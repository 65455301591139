import {
  AccountBoxOutlined,
  AddShoppingCartOutlined,
  AirportShuttleOutlined,
  AnalyticsOutlined,
  AttachMoneyOutlined,
  BackupTableOutlined,
  BusinessOutlined,
  CategoryOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  GroupAddOutlined,
  InventoryOutlined,
  LibraryMusicOutlined,
  LoyaltyOutlined,
  MonitorHeartOutlined,
  PostAddOutlined,
  PrecisionManufacturingOutlined,
  StorefrontOutlined,
  SummarizeOutlined
} from '@mui/icons-material';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { SideBarNavItem } from 'types';

// Dashboard

const dashboard: SideBarNavItem = {
  title: 'Dashboard',
  to: ROUTING_CONFIG.dashboard,
  icon: DashboardOutlined,
  exact: true,
};

// Analytics

const analytics: SideBarNavItem = {
  title: 'Analytics',
  to: ROUTING_CONFIG.analytics,
  icon: AnalyticsOutlined,
  exact: true,
};

// Inventory

const categories: SideBarNavItem = {
  title: 'Categories',
  to: ROUTING_CONFIG.categories,
  icon: CategoryOutlined,
  exact: true,
};

const genres: SideBarNavItem = {
  title: 'Genres',
  to: ROUTING_CONFIG.genres,
  icon: LibraryMusicOutlined,
  exact: true,
};

const productList = {
  title: 'Product List',
  to: ROUTING_CONFIG.productList,
  icon: AddShoppingCartOutlined,
  exact: true,
};

const production = {
  title: 'Production & Batches',
  to: ROUTING_CONFIG.production,
  icon: PrecisionManufacturingOutlined,
  exact: true,
};

const inventoryAdjustments: SideBarNavItem = {
  title: 'Inventory Adjustments',
  to: ROUTING_CONFIG.inventoryAdjustments,
  icon: InventoryOutlined,
  exact: true,
};

const baselines: SideBarNavItem = {
  title: 'Baselines',
  to: ROUTING_CONFIG.baselines,
  icon: MonitorHeartOutlined,
  exact: true,
};

const products: SideBarNavItem = {
  title: 'Inventory',
  nested: [
    {
      title: 'Products',
      nested: [ categories, genres, productList ],
    },
    {
      title: 'Stock',
      nested: [ production, inventoryAdjustments, baselines ]
    },
  ],
};

const productsNonAdmin: SideBarNavItem = {
  title: 'Stock',
  nested: [ production, inventoryAdjustments ]
};

// Events

const eventsManagement: SideBarNavItem = {
  title: 'Events Management',
  to: ROUTING_CONFIG.events,
  icon: DescriptionOutlined,
  exact: true,
};

const eventsManagement2: SideBarNavItem = {
  title: 'Events Management 2.0',
  to: '/events2',
  icon: SummarizeOutlined,
  exact: true,
};


const eventOrganizers: SideBarNavItem = {
  title: 'Event Organizers',
  to: ROUTING_CONFIG.eventOrganizers,
  icon: BusinessOutlined,
  exact: true,
};

// Store Fronts

const storefronts: SideBarNavItem = {
  title: 'Storefronts',
  to: ROUTING_CONFIG.storefronts,
  icon: StorefrontOutlined,
  exact: true,
};

// Sales and Logging Portal

const logLiveSale: SideBarNavItem = {
  title: 'Point of Sale',
  to: ROUTING_CONFIG.logLiveSale,
  icon: PostAddOutlined,
  exact: true,
};

const salesLog: SideBarNavItem = {
  title: 'Sales Log',
  to: ROUTING_CONFIG.salesLog,
  icon: AttachMoneyOutlined,
  exact: true,
};

const orders: SideBarNavItem = {
  title: 'Orders',
  to: ROUTING_CONFIG.orders,
  icon: LoyaltyOutlined,
  exact: true,
};

const sales: SideBarNavItem = {
  title: 'Sales',
  nested: [
    logLiveSale,
    salesLog,
    orders,
  ]
};
const salesNonAdmin = logLiveSale;

// Teams

const teams: SideBarNavItem = {
  title: 'Teams',
  to: ROUTING_CONFIG.teams,
  icon: BackupTableOutlined,
  exact: true,
};

// Vehicles

const vehicles: SideBarNavItem = {
  title: 'Vehicles',
  to: ROUTING_CONFIG.vehicles,
  icon: AirportShuttleOutlined,
  exact: true,
};

// Users

const users: SideBarNavItem = {
  title: 'Users',
  to: ROUTING_CONFIG.users,
  icon: GroupAddOutlined,
  exact: true,
};

const profile: SideBarNavItem = {
  title: 'Profile',
  to: ROUTING_CONFIG.profile,
  icon: AccountBoxOutlined,
  exact: true,
};

export const navItemsConfig = {
  dashboard,
  analytics,
  genres,
  productList,
  products,
  productsNonAdmin,
  sales,
  salesLog,
  orders,
  salesNonAdmin,
  eventsManagement,
  eventsManagement2,
  eventOrganizers,
  storefronts,
  teams,
  vehicles,
  users,
  profile,
};