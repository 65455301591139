import { MenuItem, Select } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';

export type SelectCellOption_old = {
  value: string;
  label: React.ReactNode;
};

export type SelectCellProps_old = { options: SelectCellOption_old[]; onChange?: (value: string) => void } & GridRenderEditCellParams;

export const SelectCell_old: React.FC<SelectCellProps_old> = ({ options, onChange, ...props }) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChange?.(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Select value={value} onChange={handleValueChange} fullWidth disabled={!options.length}>
      {options.map((option) =>
        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
    </Select>
  );
};
