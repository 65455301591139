import React, { useState } from 'react';
import { useAddressSuggestions } from './useAddressSuggestions';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { TextInput } from '../TextInput';
import { useField, useFormikContext } from 'formik';

export type AddressAutocompleteProps = {
  name: string;
};

export type AddressAutocompleteFormType = {
  place: {
    address: string;
    placeId: string;
    address2?: string;
  };
};

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = props => {
  const [ field, _, helpers ] = useField<AddressAutocompleteFormType['place']>(props.name);
  const { errors, touched } = useFormikContext<AddressAutocompleteFormType>();
  const [ open, setOpen ] = useState(false);

  const [ userInput, setUserInput ] = useState<string>(field.value.address ?? '');
  const { suggestions, loading } = useAddressSuggestions({ userInput, initialValue: field.value.address ?? '' });

  const options = suggestions.map(s => ({
    address: s.description,
    placeId: s.place_id
  }));

  return (
    <div>
      <Autocomplete
        id="address-autocomplete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={() => true}
        getOptionLabel={(option) => option.address}
        options={options}
        loading={loading}
        inputValue={userInput}
        value={field.value}
        onChange={(_e, newValue) => {
          if (!newValue) {
            return;
          }

          helpers.setValue({
            address: newValue.address,
            placeId: newValue.placeId,
          });
        }}
        onInputChange={(_e, newInputValue) => setUserInput(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Shipping Address *"
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            error={touched.place?.address && !!errors.place?.address}
            helperText={touched.place?.address && errors.place?.address}
          />
        )}
      />
      <TextInput
        sx={{ mt: 2, width: '50%' }}
        name="place.address2"
        label="Apt, Suite, etc. (optional)"
      />
    </div>
  );
};