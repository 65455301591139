import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { EVENT_SPACE_SIZE_OPTIONS } from 'constants/event';
import { useFormikContext } from 'formik';
import { EventResourceForm } from 'types';

export const SpaceSizeForm: React.FC = () => {
  const { values, setFieldValue, touched, errors } = useFormikContext<EventResourceForm['spaceSize']>();

  const spaceSizeError = touched.spaceSize && errors.spaceSize;

  return (
    <MuiAutocomplete
      freeSolo
      disableClearable
      filterOptions={(options, _) => options}
      options={EVENT_SPACE_SIZE_OPTIONS}
      value={values.spaceSize}
      onChange={(_, newValue) => {
        setFieldValue('spaceSize', newValue);
      }}
      openOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          name="spaceSize"
          label="Space Size"
          value={values.spaceSize}
          onChange={(e) => {
            setFieldValue('spaceSize', e.target.value);
          }}
          error={Boolean(spaceSizeError)}
          helperText={spaceSizeError}
          autoFocus
        />
      )}
    />
  );
};