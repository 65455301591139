import { Button, ButtonProps, Popover, PopoverProps } from '@mui/material';
import React, { useState } from 'react';

export type PopoverButtonProps = {
  children: ((props: {handleClose: () => void}) => React.ReactNode) | React.ReactNode;
  label: React.ReactNode;
  id: string;
  PopoverProps?: Partial<Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>>;
  onClose?: () => void;
} & Omit<ButtonProps, 'onClick' | 'children' | 'id' | 'label'>;

export const PopoverButton: React.FC<PopoverButtonProps> = ({ children, label, id, PopoverProps, onClose, ...props }) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    onClose?.();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? id : undefined;

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        sx={{ backgroundColor: t => t.palette.background.paper }}
        {...props}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
        {...PopoverProps}
      >
        {typeof children === 'function' ? children({ handleClose }) : children}
      </Popover>
    </>
  );
};