import { CreateEventsManagementPresetInput, GetEventsResponse } from 'api/actions';
import { EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventFutureInterestStatusEnum, EventInsuranceSharedEnum, EventInsuranceStatusEnum, EventParticipationStatusEnum, EventsManagementPreset, EventsManagementPresetDateRangeOrShortcut, EventsManagementPresetDateRangeShortcut, EventsManagementPresetDateStatusEnum } from 'api/resources';
import { DateRange, DateService } from 'services';
import { EventDatesStatusEnum, EventStaffStatusEnum, UnknownEnum, YesNoEnum } from 'types';
import { dateRangeShortcutEnumHelpers } from './components/Sidebar/FiltersInput';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { getEventDatesStatus, getEventStaffStatus } from 'helpers';

export type EventsManagementFilter = Partial<{
  dateRange: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut;
  participationStatus: (EventParticipationStatusEnum | UnknownEnum)[];
  applicationStatus: (EventApplicationStatusEnum | UnknownEnum)[];
  acceptanceStatus: (EventAcceptanceStatusEnum | UnknownEnum)[];
  staffStatus: EventStaffStatusEnum[];
  datesStatus: EventDatesStatusEnum[];
  futureInterestStatus: (EventFutureInterestStatusEnum | UnknownEnum)[];
  applicationOpenDate: EventsManagementPresetDateStatusEnum[];
  applicationDeadlineDate: EventsManagementPresetDateStatusEnum[];
  insuranceRequired: (YesNoEnum | UnknownEnum)[];
  insuranceStatus: (EventInsuranceStatusEnum | UnknownEnum)[];
  insuranceShared: (EventInsuranceSharedEnum | UnknownEnum)[];
  lodgingRequired: (YesNoEnum | UnknownEnum)[];
  team: string[];
  teamLead: string[];
}>;

const dateRangeFilter = (event: GetEventsResponse['data'][number], dateRangeOrShortcut: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut) => {
  const dateRange = dateRangeShortcutEnumHelpers.getDateRange(dateRangeOrShortcut);

  const eventDateRange = (event.startDate && event.endDate) ? {
    start: DateService.dayjsTz(event.startDate.dateAsUtc),
    end: DateService.dayjsTz(event.endDate.dateAsUtc),
  } : {
    // in case dates are not assigned, date range is the whole event year
    start: DateService.dayjs().set('year', event.year).startOf('year'),
    end: DateService.dayjs().set('year', event.year).endOf('year'),
  };

  if (dateRange.start && dateRange.end) {
    const dateRangeStartIsWithinEventDateRange = dateRange.start.isBetween(eventDateRange.start, eventDateRange.end, 'day', '[]');
    const dateRangeEndIsWithinEventDateRange = dateRange.end.isBetween(eventDateRange.start, eventDateRange.end, 'day', '[]');
    const eventStartIsWithinDateRange = eventDateRange.start.isBetween(dateRange.start, dateRange.end, 'day', '[]');
    const eventEndIsWithinDateRange = eventDateRange.end.isBetween(dateRange.start, dateRange.end, 'day', '[]');

    return dateRangeStartIsWithinEventDateRange || dateRangeEndIsWithinEventDateRange || eventStartIsWithinDateRange || eventEndIsWithinDateRange;
  }

  if (dateRange.start) {
    return !eventDateRange.start.isBefore(dateRange.start, 'day');
  }

  if (dateRange.end) {
    return !eventDateRange.end.isAfter(dateRange.end, 'day');
  }

  // date range is not set
  return true;
};

const checkboxFilter = <V extends string>(value: V | V[] | undefined, filterValue: V[] | undefined) => {
  return !filterValue || !!(value && (Array.isArray(value) ? value.some(v => filterValue.includes(v)) : filterValue.includes(value)));
};

const getDateStatusFilterValuesFromDate = (date: string | undefined): EventsManagementPresetDateStatusEnum[] => {
  if (!date) {
    return [ EventsManagementPresetDateStatusEnum.notSpecified ];
  }

  const values = [ EventsManagementPresetDateStatusEnum.specified ];

  if (DateService.dayjs(date).isBefore(DateService.dayjs())) {
    values.push(EventsManagementPresetDateStatusEnum.past);
  } else {
    values.push(EventsManagementPresetDateStatusEnum.upcoming);
  }

  return values;
};

export const filterEvents = (events: GetEventsResponse['data'], filter: EventsManagementFilter) => {
  return events.filter(event => {
    let pass = true;

    if (filter.dateRange) {
      pass = pass && dateRangeFilter(event, filter.dateRange);
    }

    if (filter.participationStatus) {
      pass = pass && checkboxFilter(event.participationStatus ?? UnknownEnum.unknown, filter.participationStatus);
    }

    if (filter.applicationStatus) {
      pass = pass && checkboxFilter(event.applicationStatus ?? UnknownEnum.unknown, filter.applicationStatus);
    }

    if (filter.acceptanceStatus) {
      pass = pass && checkboxFilter(event.acceptanceStatus ?? UnknownEnum.unknown, filter.acceptanceStatus);
    }

    if (filter.staffStatus) {
      pass = pass && checkboxFilter(getEventStaffStatus(event.dates), filter.staffStatus);
    }

    if (filter.datesStatus) {
      pass = pass && checkboxFilter(getEventDatesStatus(event.dates), filter.datesStatus);
    }

    if (filter.futureInterestStatus) {
      pass = pass && checkboxFilter(event.futureInterestStatus ?? UnknownEnum.unknown, filter.futureInterestStatus);
    }

    if (filter.applicationOpenDate) {
      pass = pass && checkboxFilter(getDateStatusFilterValuesFromDate(event.applicationOpenDate), filter.applicationOpenDate);
    }

    if (filter.applicationDeadlineDate) {
      pass = pass && checkboxFilter(getDateStatusFilterValuesFromDate(event.applicationDeadlineDate), filter.applicationDeadlineDate);
    }

    if (filter.insuranceRequired) {
      pass = pass && checkboxFilter(yesNoEnumHelpers.yesNo.getEnumValue(event.insurance?.isRequired), filter.insuranceRequired);
    }

    if (filter.insuranceStatus) {
      pass = pass && checkboxFilter(event.insurance?.status ?? UnknownEnum.unknown, filter.insuranceStatus);
    }

    if (filter.insuranceShared) {
      pass = pass && checkboxFilter(event.insurance?.shared ?? UnknownEnum.unknown, filter.insuranceShared);
    }

    if (filter.lodgingRequired) {
      pass = pass && checkboxFilter(yesNoEnumHelpers.yesNo.getEnumValue(event.lodging?.isRequired), filter.lodgingRequired);
    }

    if (filter.team) {
      pass = pass && checkboxFilter(event.team?._id, filter.team);
    }

    if (filter.teamLead) {
      pass = pass && checkboxFilter(event.teamManager?._id, filter.teamLead);
    }

    return pass;
  });
};


export const isDateRangeFilterSame = (a: EventsManagementFilter['dateRange'], b: EventsManagementFilter['dateRange']) => {
  if (!a || !b) {
    return !a && !b;
  }

  if (typeof a === 'string' || typeof b === 'string') {
    return (typeof a === 'string' && typeof b === 'string') && a === b;
  }

  const isStartSame =(!a.start && !b.start) || (a.start && b.start && a.start.isSame(b.start));
  const isEndSame = (!a.end && !b.end) || (a.end && b.end && a.end.isSame(b.end));

  return isStartSame && isEndSame;
};

export const isCheckboxFilterSame = <V extends string>(a: V[] | undefined, b: V[] | undefined) => {
  if (!a || !b) {
    return !a && !b;
  }

  return !a.some(value => !b.includes(value)) && !b.some(value => !a.includes(value));
};

export const isFilterSame = (a: EventsManagementFilter, b: EventsManagementFilter) => {
  return isDateRangeFilterSame(a.dateRange, b.dateRange)
   && isCheckboxFilterSame(a.acceptanceStatus, b.acceptanceStatus)
   && isCheckboxFilterSame(a.applicationStatus, b.applicationStatus)
   && isCheckboxFilterSame(a.datesStatus, b.datesStatus)
   && isCheckboxFilterSame(a.futureInterestStatus, b.futureInterestStatus)
   && isCheckboxFilterSame(a.applicationOpenDate, b.applicationOpenDate)
   && isCheckboxFilterSame(a.applicationDeadlineDate, b.applicationDeadlineDate)
   && isCheckboxFilterSame(a.insuranceRequired, b.insuranceRequired)
   && isCheckboxFilterSame(a.insuranceStatus, b.insuranceStatus)
   && isCheckboxFilterSame(a.insuranceShared, b.insuranceShared)
   && isCheckboxFilterSame(a.lodgingRequired, b.lodgingRequired)
   && isCheckboxFilterSame(a.team, b.team)
   && isCheckboxFilterSame(a.teamLead, b.teamLead);
};

const transformDateRangeOrShortcutValueFromPreset = (dateRangeOrShortcut?: EventsManagementPresetDateRangeOrShortcut) => {
  if (!dateRangeOrShortcut) {
    return undefined;
  }

  if (dateRangeOrShortcut.shortcut) {
    return dateRangeOrShortcut.shortcut;
  }

  return {
    start: DateService.dayjs(dateRangeOrShortcut.dateRange?.start),
    end: DateService.dayjs(dateRangeOrShortcut.dateRange?.end),
  };
};

function transformCheckboxValueFromPreset<V extends string>(
  presetFilterValue: { value: V[]; includeUnassigned?: boolean } | undefined,
  ignoreUnassigned: true
): V[] | undefined;
function transformCheckboxValueFromPreset<V extends string>(
  presetFilterValue: { value: V[]; includeUnassigned?: boolean } | undefined,
  ignoreUnassigned?: false
): (V | UnknownEnum)[] | undefined;
function transformCheckboxValueFromPreset<V extends string>(
  presetFilterValue: { value: V[]; includeUnassigned?: boolean } | undefined,
  ignoreUnassigned?: boolean
): (V | UnknownEnum)[] | V[] | undefined {
  if (!presetFilterValue || (!presetFilterValue.value.length && (ignoreUnassigned || !presetFilterValue.includeUnassigned))) {
    return undefined;
  }

  if (ignoreUnassigned) {
    return presetFilterValue.value;
  }

  return [
    ...presetFilterValue.value,
    ...(presetFilterValue.includeUnassigned ? [ UnknownEnum.unknown ] : []),
  ];
};

export const transformFilterFromPreset = (presetFilter: EventsManagementPreset['filter']): EventsManagementFilter  => {
  return {
    dateRange: transformDateRangeOrShortcutValueFromPreset(presetFilter.dateRangeOrShortcut),
    participationStatus: transformCheckboxValueFromPreset(presetFilter.participationStatus),
    applicationStatus: transformCheckboxValueFromPreset(presetFilter.applicationStatus),
    acceptanceStatus: transformCheckboxValueFromPreset(presetFilter.acceptanceStatus),
    staffStatus: transformCheckboxValueFromPreset(presetFilter.staffStatus, true),
    datesStatus: transformCheckboxValueFromPreset(presetFilter.datesStatus, true),
    futureInterestStatus: transformCheckboxValueFromPreset(presetFilter.futureInterestStatus),
    applicationOpenDate: transformCheckboxValueFromPreset(presetFilter.applicationOpenDate, true),
    applicationDeadlineDate: transformCheckboxValueFromPreset(presetFilter.applicationDeadlineDate, true),
    insuranceRequired: transformCheckboxValueFromPreset(presetFilter.insuranceRequired),
    insuranceStatus: transformCheckboxValueFromPreset(presetFilter.insuranceStatus),
    insuranceShared: transformCheckboxValueFromPreset(presetFilter.insuranceShared),
    lodgingRequired: transformCheckboxValueFromPreset(presetFilter.lodgingRequired),
    team: transformCheckboxValueFromPreset(presetFilter.team),
    teamLead: transformCheckboxValueFromPreset(presetFilter.teamLead),
  };
};

const transformCheckboxValueToPreset = <V extends string>(checkboxValue?: (V | UnknownEnum)[]) => {
  if (!checkboxValue) {
    return null;
  }

  return {
    value: checkboxValue.filter((item): item is V => item !== UnknownEnum.unknown),
    includeUnassigned: checkboxValue.some(item => item === UnknownEnum.unknown),
  };
};

const transformDateRangeValueToPreset = (dateRange?: EventsManagementFilter['dateRange']) => {
  if (!dateRange) {
    return null;
  }

  if (typeof dateRange === 'string') {
    return { shortcut: dateRange };
  }

  return {
    dateRange: {
      start: dateRange.start.format('YYYY-MM-DD'),
      end: dateRange.end.format('YYYY-MM-DD'),
    },
  };
};

export const transformFilterToPreset = (filter: EventsManagementFilter): CreateEventsManagementPresetInput['filter']  => {
  return {
    dateRangeOrShortcut: transformDateRangeValueToPreset(filter.dateRange),
    participationStatus: transformCheckboxValueToPreset(filter.participationStatus),
    applicationStatus: transformCheckboxValueToPreset(filter.applicationStatus),
    acceptanceStatus: transformCheckboxValueToPreset(filter.acceptanceStatus),
    staffStatus: transformCheckboxValueToPreset(filter.staffStatus),
    datesStatus: transformCheckboxValueToPreset(filter.datesStatus),
    futureInterestStatus: transformCheckboxValueToPreset(filter.futureInterestStatus),
    applicationOpenDate: transformCheckboxValueToPreset(filter.applicationOpenDate),
    applicationDeadlineDate: transformCheckboxValueToPreset(filter.applicationDeadlineDate),
    insuranceRequired: transformCheckboxValueToPreset(filter.insuranceRequired),
    insuranceStatus: transformCheckboxValueToPreset(filter.insuranceStatus),
    insuranceShared: transformCheckboxValueToPreset(filter.insuranceShared),
    lodgingRequired: transformCheckboxValueToPreset(filter.lodgingRequired),
    team: transformCheckboxValueToPreset(filter.team),
    teamLead: transformCheckboxValueToPreset(filter.teamLead),
  };
};