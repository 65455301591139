import { GridColDef, GridColumnHeaderTitle } from '@mui/x-data-grid';
import { Table, TeamCell, WrapWithStatusIndicator } from 'components';
import { QuantityWithStatusCell } from './QuantityWithStatusCell.component';
import { useProductOutletContext } from '../Product.base';
import { useTeams } from 'queries';
import { ProductStock, Team } from 'api/resources';
import { getProductTeamBaseline } from 'helpers';

type TProductInventoryTableRow = Pick<ProductStock, 'quantity' | 'baseline' | '_id'> & {
  team: Pick<Team, 'name'>;
};

const TOTALS_ROW_ID = 'totals';

const productInventoryTableColumns: GridColDef<TProductInventoryTableRow>[] = [
  {
    field: 'team',
    headerName: 'Team',
    flex: 1,
    valueGetter: (_v, row) => row.team.name,
    filterable: false,
    hideable: false,
    renderCell: ({ row }) => <TeamCell team={row.team} />,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    type: 'number',
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderCell: ({ row: { baseline, quantity } }) => {
      return <QuantityWithStatusCell quantity={quantity} baseline={baseline} />;
    },
  },
  {
    field: 'baseline',
    headerName: 'Baseline',
    flex: 1,
    type: 'number',
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    renderHeader: ({ colDef: { computedWidth, headerName } }) => {
      return (
        <WrapWithStatusIndicator status="warning">
          <GridColumnHeaderTitle columnWidth={computedWidth} label={headerName || ''} />
        </WrapWithStatusIndicator>
      );
    },
  },
];

export const ProductInventoryTable: React.FC = () => {
  const { product } = useProductOutletContext();
  const { data: teams = [], isInitialLoading: loading } = useTeams();

  const rows = teams.map((team): TProductInventoryTableRow => {
    const stock = product.stocks[team._id];
    const baseline = getProductTeamBaseline(product, team);

    return {
      _id: team._id,
      quantity: stock.quantity,
      baseline,
      team,
    };
  });

  const totalRow: TProductInventoryTableRow = {
    _id: TOTALS_ROW_ID,
    team: { name: 'Total' },
    ...rows.reduce((r, v): Pick<TProductInventoryTableRow, 'quantity' | 'baseline'> => ({ ...r, quantity: r.quantity + v.quantity, baseline: r.baseline + v.baseline }), { quantity: 0, baseline: 0 })
  };

  return (
    <Table
      slots={{ toolbar: null }}
      columns={productInventoryTableColumns}
      rows={rows.concat(totalRow)}
      loading={loading}
      getRowId={(x) => x._id}
      sx={{
        minHeight: 'initial',
        border: 'none',
      }}
      autoHeight
      hideFooter
      disableRowSelectionOnClick
    />
  );
};
