import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { GetEventsResponse, GetUsersAsResourceResponse } from 'api/actions';
import { AutocompleteCell } from '../Autocomplete.component';
import { Avatar, Box } from '@mui/material';
import { getUserInitialsForAvatar } from 'services';

export type EventUserAutocompleteCellProps =  GridRenderEditCellParams<GetEventsResponse['data'][number], string | null> & {
  users: GetUsersAsResourceResponse['data'];
};


export const EventUserAutocompleteCell: React.FC<EventUserAutocompleteCellProps> = props => {
  return (
    <AutocompleteCell
      {...props}
      autocompleteProps={{
        options: props.users.map(user => ({ ...user, id: user._id })),
        getOptionLabel: (option) => option.name,
        loading: props.loading,
        renderOption: (option) => (
          <Box display="flex" gap={0.5}>
            <Avatar src={option.profileImageUrl} sx={{ width: 25, height: 25 }}>{option.name && getUserInitialsForAvatar(option.name)}</Avatar>
            {option.name}
          </Box>
        ),
      }}
    />
  );
};