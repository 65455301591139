import { EventsManagementPresetDateStatusEnum } from 'api/resources';
import { EnumHelpers } from 'helpers/enums/types';

export const getCheckboxOptionsFromEnumHelpers = <T extends string>(enumHelper: EnumHelpers<T>) => {
  return enumHelper.enumValues.map(value => ({
    label: enumHelper.getLabel(value),
    value,
  }));
};

export const getCheckboxOptionsFromResource = <R extends { _id: string; name: string }>(resources: R[]) => {
  return resources.map(({ _id, name }) => ({
    label: name,
    value: _id
  }));
};

export const eventsManagementPresetDateStatusEnumHelper: EnumHelpers<EventsManagementPresetDateStatusEnum> = {
  enumValues: [
    EventsManagementPresetDateStatusEnum.specified,
    EventsManagementPresetDateStatusEnum.notSpecified,
    EventsManagementPresetDateStatusEnum.upcoming,
    EventsManagementPresetDateStatusEnum.past
  ],
  getLabel: value => {
    switch (value) {
      case EventsManagementPresetDateStatusEnum.specified:
        return 'Specified';
      case EventsManagementPresetDateStatusEnum.notSpecified:
        return 'Not specified';
      case EventsManagementPresetDateStatusEnum.upcoming:
        return 'Upcoming';
      case EventsManagementPresetDateStatusEnum.past:
        return 'Past';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};
