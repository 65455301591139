import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { EnumHelpers } from 'helpers/enums/types';
import { useMemo } from 'react';
import { SelectCell } from '../Select.component';

export type EnumSelectCellProps<T extends string> = GridRenderEditCellParams & {
  enumHelpers: EnumHelpers<T>;
};

export const EnumSelectCell = <T extends string>(props: EnumSelectCellProps<T>) => {
  const options = useMemo(() => {
    return props.enumHelpers.enumValues.map(enumValue => {
      return {
        value: enumValue,
        label: props.enumHelpers.getLabel(enumValue),
      };
    });
  }, [ props.enumHelpers ]);

  return (
    <SelectCell
      options={options}
      {...props}
    />
  );
};