import { ArrowRight, SaveOutlined } from '@mui/icons-material';
import { Button, ButtonGroup, ClickAwayListener, Grow, ListItemText, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { isFilterSame, transformFilterFromPreset } from 'pages/EventsManagement2/helpers';
import { useUpdateEventsManagementPreset } from 'pages/EventsManagement2/mutations';
import { useEventsManagementContext } from 'pages/EventsManagement2/utils';
import React, { useMemo, useRef, useState } from 'react';
import { CreatePresetModal } from './CreatePresetModal.component';
import _ from 'lodash';

const enum SavePresetOption {
  SAVE = 'save',
  SAVE_AS = 'save_as'
}

export const SavePresetButton = () => {
  const { state: { filter, columns, presetId }, presets } = useEventsManagementContext();
  const selectedPreset = useMemo(() => presets.find(preset => preset._id === presetId), [ presetId, presets ]);
  const disabled = useMemo(() => {
    if(!selectedPreset) {
      return false;
    }

    if (isFilterSame(filter, transformFilterFromPreset(selectedPreset.filter))) {
      return false;
    }

    const orderedColumns = columns.sort();
    const orderedPresetColumns = selectedPreset.columns.sort();

    return _.isEqual(orderedColumns, orderedPresetColumns);
  }, [ columns, filter, selectedPreset ]);
  const options = useMemo(() => {
    const options: { id: SavePresetOption; label: string; button: React.ReactNode }[] = [];
    const isSaveAllowed = true; // temp
    const isSaveAsAllowed = !!presetId;

    if (isSaveAllowed) {
      options.push({
        id: SavePresetOption.SAVE,
        label: 'Save view',
        button: <SaveButton onCreate={() => setCreateModalOpen(true)}/>,
      });
    }

    if (isSaveAsAllowed) {
      options.push({
        id: SavePresetOption.SAVE_AS,
        label: 'Save view as',
        button: <SaveAsButton onCreate={() => setCreateModalOpen(true)} />,
      });
    }

    return options;
  }, [ presetId ]);
  const [ selectedSaveOptionId, setSelectedSaveOptionId ] = useState(options[0].id);
  const selectedSaveOption = options.find(option => option.id === selectedSaveOptionId) ?? options[0];

  const anchorRef = useRef<HTMLDivElement>(null);

  const [ popperOpen, setPopperOpen ] = useState(false);
  const handlePopperOpenToggle = () => {
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const [ createModalOpen, setCreateModalOpen ] = useState(false);

  return (
    <>
      {createModalOpen && (
        <CreatePresetModal
          onClose={() => setCreateModalOpen(false)}
          onSuccess={() => setSelectedSaveOptionId(SavePresetOption.SAVE)}
        />
      )}
      <ButtonGroup
        variant="contained"
        size="small"
        fullWidth
        ref={anchorRef}
        sx={{
          display: 'flex',
          color: theme => theme.palette.unknown.main
        }}
        disabled={disabled}
      >
        {selectedSaveOption.button}
        {options.length > 1 && (
          <Button size="small" onClick={handlePopperOpenToggle} sx={{ flex: 0 }}>
            <ArrowRight />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={popperOpen}
        anchorEl={anchorRef.current}
        transition
        placement="right-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                <MenuList>
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      selected={option.id === selectedSaveOptionId}
                      onClick={() => {
                        setSelectedSaveOptionId(option.id);
                        setPopperOpen(false);
                      }}
                    >
                      <ListItemText>
                        {option.label}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

type SaveButtonProps = {
  onCreate: () => void;
};

const SaveButton: React.FC<SaveButtonProps> = ({ onCreate }) => {
  const { state: { presetId } } = useEventsManagementContext();
  const updatePresetMutation = useUpdateEventsManagementPreset();

  return (
    <Button
      size="small"
      startIcon={<SaveOutlined />}
      variant="contained"
      onClick={() => !!presetId ? updatePresetMutation.mutate({ id: presetId }) : onCreate()}
      fullWidth
    >
        Save view
    </Button>
  );
};

const SaveAsButton: React.FC<SaveButtonProps> = ({ onCreate }) => {
  return (
    <Button
      size="small"
      startIcon={<SaveOutlined />}
      variant="contained"
      onClick={onCreate}
      fullWidth
    >
      Save view as
    </Button>
  );
};
