import { TextField, TextFieldProps } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';

type TextFieldCellProps = GridRenderEditCellParams & {
  textFieldProps?: Omit<TextFieldProps, 'sx' | 'onChange' | 'value' | 'onWheel'>;
};

export const TextFieldCell: React.FC<TextFieldCellProps> = props => {
  const { id, value, field, api } = props;

  const handleValueChange = (newValue: string) => {
    api.setEditCellValue({ id, field, value: props.textFieldProps?.type === 'number' ? Number(newValue) : newValue });
  };

  return (
    <TextField
      {...props.textFieldProps}
      // error={!!error}
      value={value || ''}
      onChange={(e) => handleValueChange(e.target.value)}
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        const inputElement = e.currentTarget.querySelector('input');

        if (inputElement) {
          inputElement.blur();
        }
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            // borderColor: error ? (theme) => theme.palette.error.main : undefined,
          },
          '&:hover fieldset': {
            // borderColor: error ? (theme) => theme.palette.error.dark : undefined,
          },
          '&.Mui-focused fieldset': {
            // borderColor: error ? (theme) => theme.palette.error.dark : undefined,
          },
        },
        // bgcolor: error ? (theme) => theme.palette.error.background : undefined,
        // color: error ? (theme) => theme.palette.error.main : undefined,
      }}
    />
  );
};
