import { Box } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { SelectCell } from '../Select.component';

type YesNoSelectCellProps = GridRenderEditCellParams;

const options = yesNoEnumHelpers.yesNo.enumValues.map(enumValue => {
  return {
    value: enumValue,
    label: (
      <Box display="flex" justifyContent="center" width="100%">
        <YesNoUnknownIconSwitch value={yesNoEnumHelpers.yesNo.getValue(enumValue)} />
      </Box>
    ),
  };
});

export const YesNoSelectCell: React.FC<YesNoSelectCellProps> = props => {
  return (
    <SelectCell
      options={options}
      {...props}
    />
  );
};