import { CircularProgress, Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';
import { useAddressSuggestions } from 'components/Inputs/AddressAutocomplete/useAddressSuggestions';
import React, { useState } from 'react';

export type EventLocationAutocompleteCellProps = GridRenderEditCellParams<GetEventsResponse['data'][number], { address: string; placeId: string }>;

export const EventLocationAutocompleteCell: React.FC<EventLocationAutocompleteCellProps> = props => {
  const { id, value, field, api } = props;
  const onChange = (newValue: { address: string; placeId: string }) => {
    api.setEditCellValue({ id, field, value: newValue });
  };

  const [ userInput, setUserInput ] = useState<string>(value?.address ?? '');
  const { suggestions, loading } = useAddressSuggestions({ userInput, initialValue: value?.address ?? '' });

  const options = suggestions.map(s => ({
    address: s.description,
    placeId: s.place_id
  }));

  return (
    <MuiAutocomplete
      fullWidth
      open
      isOptionEqualToValue={() => true}
      getOptionLabel={(option) => option.address}
      options={options}
      loading={loading}
      inputValue={userInput}
      value={value}
      onChange={(_e, newValue) => {
        if (!newValue) {
          return;
        }

        onChange({
          address: newValue.address,
          placeId: newValue.placeId,
        });
      }}
      onInputChange={(_e, newInputValue) => setUserInput(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // error={touched.place?.address && !!errors.place?.address}
          // helperText={touched.place?.address && errors.place?.address}
        />
      )}
    />
  );
};