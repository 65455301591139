import { Box, Checkbox, Divider, FormControlLabel, FormGroup, FormLabel, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { EventsManagementFilter, isCheckboxFilterSame, isDateRangeFilterSame, transformFilterFromPreset } from 'pages/EventsManagement2/helpers';
import { useEventsManagementContext } from 'pages/EventsManagement2/utils';
import React, { useMemo } from 'react';
import { eventsManagementPresetDateStatusEnumHelper, getCheckboxOptionsFromEnumHelpers, getCheckboxOptionsFromResource } from './helpers';
import { eventEnumHelpers } from 'helpers';
import { PopoverButton } from 'components';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { DateRange, DateService } from 'services';
import { ArrowRightAlt, CalendarTodayOutlined, CheckOutlined } from '@mui/icons-material';
import { EnumHelpers } from 'helpers/enums/types';
import { EventsManagementPresetDateRangeShortcut } from 'api/resources';
import { WrapWithPresetIndicator } from './WrapWithPresetIndicator.component';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

export const FiltersInput = () => {
  const { state: { filter, presetId }, onSetState, teams, users, presets } = useEventsManagementContext();

  const selectedPresetFilter = useMemo(() => {
    const selectedPreset = presets.find(p => p._id === presetId);

    if (selectedPreset) {
      return transformFilterFromPreset(selectedPreset.filter);
    }
  }, [ presetId, presets ]);
  const setFilter = (filter: EventsManagementFilter) => onSetState({ filter });

  return (
    <Box display="flex" flexDirection="column">
      <DateRangeFilterInput
        value={filter.dateRange}
        presetValue={selectedPresetFilter?.dateRange}
        onChange={value => setFilter({ ...filter, dateRange: value || undefined })}
      />

      <CheckboxFilterInput
        label="Participation Status"
        value={filter.participationStatus}
        presetValue={selectedPresetFilter?.participationStatus}
        onChange={value => setFilter({ ...filter, participationStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.participationStatus)}
      />

      <CheckboxFilterInput
        label="Application Status"
        value={filter.applicationStatus}
        presetValue={selectedPresetFilter?.applicationStatus}
        onChange={value => setFilter({ ...filter, applicationStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.applicationStatus)}
      />

      <CheckboxFilterInput
        label="Acceptance Status"
        value={filter.acceptanceStatus}
        presetValue={selectedPresetFilter?.acceptanceStatus}
        onChange={value => setFilter({ ...filter, acceptanceStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.acceptanceStatus)}
      />

      <CheckboxFilterInput
        label="Staff Status"
        value={filter.staffStatus}
        presetValue={selectedPresetFilter?.staffStatus}
        onChange={value => setFilter({ ...filter, staffStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.staffStatus)}
      />

      <CheckboxFilterInput
        label="Dates Status"
        value={filter.datesStatus}
        presetValue={selectedPresetFilter?.datesStatus}
        onChange={value => setFilter({ ...filter, datesStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.datesStatus)}
      />

      <CheckboxFilterInput
        label="Future Interest Status"
        value={filter.futureInterestStatus}
        presetValue={selectedPresetFilter?.futureInterestStatus}
        onChange={value => setFilter({ ...filter, futureInterestStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.futureInterestStatus)}
      />

      <CheckboxFilterInput
        label="Application Open Date"
        value={filter.applicationOpenDate}
        presetValue={selectedPresetFilter?.applicationOpenDate}
        onChange={value => setFilter({ ...filter, applicationOpenDate: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventsManagementPresetDateStatusEnumHelper)}
      />

      <CheckboxFilterInput
        label="Application Deadline Date"
        value={filter.applicationDeadlineDate}
        presetValue={selectedPresetFilter?.applicationDeadlineDate}
        onChange={value => setFilter({ ...filter, applicationDeadlineDate: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventsManagementPresetDateStatusEnumHelper)}
      />

      <CheckboxFilterInput
        label="Insurance Required"
        value={filter.insuranceRequired}
        presetValue={selectedPresetFilter?.insuranceRequired}
        onChange={value => setFilter({ ...filter, insuranceRequired: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(yesNoEnumHelpers.yesNo)}
      />

      <CheckboxFilterInput
        label="Insurance Status"
        value={filter.insuranceStatus}
        presetValue={selectedPresetFilter?.insuranceStatus}
        onChange={value => setFilter({ ...filter, insuranceStatus: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.insuranceStatus)}
      />

      <CheckboxFilterInput
        label="Insurance Shared"
        value={filter.insuranceShared}
        presetValue={selectedPresetFilter?.insuranceShared}
        onChange={value => setFilter({ ...filter, insuranceShared: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(eventEnumHelpers.insuranceShared)}
      />

      <CheckboxFilterInput
        label="Lodging Required"
        value={filter.lodgingRequired}
        presetValue={selectedPresetFilter?.lodgingRequired}
        onChange={value => setFilter({ ...filter, lodgingRequired: value || undefined })}
        options={getCheckboxOptionsFromEnumHelpers(yesNoEnumHelpers.yesNo)}
      />

      <CheckboxFilterInput
        label="Team"
        value={filter.team}
        presetValue={selectedPresetFilter?.team}
        onChange={value => setFilter({ ...filter, team: value || undefined })}
        options={getCheckboxOptionsFromResource(teams)}
      />

      <CheckboxFilterInput
        label="Team Lead"
        value={filter.teamLead}
        presetValue={selectedPresetFilter?.teamLead}
        onChange={value => setFilter({ ...filter, teamLead: value || undefined })}
        options={getCheckboxOptionsFromResource(users)}
      />
    </Box>
  );
};

// Date Range Shortcut

export const dateRangeShortcutEnumHelpers: EnumHelpers<EventsManagementPresetDateRangeShortcut> & {
  getDateRange: (EventsManagementPresetDateRangeShortcut?: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut) => Partial<NonNullable<DateRange>>;
} = {
  enumValues: [
    EventsManagementPresetDateRangeShortcut.PAST,
    EventsManagementPresetDateRangeShortcut.UPCOMING,

    EventsManagementPresetDateRangeShortcut.THIS_YEAR,
    EventsManagementPresetDateRangeShortcut.THIS_MONTH,

    EventsManagementPresetDateRangeShortcut.NEXT_YEAR,
    EventsManagementPresetDateRangeShortcut.NEXT_MONTH,

    EventsManagementPresetDateRangeShortcut.LAST_YEAR,
    EventsManagementPresetDateRangeShortcut.LAST_MONTH,

    EventsManagementPresetDateRangeShortcut.LAST_365_DAYS
  ],
  getLabel: value => {
    switch (value) {
      case undefined:
        return 'All Time';
      case EventsManagementPresetDateRangeShortcut.PAST:
        return 'Past';
      case EventsManagementPresetDateRangeShortcut.UPCOMING:
        return 'Upcoming';
      case EventsManagementPresetDateRangeShortcut.THIS_YEAR:
        return 'This year';
      case EventsManagementPresetDateRangeShortcut.THIS_MONTH:
        return 'This month';
      case EventsManagementPresetDateRangeShortcut.NEXT_YEAR:
        return 'Next year';
      case EventsManagementPresetDateRangeShortcut.NEXT_MONTH:
        return 'Next month';
      case EventsManagementPresetDateRangeShortcut.LAST_YEAR:
        return 'Last year';
      case EventsManagementPresetDateRangeShortcut.LAST_MONTH:
        return 'Last month';
      case EventsManagementPresetDateRangeShortcut.LAST_365_DAYS:
        return 'Last 365 days';
      default:
        return 'Unknown';
    }
  },
  getDateRange: (dateRangeOrShortcut) => {
    switch (dateRangeOrShortcut) {
      case EventsManagementPresetDateRangeShortcut.PAST:
        return { end: DateService.dayjs().subtract(1, 'day') };
      case EventsManagementPresetDateRangeShortcut.UPCOMING:
        return { start: DateService.dayjs().add(1, 'day') };
      case EventsManagementPresetDateRangeShortcut.THIS_YEAR:
        return { start: DateService.dayjs().startOf('year'), end: DateService.dayjs().endOf('year') };
      case EventsManagementPresetDateRangeShortcut.THIS_MONTH:
        return { start: DateService.dayjs().startOf('month'), end: DateService.dayjs().endOf('month') };
      case EventsManagementPresetDateRangeShortcut.NEXT_YEAR:
        return { start: DateService.dayjs().add(1, 'year').startOf('year'), end: DateService.dayjs().add(1, 'year').endOf('year') };
      case EventsManagementPresetDateRangeShortcut.NEXT_MONTH:
        return { start: DateService.dayjs().add(1, 'month').startOf('month'), end: DateService.dayjs().add(1, 'month').endOf('month') };
      case EventsManagementPresetDateRangeShortcut.LAST_YEAR:
        return { start: DateService.dayjs().subtract(1, 'year').startOf('year'), end: DateService.dayjs().subtract(1, 'year').endOf('year') };
      case EventsManagementPresetDateRangeShortcut.LAST_MONTH:
        return { start: DateService.dayjs().subtract(1, 'month').startOf('month'), end: DateService.dayjs().subtract(1, 'month').endOf('month') };
      case EventsManagementPresetDateRangeShortcut.LAST_365_DAYS:
        return { start: DateService.dayjs().subtract(365, 'day'), end: DateService.dayjs() };
      case undefined:
        return {};
      default:
        return dateRangeOrShortcut;
    }
  },
  getColor: () => 'unknown',
};

// END: Date range shortcut

type DateRangeFilterInputProps = {
  value?: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut;
  presetValue?: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut;
  onChange: (value: NonNullable<DateRange> | EventsManagementPresetDateRangeShortcut | null) => void;
};

const DateRangeFilterInput = ({ presetValue, value, onChange }: DateRangeFilterInputProps) => {
  const { state: { presetId } } = useEventsManagementContext();
  const { start, end } = dateRangeShortcutEnumHelpers.getDateRange(value);
  const label = useMemo(() => {
    if (!value || typeof value === 'string') {
      return dateRangeShortcutEnumHelpers.getLabel(value);
    }

    return DateService.getFormattedDateRange(value?.start, value?.end, false);
  }, [ value ]);

  return (
    <PopoverButton
      label={(
        <WrapWithPresetIndicator isPreset={!!presetId} isSame={isDateRangeFilterSame(presetValue, value)}>
          <Box component="span" pr={1}>{label}</Box>
        </WrapWithPresetIndicator>
      )}
      id="events-management-daterange-popover"
      PopoverProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
      fullWidth
      size="medium"
      startIcon={<CalendarTodayOutlined />}
    >
      <Box position="relative">
        <Box display="flex" height="100%">
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            width="200px"
            borderRight={theme => `1px solid ${theme.palette.divider}`}
            boxShadow={theme => `inset 0 -16px 16px -16px ${theme.palette.divider}`}
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Box flex={1} overflow="auto" display="flex" flexDirection="column" gap={1}>
                <List>
                  <ListItemButton
                    selected={!value}
                    onClick={() => onChange(null)}
                    sx={{ position: 'relative' }}
                  >
                    <ListItemText
                      primary={dateRangeShortcutEnumHelpers.getLabel(undefined)}
                      primaryTypographyProps={{ fontWeight: !value ? 500 : 400, fontSize: 'small' }}
                    />
                    {!value && (
                      <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                        <CheckOutlined fontSize="small" color="primary" />
                      </Box>
                    )}
                  </ListItemButton>
                  {dateRangeShortcutEnumHelpers.enumValues.map(shortcut => {
                    const isSelected = typeof value === 'string' && value === shortcut;

                    return (
                      <ListItemButton
                        selected={isSelected}
                        key={shortcut}
                        onClick={() => onChange(shortcut)}
                        sx={{ position: 'relative' }}
                      >
                        <ListItemText
                          primary={dateRangeShortcutEnumHelpers.getLabel(shortcut)}
                          primaryTypographyProps={{ fontWeight: isSelected ? 500 : 400, fontSize: 'small' }}
                        />
                        {isSelected && (
                          <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                            <CheckOutlined fontSize="small" color="primary" />
                          </Box>
                        )}
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box py={1.5} pt={2} display="flex" alignItems="center">
              <Box flex={1} px={2} textAlign="center">
                <Typography fontWeight={500} fontSize="large">{start?.format('MMMM Do, YYYY') ?? '___'}</Typography>
              </Box>
              <Box display="flex" color="text.secondary">
                <ArrowRightAlt fontSize="large" color="inherit" />
              </Box>
              <Box flex={1} px={2} textAlign="center">
                <Typography fontWeight={500} fontSize="large">{end?.format('MMMM Do, YYYY') ?? '___'}</Typography>
              </Box>
            </Box>
            <Divider />
            <StaticDateRangePicker
              value={[ start || null, end || null ]}
              onChange={(value) => value[0] && value[1] && onChange({ start: value[0], end: value[1] })}
              slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
              calendars={2}
            />
          </Box>
        </Box>
      </Box>
    </PopoverButton>
  );
};

type CheckboxFilterInputProps<Value extends string = string> = {
  label?: string;
  options: { label: string; value: Value }[];
  value?: Value[];
  presetValue?: Value[];
  onChange: (value: Value[] | null) => void;
};

const CheckboxFilterInput = <Value extends string = string>({ label, options, value, presetValue, onChange }: CheckboxFilterInputProps<Value>) => {
  const onOptionToggle = (option: Value) => {
    if (!value) {
      return onChange([ option ]);
    }

    if (value.includes(option)) {
      const newValues = value.filter(v => v !== option);

      return onChange(newValues.length ? value.filter(v => v !== option) : null);
    }

    return onChange([ ...value, option ]);
  };

  return (
    <Box mx={1}>
      <WrapWithPresetIndicator isPreset={!!presetValue} isSame={isCheckboxFilterSame(presetValue, value)}>
        <FormLabel sx={{ fontSize: '13px', fontWeight: 500, pr: 1, }}>
          {label}
        </FormLabel>
      </WrapWithPresetIndicator>

      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          ml: 1,
        }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '14px',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '18px',
              },
            }}
            control={
              <Checkbox
                checked={!!value && value.includes(option.value)}
                onChange={() => onOptionToggle(option.value)}
                sx={{ p: 0 }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </Box>
  );
};