import { Box, Rating } from '@mui/material';

export type RatingCellProps = {
  value?: number;
};

export const RatingCell: React.FC<RatingCellProps> = props => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" mx={1}>
      <Rating value={props.value ?? 0} readOnly />
    </Box>
  );
};