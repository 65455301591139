import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';

export type AutocompleteCellProps<Option extends {id: string; name: string}> = GridRenderEditCellParams<GetEventsResponse['data'][number], string | null> & {
  autocompleteProps: {
    options: Option[];
    getOptionLabel: (option: Option) => string;
    getOptionDisabled?: (option: Option) => boolean;
    renderOption: (option: Option) => React.ReactNode;
    loading?: boolean;
  };
};

export const AutocompleteCell = <
  Option extends {id: string; name: string},
>(props: AutocompleteCellProps<Option>) => {
  const { id, value, field, api, autocompleteProps } = props;
  const onChange = (newValue: string | null) => {
    api.setEditCellValue({ id, field, value: newValue });
  };

  // Find the selected option based on the value
  const selectedOption = autocompleteProps.options.find((option) => option.id === value) || null;

  return (
    <Autocomplete
      fullWidth
      open
      {...autocompleteProps}
      renderOption={(params, option) => <li {...params} key={option.id}>{autocompleteProps.renderOption(option)}</li>}
      renderInput={(params) => <TextField {...params} />}
      value={selectedOption}
      slotProps={{
        popper: {
          sx: { minWidth: 250 },
        }
      }}
      onChange={(_e, newValue) => {
        const id = newValue ? (newValue as Option).id : null;

        onChange(id);
      }}
    />
  );
};