import { CloseOutlined, ContentCopyOutlined, DeleteOutlined, EditOutlined, ReplayOutlined, SaveOutlined, SearchOffOutlined } from '@mui/icons-material';
import { Box, ClickAwayListener, Divider, IconButton, Radio, TextField, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEventsManagementPreset } from 'api/actions';
import { EventsManagementPreset } from 'api/resources';
import { DeleteResourceModal, PopoverButton, useAlertSnackbar } from 'components';
import { useCurrentUser } from 'contexts';
import { useUpdateEventsManagementPreset } from 'pages/EventsManagement2/mutations';
import { useEventsManagementContext } from 'pages/EventsManagement2/utils';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useMemo, useState } from 'react';
import { filterResourceBySearch } from 'services';

const handleStopPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, func: () => void) => {
  e.stopPropagation();
  func();
};

export const ViewTypeInput = () => {
  const { isAdmin } = useCurrentUser();
  const { presets, state: { presetId }, onSetState } = useEventsManagementContext();

  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updatePresetMutation = useUpdateEventsManagementPreset();
  const deletePresetMutation = useMutation({
    mutationFn: (id: string) => deleteEventsManagementPreset(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENTS_MANAGEMENT_PRESETS });
      snackbar.success('Preset deleted successfully');
    },
  });

  const [ deleteModalPreset, setDeleteModalPreset ] = useState<EventsManagementPreset | null>(null);

  const [ renameText, setRenameText ] = useState<string>('');
  const [ renamePresetId, setRenamePresetId ] = useState<string | null>(null);

  const [ searchQuery, setSearchQuery ] = useState('');
  const selectedPreset = useMemo(() => presets.find(preset => preset._id === presetId), [ presetId, presets ]);
  const filteredPresets = filterResourceBySearch(presets, 'name', searchQuery);

  const handleRenameReset = () => {
    setRenamePresetId(null);
    setRenameText('');
  };
  const handleRename = (id: string) => {
    updatePresetMutation.mutate({ id, name: renameText });
    handleRenameReset();
  };

  const handleDelete = (id: string) => {
    if (presetId === id) {
      onSetState({ presetId: null });
    }

    deletePresetMutation.mutate(id);
    setDeleteModalPreset(null);
  };

  return (
    <>
      {deleteModalPreset && (
        <DeleteResourceModal
          onClose={() => setDeleteModalPreset(null)}
          deleteButtonText="Delete Preset"
          title={`Delete "${deleteModalPreset.name}" preset?`}
          description={`Are you sure you want to delete "${deleteModalPreset.name}" preset? This action cannot be undone.`}
          onDelete={() => handleDelete(deleteModalPreset._id)}
        />
      )}
      <PopoverButton
        id="events_management_presets"
        label={selectedPreset?.name ?? 'Select a preset'}
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<ContentCopyOutlined />}
        PopoverProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transformOrigin: { vertical: 0, horizontal: 0 },
          PaperProps: {
            sx: { height: '100%', boxShadow: 2 },
          }
        }}
        onClose={() => setSearchQuery('')}
      >
        {({ handleClose }) => {
          return (
            <Box display="flex" flexDirection="column" p={1} width={600} height="100%">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <TextField
                  variant="standard"
                  size="small"
                  id="search_events_management_presets"
                  placeholder="Search columns..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ flex: 1, ml: 1 }}
                />
                <IconButton onClick={handleClose} size="small">
                  <CloseOutlined fontSize="small" />
                </IconButton>
              </Box>
              <Box mx={-1} mt={1}><Divider /></Box>

              <Box display="flex" flexDirection="column" flex={1}>
                {filteredPresets.map((preset) => {
                  const selected = presetId === preset._id;
                  const rename = renamePresetId === preset._id;
                  const onChange = () => onSetState({ presetId: selected ? null : preset._id });
                  const handleRenameClick = () => {
                    setRenamePresetId(preset._id);
                    setRenameText(preset.name);
                  };
                  const handleDeleteClick = () => {
                    setDeleteModalPreset(preset);
                  };

                  return (
                    <ClickAwayListener key={preset._id} onClickAway={() => rename && handleRenameReset()}>
                      <Box
                        display="flex"
                        alignItems="center"
                        borderBottom={theme => `1px solid ${theme.palette.divider}`}
                        py={0.5}
                        mx={-1}
                        px={1}
                        bgcolor={theme => selected ? theme.palette.primary.background : undefined}
                        onClick={() => !rename && onChange()}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Radio checked={selected} onChange={onChange} />
                        <Box
                          flex={1}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          {rename ? (
                            <TextField
                              autoFocus
                              value={renameText}
                              onChange={(e) => setRenameText(e.target.value)}
                              variant="standard"
                              size="small"
                              fullWidth
                            />
                          ) : (
                            <Typography width={300} display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                              {preset.name}
                            </Typography>
                          )}
                        </Box>
                        {isAdmin && (
                          <Box display="flex">
                            {rename ? (
                              <>
                                <IconButton color="warning" onClick={handleRenameReset}>
                                  <ReplayOutlined />
                                </IconButton>
                                <IconButton color="success" onClick={() => handleRename(preset._id)}>
                                  <SaveOutlined />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <Tooltip color="primary" title="Rename Preset">
                                  <IconButton onClick={(e) => handleStopPropagation(e, handleRenameClick)}>
                                    <EditOutlined />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Preset">
                                  <IconButton color="error" onClick={(e) => handleStopPropagation(e, handleDeleteClick)}>
                                    <DeleteOutlined />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                  );
                })}

                {filteredPresets.length === 0 && (
                  <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                    <Typography color="text.secondary" display="flex" alignItems="center" gap={1}>
                      <SearchOffOutlined />No matching results found.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }}
      </PopoverButton>
    </>
  );
};
