import { authAxiosRequest } from 'api/axios';
import { DeepOptionalToNull } from 'api/resources/resources.types';
import { EventDatesStatusEnum, EventStaffStatusEnum, YesNoEnum } from 'types';
import { EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventFutureInterestStatusEnum, EventInsuranceSharedEnum, EventInsuranceStatusEnum, EventParticipationStatusEnum, EventsManagementPreset, EventsManagementPresetDateRangeOrShortcut, EventsTableField, EventsManagementFilterMultiSelectField, EventsManagementPresetDateStatusEnum } from 'api/resources';

export type GetEventsManagementPresetsResponse = { data: EventsManagementPreset[] };

export const getEventsManagementPresets = () => {
  return authAxiosRequest<GetEventsManagementPresetsResponse>('/api/events-management-presets');
};

export type CreateEventsManagementPresetInput = DeepOptionalToNull<{
  name: string;
  columns: EventsTableField[];
  filter: {
    dateRangeOrShortcut?: EventsManagementPresetDateRangeOrShortcut;
    participationStatus?: EventsManagementFilterMultiSelectField<EventParticipationStatusEnum>;
    applicationStatus?: EventsManagementFilterMultiSelectField<EventApplicationStatusEnum>;
    acceptanceStatus?: EventsManagementFilterMultiSelectField<EventAcceptanceStatusEnum>;
    staffStatus?: EventsManagementFilterMultiSelectField<EventStaffStatusEnum>;
    datesStatus?: EventsManagementFilterMultiSelectField<EventDatesStatusEnum>;
    futureInterestStatus?: EventsManagementFilterMultiSelectField<EventFutureInterestStatusEnum>;
    applicationOpenDate?: EventsManagementFilterMultiSelectField<EventsManagementPresetDateStatusEnum>;
    applicationDeadlineDate?: EventsManagementFilterMultiSelectField<EventsManagementPresetDateStatusEnum>;
    insuranceRequired?: EventsManagementFilterMultiSelectField<YesNoEnum>;
    insuranceStatus?: EventsManagementFilterMultiSelectField<EventInsuranceStatusEnum>;
    insuranceShared?: EventsManagementFilterMultiSelectField<EventInsuranceSharedEnum>;
    lodgingRequired?: EventsManagementFilterMultiSelectField<YesNoEnum>;
    team?: EventsManagementFilterMultiSelectField;
    teamLead?: EventsManagementFilterMultiSelectField;
  };
}>;

export const createEventsManagementPreset = (input: CreateEventsManagementPresetInput) => {
  return authAxiosRequest<{ data: EventsManagementPreset }>('/api/events-management-presets', { method: 'POST', data: input });
};

export type UpdateEventsManagementPresetInput = Partial<CreateEventsManagementPresetInput>;

export const updateEventsManagementPreset = (id: string, input: UpdateEventsManagementPresetInput) => {
  return authAxiosRequest(`/api/events-management-presets/${id}`, { method: 'PUT', data: input });
};

export const deleteEventsManagementPreset = (id: string) => {
  return authAxiosRequest(`/api/events-management-presets/${id}`, { method: 'DELETE' });
};
