import { GetEventsResponse, UpdateEventInput } from 'api/actions';
import { GridColDef, GridComparatorFn, GridSortCellParams } from '@mui/x-data-grid';
import { EnumHelpers } from 'helpers/enums/types';
import { currencyFormatter, DateObjectType, DateRange, DateService } from 'services';

import {
  EventProgressionColorCodeCell,
  YesNoCell,
  TextFieldCell,
  YesNoSelectCell,
  EnumSelectCell,
  EventOwedColorCodeCell,
  EventPaymentsDueColorCodeCell,
  EnumColorCodeCell,
  EventApplicationOpenDateColorCodeCell,
  EventApplicationDeadlineDateColorCodeCell,
  DatePickerWithUnknown,
  RatingCell,
  BaseCell,
  AvatarBaseCell,
  EventTeamAutocompleteCell,
  EventUserAutocompleteCell,
  AutocompleteCell,
  EventVehicleAutocompleteCell,
  EventSpaceSizeAutocompleteCell,
  EventLocationAutocompleteCell
} from 'components/Table/Cells';

import { InputAdornment } from '@mui/material';
import { eventEnumHelpers, getEventClosestPaymentDue, getEventDatesStatus, getEventStaffStatus, processFormValueUpdate } from 'helpers';
import { UnknownEnum } from 'types';
import { getMatrixDisplayText } from 'components/PlaceDistanceAndDurationContent';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import _ from 'lodash';
import { StatusIndicator } from 'components/StatusIndicator';
import {  EventsTableFieldHelperResouces, EventsTableFieldHelperValue, EventsTableFieldValues } from './types';
import { EventsTableField } from 'api/resources';

export const eventsTableFieldEnumHelpers: EnumHelpers<EventsTableField> = {
  enumValues: [
    EventsTableField.NAME,
    EventsTableField.CREATED_AT,
    EventsTableField.YEAR,
    EventsTableField.EVENT_PROGRESSION,
    EventsTableField.START_DATE,
    EventsTableField.END_DATE,
    EventsTableField.DATE_RANGE,
    EventsTableField.DAYS_COUNT,
    EventsTableField.ATTENDANCE,
    EventsTableField.VENDORS,
    EventsTableField.SPACE_SIZE,
    EventsTableField.TEAM,
    EventsTableField.TEAM_MANAGER,
    EventsTableField.VEHICLE,
    EventsTableField.EVENT_ORGANIZER,
    EventsTableField.EVENT_MANAGER,
    EventsTableField.PARTICIPATION_STATUS,
    EventsTableField.APPLICATION_STATUS,
    EventsTableField.APPLICATION_OPEN,
    EventsTableField.APPLICATION_DEADLINE,
    EventsTableField.JURY_FEE,
    EventsTableField.JURY_FEE_AMOUNT,
    EventsTableField.JURY_FEE_PAID,
    EventsTableField.JURY_FEE_PAYMENT_TYPE,
    EventsTableField.ACCEPTANCE_STATUS,
    EventsTableField.APPLICATION_PLATFORM,
    EventsTableField.COST,
    EventsTableField.PAID,
    EventsTableField.OWED,
    EventsTableField.PAYMENTS_DUE,
    EventsTableField.REVENUE,
    EventsTableField.ITEMS_SOLD,
    EventsTableField.LOCATION,
    EventsTableField.STUDIO_TO_EVENT,
    EventsTableField.AVERAGE_OVERALL_RATING,
    EventsTableField.CREATED_BY,
    EventsTableField.INSURANCE_REQUIRED,
    EventsTableField.INSURANCE_STATUS,
    EventsTableField.INSURANCE_SHARED,
    EventsTableField.LODGING_REQUIRED,
    EventsTableField.STAFF_STATUS,
    EventsTableField.DATES_STATUS,
  ],
  getLabel: value => {
    switch (value) {
      case EventsTableField.NAME:
        return 'Name';
      case EventsTableField.CREATED_AT:
        return 'Created';
      case EventsTableField.YEAR:
        return 'Year';
      case EventsTableField.EVENT_PROGRESSION:
        return 'Event Progression';
      case EventsTableField.START_DATE:
        return 'Start Date';
      case EventsTableField.END_DATE:
        return 'End Date';
      case EventsTableField.DATE_RANGE:
        return 'Date Range';
      case EventsTableField.DAYS_COUNT:
        return 'Days Count';
      case EventsTableField.ATTENDANCE:
        return 'Attendance';
      case EventsTableField.VENDORS:
        return 'Vendors';
      case EventsTableField.SPACE_SIZE:
        return 'Space Size';
      case EventsTableField.TEAM:
        return 'Team';
      case EventsTableField.TEAM_MANAGER:
        return 'Team Manager';
      case EventsTableField.VEHICLE:
        return 'Vehicle';
      case EventsTableField.EVENT_ORGANIZER:
        return 'Event Organizer';
      case EventsTableField.EVENT_MANAGER:
        return 'Event Manager';
      case EventsTableField.PARTICIPATION_STATUS:
        return 'Participation Status';
      case EventsTableField.APPLICATION_STATUS:
        return 'Application Status';
      case EventsTableField.APPLICATION_OPEN:
        return 'Application Open Date';
      case EventsTableField.APPLICATION_DEADLINE:
        return 'Application Deadline Date';
      case EventsTableField.JURY_FEE:
        return 'Jury Fee';
      case EventsTableField.JURY_FEE_AMOUNT:
        return 'Jury Fee Amount';
      case EventsTableField.JURY_FEE_PAID:
        return 'Jury Fee Paid';
      case EventsTableField.JURY_FEE_PAYMENT_TYPE:
        return 'Jury Fee Payment Type';
      case EventsTableField.ACCEPTANCE_STATUS:
        return 'Acceptance Status';
      case EventsTableField.APPLICATION_PLATFORM:
        return 'Application Platform';
      case EventsTableField.COST:
        return 'Cost';
      case EventsTableField.PAID:
        return 'Paid';
      case EventsTableField.OWED:
        return 'Owed';
      case EventsTableField.PAYMENTS_DUE:
        return 'Payments Due';
      case EventsTableField.REVENUE:
        return 'Revenue';
      case EventsTableField.ITEMS_SOLD:
        return 'Items Sold';
      case EventsTableField.LOCATION:
        return 'Location';
      case EventsTableField.STUDIO_TO_EVENT:
        return 'Studio to Event';
      case EventsTableField.AVERAGE_OVERALL_RATING:
        return 'Average Overall Rating';
      case EventsTableField.CREATED_BY:
        return 'Created By';
      case EventsTableField.INSURANCE_REQUIRED:
        return 'Insurance Required';
      case EventsTableField.INSURANCE_STATUS:
        return 'Insurance Status';
      case EventsTableField.INSURANCE_SHARED:
        return 'Insurance Shared';
      case EventsTableField.LODGING_REQUIRED:
        return 'Lodging Required';
      case EventsTableField.STAFF_STATUS:
        return 'Staff Status';
      case EventsTableField.DATES_STATUS:
        return 'Dates Status';
      default:
        return '';
    }
  },
  getColor: () => 'unknown'
};

// Sort comparators

const stringOrUndefinedSortComparator = (a: string | undefined, b: string | undefined): number => {
  if (a && b) {
    return a < b ? -1 : 1;
  }

  if (a) {
    return 1;
  }

  if (b) {
    return -1;
  }

  return 0;
};
const numberSortComparator: GridComparatorFn<number>  = (a: number, b: number) => a - b;
const dateSortComparator: GridComparatorFn<DateObjectType | undefined> = DateService.dateComparator(false);
const dateRangeSortComparator: GridComparatorFn<DateRange> = DateService.dateRangeComparator;
const resourceSortComparator = (
  cellParams1: GridSortCellParams,
  cellParams2: GridSortCellParams,
  valueGetter: (row?: GetEventsResponse['data'][number]) => string | undefined
): number => {
  const resource1Name = valueGetter(cellParams1.api.getRow(cellParams1.id));
  const resource2Name = valueGetter(cellParams2.api.getRow(cellParams2.id));

  return stringOrUndefinedSortComparator(resource1Name, resource2Name);
};
const eventProgressionSortComparator: GridComparatorFn<EventsTableFieldValues[EventsTableField.EVENT_PROGRESSION]>  = (a: EventsTableFieldValues[EventsTableField.EVENT_PROGRESSION], b: EventsTableFieldValues[EventsTableField.EVENT_PROGRESSION]) => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  return a.daysUntilStart - b.daysUntilStart;
};

// END: Sort comparators

export const eventsTableFieldHelper: {
  [K in keyof EventsTableFieldValues]: EventsTableFieldHelperValue<K>;
} = {
  [EventsTableField.NAME]: {
    valueGetter: (row) => row.name,
    renderCell: () => ({ value }) => <BaseCell value={value} />,
    width: 300,
  },
  [EventsTableField.CREATED_AT]: {
    valueGetter: (row) => DateService.dayjsTz(row.createdAt),
    renderCell: () => ({ value }) => <BaseCell value={value?.format('MMM D, YYYY')} />,
    sortComparator: dateSortComparator,
  },
  [EventsTableField.YEAR]: {
    width: 100,
    valueGetter: (row) => row.year,
    renderCell: () => ({ value }) => <BaseCell value={value} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.EVENT_PROGRESSION]: {
    valueGetter: (row) => {
      if (!row.startDate || !row.endDate) {
        return undefined;
      }

      return {
        daysUntilStart: -DateService.dayjs().startOf('day').diff(row.startDate.dateAsUtc, 'days'),
        daysUntilEnd: -DateService.dayjs().startOf('day').diff(row.endDate.dateAsUtc, 'days'),
      };
    },
    renderCell: () => ({ value }) => <EventProgressionColorCodeCell value={value} />,
    cellClassName: 'padding-0',
    sortComparator: eventProgressionSortComparator,
  },
  [EventsTableField.START_DATE]: {
    valueGetter: (row) => row.startDate ? DateService.dayjsTz(row.startDate.dateAsUtc) : undefined,
    renderCell: () => ({ value }) => <BaseCell value={value?.format('MMM D, YYYY')} />,
    sortComparator: dateSortComparator,
  },
  [EventsTableField.END_DATE]: {
    valueGetter: (row) => row.endDate ? DateService.dayjsTz(row.endDate.dateAsUtc) : undefined,
    renderCell: () => ({ value }) => <BaseCell value={value?.format('MMM D, YYYY')} />,
    sortComparator: dateSortComparator,
  },
  [EventsTableField.DATE_RANGE]: {
    valueGetter: (row) => (row.startDate && row.endDate) ? ({ start: DateService.dayjsTz(row.startDate.dateAsUtc), end: DateService.dayjsTz(row.endDate.dateAsUtc) }) : null,
    renderCell: () => ({ value }) => <BaseCell value={DateService.getFormattedDateRange(value?.start, value?.end, false)} />,
    sortComparator: dateRangeSortComparator,
  },
  [EventsTableField.DAYS_COUNT]: {
    valueGetter: (row) => row.dates.length,
    renderCell: () => ({ value }) => <BaseCell value={`${value} day${value === 1 ? '' : 's'}`} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.ATTENDANCE]: {
    valueGetter: (row) => row.attendance ?? 0,
    renderCell: () => ({ value }) => <BaseCell value={value || null} />,
    renderEditCell: () => (params) => <TextFieldCell {...params} textFieldProps={{ type: 'number' }} />,
    convertValueToUpdate: (value) => ({ attendance: processFormValueUpdate.number(value) }),
    sortComparator: numberSortComparator,
  },
  [EventsTableField.VENDORS]: {
    valueGetter: (row) => row.vendors ?? 0,
    renderCell: () => ({ value }) => <BaseCell value={value || null} />,
    renderEditCell: () => (params) => <TextFieldCell {...params} textFieldProps={{ type: 'number' }} />,
    convertValueToUpdate: (value) => ({ vendors: processFormValueUpdate.number(value) }),
    sortComparator: numberSortComparator,
  },
  [EventsTableField.SPACE_SIZE]: {
    valueGetter: (row) => row.spaceSize,
    renderCell: () => ({ value }) => <BaseCell value={value} />,
    renderEditCell: () => (params) => <EventSpaceSizeAutocompleteCell {...params} />,
    convertValueToUpdate: (value) => ({ spaceSize: value }),
  },
  [EventsTableField.TEAM]: {
    width: 150,
    valueGetter: (row) => row.team?._id ?? null,
    renderCell: () => ({ row }) => (
      <BaseCell
        startIcon={<StatusIndicator status={row.team?.color ?? 'rgba(0, 0, 0, 0'} />}
        value={row.team?.name}
      />
    ),
    renderEditCell: () => (params) => <EventTeamAutocompleteCell {...params} />,
    convertValueToUpdate: (value) => ({ team: value }),
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.team?.name),
  },
  [EventsTableField.TEAM_MANAGER]: {
    width: 270,
    valueGetter: (row) => row.teamManager?._id ?? null,
    renderCell: ({ users }) => ({ value }) => <AvatarBaseCell value={value} users={users} />,
    renderEditCell: ({ users }) => (params) => <EventUserAutocompleteCell {...params} users={users} />,
    convertValueToUpdate: (value) => ({ teamManager: value }),
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.teamManager?.name),
  },
  [EventsTableField.VEHICLE]: {
    valueGetter: (row) => row.vehicle?._id ?? null,
    renderCell: () => ({ row }) => <BaseCell value={row.vehicle?.name} />,
    renderEditCell: () => (params) => <EventVehicleAutocompleteCell {...params} />,
    convertValueToUpdate: (value) => ({ vehicle: value }),
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.vehicle?.name),
  },
  [EventsTableField.EVENT_ORGANIZER]: {
    valueGetter: (row) => row.eventOrganizer?._id ?? null,
    renderCell: () => ({ row }) => <BaseCell value={row.eventOrganizer?.name} />,
    renderEditCell: ({ eventOrganizers }) => (params) => (
      <AutocompleteCell
        {...params}
        autocompleteProps={{
          options: eventOrganizers.map(user => ({ ...user, id: user._id })),
          getOptionLabel: (option) => option.name,
          // loading: props.loading,
          renderOption: (option) => option.name,
        }}
      />
    ),
    convertValueToUpdate: (value) => ({ eventOrganizer: value }),
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.eventOrganizer?.name),
  },
  [EventsTableField.EVENT_MANAGER]: {
    valueGetter: (row) => row.eventManager?._id ?? null,
    renderCell: ({ users }) => ({ value }) => <AvatarBaseCell value={value} users={users} />,
    renderEditCell: ({ users }) => (params) => <EventUserAutocompleteCell {...params} users={users} />,
    convertValueToUpdate: (value) => ({ eventManager: value }),
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.eventManager?.name),
  },
  [EventsTableField.PARTICIPATION_STATUS]: {
    valueGetter: (row) => row.participationStatus ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.participationStatus}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.participationStatus} {...params} />,
    convertValueToUpdate: (value) => ({ participationStatus: processFormValueUpdate.enumWithUnknown(value) }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.APPLICATION_STATUS]: {
    valueGetter: (row) => row.applicationStatus ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.applicationStatus}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.applicationStatus} {...params} />,
    convertValueToUpdate: (value) => ({ applicationStatus: processFormValueUpdate.enumWithUnknown(value) }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.APPLICATION_OPEN]:{
    width: 240,
    valueGetter: (row) => ({ applicationOpenDate: row.applicationOpenDate ?? null, applicationIsOpen: row.applicationIsOpen ?? false }),
    renderCell: () => ({ row, value }) => <EventApplicationOpenDateColorCodeCell row={row} value={value} />,
    renderEditCell: () => (params) => (
      <DatePickerWithUnknown
        unknownOptionLabel="Application is open"
        unknownOptionHelperText="Check if app. open date is unknown but it is already open"
        unknownOptionFieldName="applicationIsOpen"
        dateFieldName="applicationOpenDate"
        {...params}
      />
    ),
    convertValueToUpdate: (value) => ({
      applicationIsOpen: value?.applicationIsOpen,
      applicationOpenDate: value?.applicationOpenDate,
    }),
    headerAlign: 'center',
    cellClassName: 'padding-0',
  },
  [EventsTableField.APPLICATION_DEADLINE]:{
    width: 240,
    valueGetter: (row) => ({ applicationDeadlineDate: row.applicationDeadlineDate ?? null, applicationOpenUntilFull: row.applicationOpenUntilFull ?? false }),
    renderCell: () => ({ value }) => <EventApplicationDeadlineDateColorCodeCell value={value} />,
    renderEditCell: () => (params) => (
      <DatePickerWithUnknown
        unknownOptionLabel="Application is open until full"
        unknownOptionFieldName="applicationOpenUntilFull"
        dateFieldName="applicationDeadlineDate"
        {...params}
      />
    ),
    convertValueToUpdate: (value) => ({
      applicationOpenUntilFull: value?.applicationOpenUntilFull,
      applicationDeadlineDate: value?.applicationDeadlineDate,
    }),
    headerAlign: 'center',
    cellClassName: 'padding-0',
  },
  [EventsTableField.JURY_FEE]: {
    valueGetter: (row) => yesNoEnumHelpers.yesNo.getEnumValue(row.juryFee),
    renderCell: () => ({ value }) => <YesNoCell value={value} />,
    renderEditCell: () => (params) => <YesNoSelectCell {...params} />,
    convertValueToUpdate: (value) => ({ juryFee: processFormValueUpdate.yesNoUnknown(value) }),
    headerAlign: 'center',
  },
  [EventsTableField.JURY_FEE_AMOUNT]: {
    valueGetter: (row) => row.juryFeeAmount ?? 0,
    renderCell: () => ({ value }) => <BaseCell value={currencyFormatter.format(value ?? 0)} />,
    renderEditCell: () => (params) => (
      <TextFieldCell
        textFieldProps={{
          type: 'number',
          InputProps:{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }
        }}
        {...params}
      />
    ),
    convertValueToUpdate: (value) => ({ juryFeeAmount: processFormValueUpdate.number(value) }),
    sortComparator: numberSortComparator,
  },
  [EventsTableField.JURY_FEE_PAID]: {
    valueGetter: (row) => yesNoEnumHelpers.yesNo.getEnumValue(row.juryFeePaid),
    renderCell: () => ({ value }) => <YesNoCell value={value} />,
    renderEditCell: () => (params) => <YesNoSelectCell {...params} />,
    convertValueToUpdate: (value) => ({ juryFeePaid: processFormValueUpdate.yesNoUnknown(value) }),
    headerAlign: 'center',
  },
  [EventsTableField.JURY_FEE_PAYMENT_TYPE]: {
    valueGetter: (row) => row.juryFeePaymentType ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <BaseCell value={value !== UnknownEnum.unknown ?eventEnumHelpers.paymentType.getLabel(value) : null} />,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.paymentType} {...params} />,
    convertValueToUpdate: (value) => ({ juryFeePaymentType: processFormValueUpdate.enumWithUnknown(value) }),
  },
  [EventsTableField.APPLICATION_PLATFORM]: {
    width: 160,
    valueGetter: (row) => row.applicationPlatform ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.applicationPlatform}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.applicationPlatform} {...params} />,
    convertValueToUpdate: (value) => ({ applicationPlatform: processFormValueUpdate.enumWithUnknown(value) }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.ACCEPTANCE_STATUS]: {
    valueGetter: (row) => row.acceptanceStatus ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.acceptanceStatus}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.acceptanceStatus} {...params} />,
    convertValueToUpdate: (value) => ({ acceptanceStatus: processFormValueUpdate.enumWithUnknown(value) }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.COST]: {
    valueGetter: (row) => row.cost,
    renderCell: () => ({ value }) => <BaseCell value={value ? currencyFormatter.format(value) : null} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.PAID]: {
    valueGetter: (row) => row.paid,
    renderCell: () => ({ value, row }) => <BaseCell value={row.cost ? currencyFormatter.format(value ?? 0) : null} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.OWED]: {
    valueGetter: (row) => row.cost ? row.cost - (row.paid ?? 0) : undefined,
    renderCell: () => ({ row, value }) => <EventOwedColorCodeCell row={row} value={value} />,
    headerAlign: 'center',
    cellClassName: 'padding-0',
    sortComparator: numberSortComparator,
  },
  [EventsTableField.PAYMENTS_DUE]: {
    width: 250,
    valueGetter: (row) => getEventClosestPaymentDue(row),
    renderCell: () => ({ row, value }) => <EventPaymentsDueColorCodeCell row={row} value={value} />,
    headerAlign: 'center',
    cellClassName: 'padding-0',
  },
  [EventsTableField.REVENUE]: {
    width: 100,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (row) => row.salesRevenue,
    renderCell: () => ({ value }) => <BaseCell value={value ? currencyFormatter.format(value) : null} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.ITEMS_SOLD]: {
    width: 100,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (row) => row.salesCount,
    renderCell: () => ({ value }) => <BaseCell value={value} />,
    sortComparator: numberSortComparator,
  },
  [EventsTableField.LOCATION]: {
    width: 440,
    valueGetter: (row) => ({ address: row.place.address, placeId: row.place.googlePlaceId }),
    renderCell: () => ({ value }) => <BaseCell value={value?.address} />,
    renderEditCell: () => params => <EventLocationAutocompleteCell {...params} />,
    convertValueToUpdate: ({ placeId }) => ({ placeId }),
  },
  [EventsTableField.STUDIO_TO_EVENT]: {
    width: 255,
    valueGetter: (row) => row.place.fromStudioMatrix,
    renderCell: () => ({ value }) => <BaseCell value={value && getMatrixDisplayText(value)} />,
    sortComparator: (a, b) => a.duration - b.duration,
  },
  [EventsTableField.AVERAGE_OVERALL_RATING]: {
    valueGetter: (row) => row.averageOverallRating,
    renderCell: () => ({ value }) => <RatingCell value={value} />,
    sortComparator: numberSortComparator,
    headerAlign: 'center',
    align: 'center',
  },
  [EventsTableField.CREATED_BY]: {
    valueGetter: (row) => row.createdBy?._id ?? null,
    renderCell: ({ users }) => ({ value }) => <AvatarBaseCell value={value} users={users} />,
    sortComparator: (_, __, cellParams1, cellParams2) => resourceSortComparator(cellParams1, cellParams2, row => row?.createdBy?.name),
  },
  [EventsTableField.INSURANCE_REQUIRED]: {
    valueGetter: (row) => yesNoEnumHelpers.yesNo.getEnumValue(row.insurance?.isRequired),
    renderCell: () => ({ value }) => <YesNoCell value={value} />,
    renderEditCell: () => (params) => <YesNoSelectCell {...params} />,
    convertValueToUpdate: (value) => ({ insurance: { isRequired: processFormValueUpdate.yesNoUnknown(value) } }),
  },
  [EventsTableField.INSURANCE_STATUS]: {
    valueGetter: (row) => row.insurance?.status ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.insuranceStatus}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.insuranceStatus} {...params} />,
    convertValueToUpdate: (value) => ({ insurance: { status: processFormValueUpdate.enumWithUnknown(value) } }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.INSURANCE_SHARED]: {
    valueGetter: (row) => row.insurance?.shared ?? UnknownEnum.unknown,
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.insuranceShared}/>,
    renderEditCell: () => (params) => <EnumSelectCell enumHelpers={eventEnumHelpers.insuranceShared} {...params} />,
    convertValueToUpdate: (value) => ({ insurance: { shared: processFormValueUpdate.enumWithUnknown(value) } }),
    cellClassName: 'padding-0',
  },
  [EventsTableField.LODGING_REQUIRED]: {
    valueGetter: (row) => yesNoEnumHelpers.yesNo.getEnumValue(row.lodging?.isRequired),
    renderCell: () => ({ value }) => <YesNoCell value={value} />,
    renderEditCell: () => (params) => <YesNoSelectCell {...params} />,
    convertValueToUpdate: (value) => ({ lodging: { isRequired: processFormValueUpdate.yesNoUnknown(value) } }),
  },
  [EventsTableField.STAFF_STATUS]: {
    valueGetter: (row) => getEventStaffStatus(row.dates),
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.staffStatus} />,
    cellClassName: 'padding-0',
  },
  [EventsTableField.DATES_STATUS]: {
    valueGetter: (row) => getEventDatesStatus(row.dates),
    renderCell: () => ({ value }) => <EnumColorCodeCell value={value} helpers={eventEnumHelpers.datesStatus} />,
    cellClassName: 'padding-0',
  },
};

export const getEventsTableColumns = (resources: EventsTableFieldHelperResouces): GridColDef<GetEventsResponse['data'][number]>[] => {
  return eventsTableFieldEnumHelpers.enumValues.map(field => {
    const { valueGetter, renderCell, renderEditCell, ...helper } = eventsTableFieldHelper[field];

    return {
      field: field,
      headerName: eventsTableFieldEnumHelpers.getLabel(field),
      valueGetter: (_v, row) => valueGetter(row),
      editable: !!renderEditCell,
      renderEditCell: renderEditCell?.(resources),
      renderCell: renderCell(resources),
      sortable: !!helper.sortComparator,

      width: 200,
      type: undefined,
      ...helper,
    };
  });
};

export const eventTableEditableFields = eventsTableFieldEnumHelpers.enumValues.filter(field => !!eventsTableFieldHelper[field].renderEditCell);

const convertFieldValueToUpdate = <Field extends EventsTableField>(field: Field, newRow: EventsTableFieldValues, oldRow: GetEventsResponse['data'][number]) => {
  return eventsTableFieldHelper[field].convertValueToUpdate?.(newRow[field], oldRow);
};

export const processEventTableRowUpdate = (newRow: EventsTableFieldValues, oldRow: GetEventsResponse['data'][number]) => {
  return eventTableEditableFields.reduce((r, field) => {
    if (
      newRow[field] !== undefined
      // && typeof newRow[field] === typeof eventsTableFieldHelper[field].valueGetter(oldRow)
      && !_.isEqual(newRow[field], eventsTableFieldHelper[field].valueGetter(oldRow))
    ) {
      const update = convertFieldValueToUpdate(field, newRow, oldRow);

      return {
        ...r,
        ...update
      };
    }

    return r;
  }, {} as UpdateEventInput);
};

// export const eventStaffListField: GridColDef = {
//   field: EventsTableFields.STAFF_LIST,
//   headerName: 'Staff List',
//   width: 200,
//   valueGetter: (params: GridValueGetterParams<{ dates: Pick<GetEventsResponse['data'][number]['dates'][number], 'staff'>[] }>) => {
//     const staffList = params.row.dates.reduce((r: GetEventsResponse['data'][number]['dates'][number]['staff'], date) => [
//       ...r,
//       ...date.staff.filter((staff) => !r.find((element) => element.user._id === staff.user._id))
//     ], []);

//     return staffList;
//   },
//   renderCell: () => ({ value }) => <StaffList staffList={value} />
// };


// const numberOrNullOrUndefinedComparator = (a?: number | null, b?: number | null) => {
//   if (a === null || a === undefined) {
//     return 1;
//   }
//   if (b === null || b === undefined) {
//     return -1;
//   }

//   return a - b;
// };


// NOT SURE IF NEEDED
//   {
//     field: EventsTableFields.FILES_COUNT,
//     headerName: 'Files #',
//     valueGetter: () => ({ row }) => row.files?.length ?? 0,
//     width: 70,
//   },
// {
//     field: EventsTableFields.NOTES,
//     headerName: 'Notes',
//     width: 200,
//   },
//   {
//     field: EventsTableFields.PAYMENT_NOTE,
//     headerName: 'Payment Note',
//     valueGetter: () => ({ row }) => row.paymentNote,
//     width: 200,
//   },


// !! START HERE !!


//   {
//     field: EventsTableFields.APPLICATION_PLATFORM,
//     headerName: 'Application Platform',
//     valueGetter: () => ({ row }) => row.applicationPlatform ?? UnknownEnum.unknown,
//     width: 160,
//     renderCell: ApplicationPlatformCell,
//   },

//   {
//     field: EventsTableFields.TEAM_NAME,
//     headerName: 'Team',
//     width: 130,
//     valueGetter: () => ({ row }) => row.team?.name,
//     renderCell: () => ({ row }) => <TeamCell team={row.team ?? null} />,
//   },
//   {
//     field: EventsTableFields.EVENT_ORGANIZER_NAME,
//     headerName: 'Event Organizer',
//     valueGetter: () => ({ row }) => row.eventOrganizer?.name,
//     width: 200,
//   },
//   {
//     field: EventsTableFields.EVENT_MANAGER_NAME,
//     headerName: 'Event Manager',
//     valueGetter: () => ({ row }) => row.eventManager?.name,
//     width: 150,
//   },
//   {
//     field: EventsTableFields.VEHICLE_NAME,
//     headerName: 'Vehicle',
//     valueGetter: () => ({ row }) => row.vehicle?.name,
//     width: 120,
//   },

//   {
//     field: EventsTableFields.LOCATION,
//     headerName: 'Location',
//     valueGetter: () => ({ row }) => row.place.address,
//     width: 300,
//   },
//   {
//     field: EventsTableFields.STUDIO_TO_EVENT,
//     headerName: 'Studio → Event',
//     width: 220,
//     valueGetter: () => ({ row }) => row.place.fromStudioMatrix,
//     valueFormatter: () => ({ value }) => getMatrixDisplayText(value),
//   },

//   {
//     field: EventsTableFields.ATTENDANCE,
//     headerName: 'Attendance',
//     width: 100,
//   },
//   {
//     field: EventsTableFields.VENDORS,
//     headerName: '# of Vendors',
//     width: 100,
//   },
//   {
//     field: EventsTableFields.SPACE_SIZE,
//     headerName: 'Space Size',
//     width: 150,
//   },

//   {
//     field: EventsTableFields.STAFF_STATUS,
//     headerName: 'Staff Status',
//     valueGetter: () => ({ row }) => getEventStaffStatus(row.dates),
//     width: 100,
//     renderCell: StaffStatusCell,
//   },
//   eventStaffListField,
//   {
//     field: EventsTableFields.DATES_STATUS,
//     headerName: 'Dates Status',
//     valueGetter: () => ({ row }) => getEventDatesStatus(row.dates),
//     width: 100,
//     renderCell: DatesStatusCell,
//   },

//   {
//     field: EventsTableFields.LODGING_REQUIRED,
//     headerName: 'Lodging Required',
//     width: 130,
//     valueGetter: () => ({ row }) => row.lodging?.isRequired,
//     renderCell: () => ({ value }) => (
//       <Box width="100%" display="flex" justifyContent="center">
//         <YesNoUnknownIconSwitch value={value} />
//       </Box>
//     ),
//     type: 'singleSelect',
//     valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
//   },

//   {
//     field: EventsTableFields.INSURANCE_REQUIRED,
//     headerName: 'Insurance Required',
//     width: 150,
//     valueGetter: () => ({ row }) => row.insurance?.isRequired,
//     renderCell: () => ({ value }) => (
//       <Box width="100%" display="flex" justifyContent="center">
//         <YesNoUnknownIconSwitch value={value} />
//       </Box>
//     ),
//     type: 'singleSelect',
//     valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
//   },
//   {
//     field: EventsTableFields.INSURANCE_STATUS,
//     headerName: 'Insurance Status',
//     valueGetter: () => ({ row }) => row.insurance?.status ?? UnknownEnum.unknown,
//     width: 200,
//     renderCell: InsuranceStatusCell,
//     type: 'singleSelect',
//     valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceStatus),
//   },
//   {
//     field: EventsTableFields.INSURANCE_SHARED,
//     headerName: 'Insurance Shared',
//     valueGetter: () => ({ row }) => row.insurance?.shared ?? UnknownEnum.unknown,
//     width: 200,
//     renderCell: InsuranceSharedCell,
//     type: 'singleSelect',
//     valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceShared),
//   },


//   {
//     field: EventsTableFields.AVERAGE_OVERALL_RATING,
//     headerName: 'Average Rating',
//     width: 140,
//     renderCell: () => ({ value }) => {
//       return <Rating value={value} readOnly />;
//     }
//   },
//   {
//     field: EventsTableFields.CREATED_BY,
//     headerName: 'Created By',
//     valueGetter: () => ({ row }) => row.createdBy?.name,
//     width: 140,
//   }
// ];
