import { EventAcceptanceStatusEnum, EventApplicationStatusEnum } from 'api/resources';
import { EventClosestPaymentDue, EventClosestPaymentDueType } from 'helpers';
import { currencyFormatter, DateService } from 'services';
import { ColorCodeCell } from '../ColorCode.component';
import { GetEventsResponse } from 'api/actions';

export type EventPaymentsDueProps = {
  row: GetEventsResponse['data'][number];
  value?: EventClosestPaymentDue;
};

export const EventPaymentsDueColorCodeCell: React.FC<EventPaymentsDueProps> = ({ row, value }) => {
  if (!value) {
    return <ColorCodeCell color="unknown" label="No payments due" />;
  }


  if (row.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
    switch (value.dueType) {
      case EventClosestPaymentDueType.ASAP:
        return (
          <ColorCodeCell
            color="unknown"
            label="No payments due until accepted"
            caption={`Then ${currencyFormatter.format(value.paymentDue.amountDue)} due ASAP`}
          />
        );
      case EventClosestPaymentDueType.UPON_ACCEPTANCE:
        return (
          <ColorCodeCell
            color="unknown"
            label="No payments due until accepted"
            caption={`${currencyFormatter.format(value.paymentDue.amountDue)} due upon acceptance`}
          />
        );
      case EventClosestPaymentDueType.WITH_APPLICATION:
        return (
          <ColorCodeCell
            color={row.applicationStatus === EventApplicationStatusEnum.applied ? 'error' : 'warning'}
            solid={row.applicationStatus === EventApplicationStatusEnum.applied}
            label={`${currencyFormatter.format(value.paymentDue.amountDue)} due with application`}
          />
        );
      case EventClosestPaymentDueType.DATE:
        return (
          <ColorCodeCell
            color="unknown"
            label="No payments due until accepted"
            caption={`Then ${currencyFormatter.format(value.paymentDue.amountDue)} due on ${DateService.dayjsTz(value.paymentDue.dueDateAsUtc).format('MMM D, YYYY')}`}
          />
        );
    }
  }
  const caption = `Payment ${value.paymentNumber} of ${row.paymentsDue.length}`;

  switch (value.dueType) {
    case EventClosestPaymentDueType.ASAP:
      return (
        <ColorCodeCell
          color="error"
          label={`${currencyFormatter.format(value.paymentDue.amountDue)} due ASAP`}
          caption={caption}
        />
      );
    case EventClosestPaymentDueType.UPON_ACCEPTANCE:
      return (
        <ColorCodeCell
          color="error"
          label={`${currencyFormatter.format(value.paymentDue.amountDue)} due upon acceptance`}
          caption={caption}
        />
      );
    case EventClosestPaymentDueType.WITH_APPLICATION:
      return (
        <ColorCodeCell
          color={row.applicationStatus === EventApplicationStatusEnum.applied ? 'error' : 'warning'}
          solid={row.applicationStatus === EventApplicationStatusEnum.applied}
          label={`${currencyFormatter.format(value.paymentDue.amountDue)} due with application`}
        />
      );
    case EventClosestPaymentDueType.DATE:
      const dayDiff = DateService.dayjsTz(value.paymentDue.dueDateAsUtc).diff(DateService.dayjsTz(), 'days');
      const dueDatePast = dayDiff < 0;

      return (
        <ColorCodeCell
          color={dueDatePast ? 'error' : 'warning'}
          solid={dueDatePast}
          label={dueDatePast ? `${currencyFormatter.format(value.paymentDue.amountDue)} overdue by ${-dayDiff} days` : `${currencyFormatter.format(value.paymentDue.amountDue)} due in ${dayDiff} days`}
          caption={`Due date: ${DateService.dayjsTz(value.paymentDue.dueDateAsUtc).format('MMM D, YYYY')} (${caption})`}
        />
      );
  }
};