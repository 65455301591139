import { CloseOutlined, SearchOffOutlined, ViewColumnOutlined } from '@mui/icons-material';
import { Box, Checkbox, Divider, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import { useEventsManagementContext } from 'pages/EventsManagement2/utils';
import React, { useState } from 'react';
import { EventsTableFieldPreview, eventsTableFieldEnumHelpers, PopoverButton, eventTableEditableFields } from 'components';

export const ColumnsInput = () => {
  const { events, users, eventOrganizers, state: { columns }, onSetState } = useEventsManagementContext();

  const [ searchQuery, setSearchQuery ] = useState('');

  const filteredColumns = eventsTableFieldEnumHelpers.enumValues.filter((column) =>
    eventsTableFieldEnumHelpers.getLabel(column).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <PopoverButton
      id="events_management_columns"
      label="Columns"
      variant="outlined"
      color="primary"
      size="medium"
      startIcon={<ViewColumnOutlined />}
      PopoverProps={{
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 0, horizontal: 0 },
        PaperProps: {
          sx: { height: '100%', boxShadow: 2 },
        }
      }}
      onClose={() => setSearchQuery('')}
    >
      {({ handleClose }) => {
        return (
          <Box display="flex" flexDirection="column" p={1} width={600} height="100%">
            {/* Header with Search and Close Button */}
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <TextField
                variant="standard"
                size="small"
                id="search_events_columns"
                placeholder="Search columns..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ flex: 1, ml: 1 }}
              />
              <IconButton onClick={handleClose} size="small">
                <CloseOutlined fontSize="small" />
              </IconButton>
            </Box>
            <Box mx={-1} mt={1}><Divider /></Box>

            {/* List of Checkboxes */}
            <Box display="flex" flexDirection="column" flex={1}>
              {filteredColumns.map((column) => {
                const checked = columns.includes(column);
                const disabled = false;
                const onChange = () =>
                  !disabled && onSetState({
                    columns: checked ? columns.filter((c) => c !== column) : [ ...columns, column ],
                  });

                return (

                  <Box
                    key={column}
                    display="flex"
                    alignItems="center"
                    borderBottom={theme => `1px solid ${theme.palette.divider}`}
                    py={0.5}
                    mx={-1}
                    px={1}
                    bgcolor={theme => checked ? theme.palette.primary.background : undefined}
                    onClick={onChange}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Checkbox checked={checked} disabled={disabled} onChange={onChange} />
                    <Box
                      flex={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography width={300} display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        {eventsTableFieldEnumHelpers.getLabel(column)}
                        {eventTableEditableFields.includes(column) && (
                          <Typography variant="body2" component="span" color="primary.main" display="flex" alignItems="center">
                            Editable
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                    {events[0] ? (
                      <Box width={230}>
                        <EventsTableFieldPreview event={events[0]} resources={{ users, eventOrganizers }} column={column} />
                      </Box>
                    ) : (
                      <Skeleton variant="rectangular" width={230} height={45} />
                    )}
                  </Box>
                );
              })}
              {/* No Results Message */}
              {filteredColumns.length === 0 && (
                <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                  <Typography color="text.secondary" display="flex" alignItems="center" gap={1}>
                    <SearchOffOutlined />No matching results found.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    </PopoverButton>
  );
};
