import { MenuItem, Select } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';

export type SelectCellOption = {
  value: string;
  label: React.ReactNode;
};

// Remove serilization (?)
type SelectCellBaseProps = { options: SelectCellOption[] } & GridRenderEditCellParams;
type SelectCellPropsWithSerialization = { serializeValue: (value: string) => unknown; deserializeValue: (value: unknown) => string } & SelectCellBaseProps;

export type SelectCellProps = SelectCellBaseProps | (SelectCellPropsWithSerialization & { serializeValue: never; deserializeValue: never });

export const SelectCell: React.FC<SelectCellProps> = ({ options, ...props }) => {
  const { id, value, field, api } = props;

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = 'deserializeValue' in props ? props.deserializeValue(event.target.value) : event.target.value;

    api.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Select value={'serializeValue' in props ? props.serializeValue(value) : value} onChange={handleValueChange} fullWidth disabled={!options.length}>
      {options.map((option) =>
        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
    </Select>
  );
};
