import { EVENT_SPACE_SIZE_OPTIONS } from 'constants/event';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';

export type EventSpaceSizeAutocompleteCellProps = GridRenderEditCellParams;

export const EventSpaceSizeAutocompleteCell: React.FC<EventSpaceSizeAutocompleteCellProps> = props => {
  const { id, value, field, api } = props;
  const onChange = (newValue: string | null) => {
    api.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <MuiAutocomplete
      open
      fullWidth
      freeSolo
      disableClearable
      filterOptions={(options, _) => options}
      options={EVENT_SPACE_SIZE_OPTIONS}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          // error={Boolean(spaceSizeError)}
          // helperText={spaceSizeError}
          autoFocus
        />
      )}
    />
  );
};