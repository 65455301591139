import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useEventVehicleChangeOptions } from 'queries';
import { EventResourceAutocompleteCell } from './EventResourceAutocomplete.component';
import { GetEventsResponse } from 'api/actions';

export type EventVehicleAutocompleteCellProps = GridRenderEditCellParams<GetEventsResponse['data'][number], string | null>;

export const EventVehicleAutocompleteCell: React.FC<EventVehicleAutocompleteCellProps> = props => {
  const { data: vehicleChangeOptions = [], isInitialLoading: loading } = useEventVehicleChangeOptions(props.row._id);

  return <EventResourceAutocompleteCell {...props} options={vehicleChangeOptions} loading={loading} />;
};