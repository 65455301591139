import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { updateEvent, GetEventsResponse } from 'api/actions';
import { EventFutureInterestStatusEnum } from 'api/resources';
import { EventPreviewTypeEnum, useAlertSnackbar, useEventPreviewContext } from 'components';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper, processFormValueUpdate } from 'helpers';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UnknownEnum } from 'types';

const getDuplicateEventsTableColumns = (): GridColDef<GetEventsResponse['data'][number]>[] => [
  {
    field: 'futureInterestStatus',
    headerName: 'Future Interest Status',
    width: 160,
    valueFormatter: (value) => eventEnumHelpers.futureInterestStatus.getLabel(value),
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.futureInterestStatus),
  },
];

export const EventsDuplicate = () => {
  const { events } = useContext(EventsManagementPageContext);
  const { setOpenEvent } = useEventPreviewContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationFn: ({ eventId, futureInterestStatus }: {eventId: string; futureInterestStatus: EventFutureInterestStatusEnum | null }) => {
      return updateEvent(eventId, { futureInterestStatus });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);

      snackbar.success('Event updated');
    }
  });
  const [ duplicatedEvent, setDuplicatedEvent ] = useState<GetEventsResponse['data'][number] | null>(null);

  const processRowUpdate = useCallback(async (newRow: Omit<GetEventsResponse['data'][number], 'futureInterestStatus'> & { futureInterestStatus: EventFutureInterestStatusEnum | UnknownEnum }, oldRow: GetEventsResponse['data'][number]) => {
    const update = processFormValueUpdate.enumWithUnknown(newRow.futureInterestStatus);

    if(update !== undefined && oldRow.futureInterestStatus !== update) {
      await updateMutation.mutateAsync({ eventId: newRow._id, futureInterestStatus: update });

      setDuplicatedEvent(oldRow);

      return newRow;
    }

    return oldRow;
  }, [ updateMutation ]);

  useEffect(() => {
    if(duplicatedEvent) {
      const newEvent = events.find(event => event.eventCollection.baseEventId === duplicatedEvent.eventCollection.baseEventId && event.year === duplicatedEvent.year + 1);

      if (newEvent) {
        setOpenEvent(newEvent._id, EventPreviewTypeEnum.MAIN);
        setDuplicatedEvent(null);
      }
    }
  }, [ duplicatedEvent, events, setOpenEvent ]);

  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.eventsDuplicate}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'eventTimeframe', 'daysCount', 'futureInterestStatus', 'participationStatus' , 'acceptanceStatus' ],
        initialSortModel: [ { field: 'eventTimeframe', sort: 'asc' } ]
      }}
      extraColumns={getDuplicateEventsTableColumns()}
      processRowUpdate={processRowUpdate}
    />
  );
};
