import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import { SwapHorizOutlined } from '@mui/icons-material';
import { GetTeamsResponse } from 'api/actions';
import { DeepPartial, UnknownEnum } from 'types';
import { InventoryTableEditableMode, InventoryTableTransferConfig } from '../hooks';

export type InventoryTableTeamColumnsInputProps = {
  mode: InventoryTableEditableMode | UnknownEnum;
  teams:  GetTeamsResponse['data'];
  setSelectedTeamIds: (teamIds: string[]) => void;
  selectedTeamIds: string[];
  setTransferConfig: (transferConfig: InventoryTableTransferConfig) => void;
  transferConfig: InventoryTableTransferConfig | null;
  getTeamDisabled?: (teamId: string) => boolean;
};

export const InventoryTableTeamColumnsInput: React.FC<InventoryTableTeamColumnsInputProps> = props => {
  const [ transferConfig, setTransferConfig ] = useState<DeepPartial<InventoryTableTransferConfig> | null>(props.transferConfig);
  const onCompleteTransferConfig = props.setTransferConfig;

  useEffect(() => {
    if (transferConfig) {
      const inTeam = transferConfig.in;
      const outTeam = transferConfig.out;

      if (!!inTeam && !!outTeam) {
        onCompleteTransferConfig({ in: inTeam, out: outTeam });
      }
    }
  }, [ onCompleteTransferConfig, transferConfig ]);

  const onSelectTeam = useCallback((teamId: string) => {
    const prev = props.selectedTeamIds;

    const teamExists = prev.some(_id => _id === teamId);

    if (teamExists) {
      props.setSelectedTeamIds(prev.filter(_id => _id !== teamId));
      return;
    }

    props.setSelectedTeamIds(props.teams.filter(team => [ ...prev, teamId ].includes(team._id)).sort((a, b) => a.order - b.order).map(team => team._id));
  }, [ props ]);

  const content = useMemo(() => {
    if (props.mode === InventoryTableEditableMode.transfer) {
      const onSwapTransfer = () => {
        setTransferConfig({
          in: transferConfig?.out,
          out: transferConfig?.in,
        });
      };

      return (
        <Box display="flex" gap={2} alignItems="center">
          <Box flex={1} textAlign="center" border={theme => `1px solid ${theme.palette.error.dark}`} py={1}>
            <Select
              size="small"
              value={transferConfig?.out}
              onChange={(e) => setTransferConfig(prev => ({ ...(prev ?? {}), out: e.target.value }))}
              sx={{ background: theme => theme.palette.background.paper, width: { xs: '100px', sm: '150px' } }}
            >
              {props.teams.map((team) => {
                return (
                  <MenuItem key={team._id} value={team._id} disabled={props.getTeamDisabled?.(team._id) || transferConfig?.in === team._id}>
                    {team.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography>{'>>>'}</Typography>
          <Box flex={1} textAlign="center" border={theme => `1px solid ${theme.palette.success.dark}`} py={1}>
            <Select
              size="small"
              value={transferConfig?.in}
              onChange={(e) => setTransferConfig(prev => ({ ...(prev ?? {}), in: e.target.value }))}
              sx={{ background: theme => theme.palette.background.paper, width: { xs: '100px', sm: '150px' } }}
            >
              {props.teams.map((team) => {
                return (
                  <MenuItem key={team._id} value={team._id} disabled={props.getTeamDisabled?.(team._id) || transferConfig?.out === team._id}>
                    {team.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Button startIcon={<SwapHorizOutlined />} onClick={onSwapTransfer}>Swap</Button>
        </Box>
      );
    }

    return (
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))">
        {props.teams.map((team) => {
          const isSelected = props.selectedTeamIds.some((_id) => _id === team._id);

          return (
            <FormControlLabel
              key={team._id}
              control={<Checkbox checked={isSelected} onClick={() => onSelectTeam(team._id)} />}
              label={team.name}
              disabled={props.mode === UnknownEnum.unknown || props.getTeamDisabled?.(team._id)}
            />
          );
        })}
      </Box>
    );
  }, [ onSelectTeam, props, transferConfig?.in, transferConfig?.out ]);

  return (
    <>
      {!props.selectedTeamIds.length && <Alert severity="error">No teams selected</Alert>}
      {content}
    </>
  );
};