import { AddressSuggestionsResponse, getAddressSuggestions } from 'api/actions';
import { useEffect, useState } from 'react';


export function useDebouncedValue<T>(value: T, delay = 200) {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
}

export type UseAddressSuggestionsProps = {
  userInput: string;
  initialValue: string;
  debounceDelay?: number;
};

export const useAddressSuggestions = ({
  userInput,
  initialValue,
  debounceDelay = 200,
}: UseAddressSuggestionsProps) => {
  const [ loading, setLoading ] = useState(true);
  const debouncedUserInput = useDebouncedValue(userInput, debounceDelay);
  const [ suggestions, setSuggestions ] = useState<AddressSuggestionsResponse['predictions']>([]);

  useEffect(() => {
    const userInput = debouncedUserInput.trim().replace(/[^A-Za-z0-9 ]/g, '');

    // Prevent fetching suggestions if the input matches the initial value
    if (userInput === initialValue.trim().replace(/[^A-Za-z0-9 ]/g, '')) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    const fetcher = () =>
      getAddressSuggestions(userInput)
        .then((suggestions) => {
          setSuggestions(suggestions.predictions);
          setLoading(false);
        })
        .catch(() => {
          setSuggestions([]);
          setLoading(false);
        });

    if (!userInput) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    void fetcher();
  }, [ debouncedUserInput, initialValue ]);

  return { loading, suggestions };
};

