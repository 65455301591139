import React, { createContext, useContext, useMemo, useState } from 'react';
import { Box, Divider, IconButton, Typography, lighten, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { CloseOutlined, OpenInNew } from '@mui/icons-material';
import { QueryClient } from '@tanstack/react-query';

import { GetEventsResponse } from 'api/actions';
import { useSalesForEvent } from 'queries';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

import {
  EditableImage,
  JumpNav,
  PreviewDrawer,
  StickyStackItem,
  WrapWithStatusIndicator
} from 'components';
import { CALENDAR_FULL_SCREEN_MODAL_ZINDEX } from 'constants/full-screen';
import { useCurrentUser, UseCurrentUserOutput } from 'contexts';
import { useNavigate } from 'react-router-dom';

import { EventPreviewContentFeedback, EventPreviewContentMain, EventPreviewContentSales, EventPreviewContentScheduling, EventPreviewContentTeamScheduling } from './variants';
import { eventPreviewTypeEnumHelpers, eventPageSectionEnumHelpers } from '../helpers';
import { EventPageSection, EventPreviewTypeEnum } from '../types';
import { EventContextProvider } from '../event.context';
import { UserEmployeeRoleEnum } from 'api/resources';

type EventPreviewContextType = {
  openEventId: string | null;
  setOpenEvent: (eventId: string, variant: EventPreviewTypeEnum) => void;
  allowedVariants: EventPreviewTypeEnum[];
  setAllowedVariants: (allowedVariants: EventPreviewTypeEnum[]) => void;
};

const EventPreviewContext = createContext<EventPreviewContextType>({
  openEventId: null,
  setOpenEvent: () => {},
  allowedVariants: [],
  setAllowedVariants: () => {},
});

export const useEventPreviewContext = () => useContext(EventPreviewContext);

export type EventPreviewContainerProps = {
  children: React.ReactNode;
  events: GetEventsResponse['data'];
  initialAlloweVariants?: EventPreviewTypeEnum[];
  loading?: boolean;
  invalidateQueriesHandler: (queryClient: QueryClient, eventId: string | null) => void | Promise<void>;
  schedulingUserId?: string;
};

const isUserAuthorizedForVariant = (isAuthorizedEmployee: UseCurrentUserOutput['isAuthorizedEmployee'], isAdmin: boolean, variant: EventPreviewTypeEnum) => {
  if (variant === EventPreviewTypeEnum.MAIN || variant === EventPreviewTypeEnum.FEEDBACK) {
    return isAuthorizedEmployee([ UserEmployeeRoleEnum.teamMember, UserEmployeeRoleEnum.eventManager ]);
  }

  if (variant === EventPreviewTypeEnum.SCHEDULING || variant === EventPreviewTypeEnum.TEAM_SCHEDULING) {
    return isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  }
  return isAdmin;
};

export const EventPreviewContainer: React.FC<EventPreviewContainerProps> = ({ children, events, initialAlloweVariants: inputInitialAlloweVariants, invalidateQueriesHandler, schedulingUserId }) => {
  const { isAuthorizedEmployee, isAdmin } = useCurrentUser();
  const [ openEventId, setOpenEventId ] = useState<string | null>(null);

  const initialAlloweVariants = useMemo(() => {
    return (inputInitialAlloweVariants ?? eventPreviewTypeEnumHelpers.enumValues).filter(variant => isUserAuthorizedForVariant(isAuthorizedEmployee, isAdmin, variant));
  }, [ inputInitialAlloweVariants, isAdmin, isAuthorizedEmployee ]);
  const [ allowedVariants, setAllowedVariants ] = useState<EventPreviewTypeEnum[]>(initialAlloweVariants);
  const onSetAllowedVariants = (variants: EventPreviewTypeEnum[]) => {
    if (!variants.includes(variant)) {
      setVariant(allowedVariants[0]);
    }

    setAllowedVariants(variants.filter(variant => isUserAuthorizedForVariant(isAuthorizedEmployee, isAdmin, variant)));
  };

  const [ variant, setVariant ] = useState<EventPreviewTypeEnum>(EventPreviewTypeEnum.MAIN);

  const setOpenEvent = (eventId: string, variant: EventPreviewTypeEnum) => {
    setOpenEventId(eventId);
    setVariant(variant);
  };

  const event = events.find(event => event._id === openEventId)!;

  return (
    <EventPreviewContext.Provider value={{ setOpenEvent, openEventId, allowedVariants, setAllowedVariants: onSetAllowedVariants }}>
      <EventPreviewDrawer
        event={event}
        schedulingUserId={schedulingUserId}
        variant={variant}
        setVariant={setVariant}
        onClose={() => setOpenEventId(null)}
        invalidateQueriesHandler={(queryClient) => invalidateQueriesHandler(queryClient, event._id)}
      />
      {children}
    </EventPreviewContext.Provider>
  );
};

type EventPreviewDrawerProps = {
  event: GetEventsResponse['data'][number] | null;
  invalidateQueriesHandler: (queryClient: QueryClient) => void | Promise<void>;
  onClose: () => void;
  variant: EventPreviewTypeEnum;
  setVariant: React.Dispatch<React.SetStateAction<EventPreviewTypeEnum>>;
  schedulingUserId?: string;
};

const EVENT_PREVIEW_CONTENT_MAIN_HIDDEN_SECTIONS = [ EventPageSection.PHOTOS ];

const EventPreviewDrawer: React.FC<EventPreviewDrawerProps> = ({ event, variant, setVariant, ...props }) => {
  const currentUser = useCurrentUser();
  const { data: eventSales = [], isInitialLoading: eventSalesLoading } = useSalesForEvent(event?._id ?? '', !!event && !!variant);

  if (!event || !variant) {
    return null;
  }

  return (
    <PreviewDrawer open={!!event}>
      <EventContextProvider event={event} loading={eventSalesLoading} eventSales={eventSales} invalidateQueriesHandler={props.invalidateQueriesHandler}>
        {context => {
          const getBadgeContent = (sectionId: EventPageSection) => context.warningItems.find(warningItem => warningItem.section === sectionId)?.reasonItems.length;

          const tabs = eventPageSectionEnumHelpers.enumValues
            .filter(sectionId => !EVENT_PREVIEW_CONTENT_MAIN_HIDDEN_SECTIONS.includes(sectionId))
            .map(sectionId => ({
              label: eventPageSectionEnumHelpers.getLabel(sectionId),
              sectionId,
              badgeContent: getBadgeContent(sectionId),
              hidden: eventPageSectionEnumHelpers.getIsHidden(sectionId, event, currentUser),
            }));

          return (
            <Box bgcolor={theme => lighten(theme.palette.background.default, 0.5)} height="auto">
              <StickyStackItem order={0} placement="top">
                <Box zIndex={CALENDAR_FULL_SCREEN_MODAL_ZINDEX + 100} bgcolor={theme => theme.palette.background.paper}>
                  <EventPreviewDrawerHeader
                    event={event}
                    onClose={props.onClose}
                    actions={<EventPreviewHeaderActions variant={variant} setVariant={setVariant} />}
                    tabs={variant === EventPreviewTypeEnum.MAIN ? (
                      <JumpNav
                        initialValue={EventPageSection.OVERVIEW}
                        offsetTop={156}
                        tabs={tabs}
                      />
                    ) : undefined}
                  />
                </Box>
              </StickyStackItem>
              {{
                [EventPreviewTypeEnum.MAIN]: <EventPreviewContentMain />,
                [EventPreviewTypeEnum.SCHEDULING]: <EventPreviewContentScheduling schedulingUserId={props.schedulingUserId} />,
                [EventPreviewTypeEnum.TEAM_SCHEDULING]: <EventPreviewContentTeamScheduling />,
                [EventPreviewTypeEnum.SALES]: <EventPreviewContentSales />,
                [EventPreviewTypeEnum.FEEDBACK]: <EventPreviewContentFeedback />,
              }[variant]}
            </Box>
          );
        }}
      </EventContextProvider>
    </PreviewDrawer>
  );
};

type EventPreviewDrawerHeaderProps = {
  event: GetEventsResponse['data'][number];
  onClose: () => void;
  actions?: React.ReactNode;
  tabs?: React.ReactNode;
  routeTo?: string;
};

const EventPreviewDrawerHeader: React.FC<EventPreviewDrawerHeaderProps> = ({ event, actions, tabs, onClose, routeTo }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" mx={2} py={1} gap={2} alignItems="flex-start">
        <Box sx={{ position: 'absolute', top: 8, right: 16 }}>
          <IconButton onClick={() => navigate(routeTo ?? DYNAMIC_ROUTES.event.createLink({ eventId: event._id }))}>
            <OpenInNew />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <EditableImage
          width="90px"
          height="90px"
          onUpload={()=>{}}
          imageUrl={event.iconUrl}
          modalTitle=""
          disabledEditable
        />
        <Box width="100%">
          <Typography variant="h6" mt={1}>{event.name}&nbsp;<Typography component="span" fontSize="large">{event.year}</Typography></Typography>
          <Typography component="span">
            <WrapWithStatusIndicator status={event.team?.color}>{event.team?.name}
              {event.teamManager && ', '}
              {event.teamManager && <Typography mt={0.3} component="span" variant="body2" color="text.secondary">managed by</Typography>}
              {event.teamManager?.name}
            </WrapWithStatusIndicator>
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            {actions}
          </Box>
        </Box>
      </Box>
      {tabs && (
        <>
          <Box mx={2}><Divider /></Box>
          {tabs}
        </>
      )}
      <Divider />
    </>
  );
};

type EventPreviewHeaderActionsProps = {
  variant: EventPreviewTypeEnum;
  setVariant: (variant: EventPreviewTypeEnum) => void;
};

const EventPreviewHeaderActions: React.FC<EventPreviewHeaderActionsProps> = ({ variant, setVariant }) => {
  const { allowedVariants } = useEventPreviewContext();

  return (
    <ToggleButtonGroup
      value={variant}
      color="primary"
      sx={{
        '& .MuiToggleButtonGroup-grouped': {
          border: 'none', // Remove borders for all buttons
        },
      }}
    >
      {allowedVariants.map(currentVariant => {
        return (
          <ToggleButton
            key={currentVariant}
            value={currentVariant}
            onClick={() => setVariant(currentVariant)}
          >
            {eventPreviewTypeEnumHelpers.getLabel(currentVariant)}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};