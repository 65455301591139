import { Checkbox, FormControlLabel } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GetProductsResponse } from 'api/actions';
import { EditSettingsModal, FilterButton, Table, TableActionsBar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useProductsPageContext } from 'contexts';
import { useTableActionColumn } from 'hooks';
import { useState } from 'react';

const columnsRaw: GridColDef<GetProductsResponse['data'][number]>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'productionQuantity',
    headerName: 'In production',
    width: 180,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (_v, row) => {
      return Object.values(row.stocks).reduce((total, stock) => total + stock.productionQuantity, 0);
    },
  },
];

export const InProduction = () => {
  const { products, loading } = useProductsPageContext();
  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });

  const [ settingsOpen, setSettingsOpen ] = useState(false);
  const [ showWithNoneInProduction, setShowWithNoneInProduction ] = useState<boolean>(false);

  const rows = products.filter((product) => showWithNoneInProduction ? true : Object.values(product.stocks).some((stock) => stock.productionQuantity > 0));
  const columns = withActionColumn(columnsRaw);

  return (
    <>
      <EditSettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        title="In Production Filters"
        otherFilters={(
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setShowWithNoneInProduction((prev) => !prev)}
                checked={showWithNoneInProduction}
              />
            }
            label="Show products with none in production"
          />
        )}
      />
      <TableActionsBar leftActions={(<FilterButton onClick={() => setSettingsOpen(true)} />)} />
      <Table
        columns={columns}
        rows={rows}
        getRowId={(x) => x._id}
        loading={loading}
      />
    </>
  );
};
