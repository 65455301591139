import { GetEventsResponse } from 'api/actions';
import { EventAcceptanceStatusEnum, EventPaymentDue } from 'api/resources';
import { DateObjectType, DateService } from 'services';

export const getEventUnpaidPaymentsDue = (event: GetEventsResponse['data'][number]) => {
  return event.paymentsDue.filter(paymentDue => !paymentDue.isPaidInFull);
};

export const enum EventClosestPaymentDueType {
  DATE = 'date',
  ASAP = 'asap',
  WITH_APPLICATION = 'withApplication',
  UPON_ACCEPTANCE = 'uponAcceptance',
}

export type EventClosestPaymentDue = {
  paymentDue: EventPaymentDue;
  paymentNumber: number;
  dueType: EventClosestPaymentDueType;
  daysUntilDue?: number;
} | null;

export const getEventClosestPaymentDue = (event: GetEventsResponse['data'][number]): EventClosestPaymentDue => {
  const unpaidPaymentsDue = getEventUnpaidPaymentsDue(event);
  const paymentNumber = event.paymentsDue.length - unpaidPaymentsDue.length + 1;

  if (!unpaidPaymentsDue.length) {
    return null;
  }

  const asapDue = unpaidPaymentsDue.find(paymentDue => paymentDue.dueAsSoonAsPossible);
  const withApplicationDue = unpaidPaymentsDue.find(paymentDue => paymentDue.dueWithApplication);
  const uponAcceptanceDue = unpaidPaymentsDue.find(paymentDue => paymentDue.chargedUponAcceptance);

  if (withApplicationDue) {
    return { paymentDue: withApplicationDue, paymentNumber, dueType: EventClosestPaymentDueType.WITH_APPLICATION };
  }
  if (uponAcceptanceDue) {
    return { paymentDue: uponAcceptanceDue, paymentNumber, dueType: EventClosestPaymentDueType.UPON_ACCEPTANCE };
  }
  if (asapDue) {
    return { paymentDue: asapDue, paymentNumber, dueType: EventClosestPaymentDueType.ASAP };
  }

  const closestDue = unpaidPaymentsDue.reduce((r, paymentDue) => {
    if (!paymentDue.dueDateAsUtc) {
      return r;
    }

    const dueDate = DateService.dayjsTz(paymentDue.dueDateAsUtc);

    if (!r.dueDate || dueDate.isBefore(r.dueDate)) {
      return { dueDate, paymentDue };
    }

    return r;
  }, { dueDate: null, paymentDue: null } as { dueDate: DateObjectType | null; paymentDue: GetEventsResponse['data'][number]['paymentsDue'][number] | null });

  if (!closestDue.paymentDue) {
    return null;
  }

  const daysUntilDue = -DateService.dayjs().startOf('day').diff(closestDue.dueDate, 'days');

  return { paymentDue: closestDue.paymentDue, paymentNumber, daysUntilDue, dueType: EventClosestPaymentDueType.DATE };
};

export const getEventOwed = (event: GetEventsResponse['data'][number]) => {
  if (event.acceptanceStatus === EventAcceptanceStatusEnum.accepted) {
    const chargedUponAcceptance = event.paymentsDue.find(paymentDue => !paymentDue.isPaidInFull && paymentDue.chargedUponAcceptance);
    const amountPaid = event.applicationPayments.reduce((r, applicationPayment) => applicationPayment.paymentDue === chargedUponAcceptance?._id ? r + applicationPayment.amount : r, 0);

    return event.cost ? event.cost + (chargedUponAcceptance?.amountDue ?? 0 - amountPaid) - (event.paid ?? 0) : null;
  }

  const paymentDueWithApplication = event.paymentsDue.find(paymentDue => !paymentDue.isPaidInFull && paymentDue.dueWithApplication);
  const amountPaid = event.applicationPayments.reduce((r, applicationPayment) => applicationPayment.paymentDue === paymentDueWithApplication?._id ? r + applicationPayment.amount : r, 0);

  if (paymentDueWithApplication) {
    return (paymentDueWithApplication?.amountDue ?? 0) - amountPaid;
  }

  return null;
};