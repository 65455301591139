import { useLocalStorage } from 'hooks';
import { createContext, useContext, useMemo, useState } from 'react';
import { EventsManagementFilter, filterEvents, transformFilterFromPreset } from './helpers';
import { useEventOrganizers, useEvents, useTeams, useVehicles } from 'queries';
import { getEvent, GetEventOrganizersResponse, GetEventsManagementPresetsResponse, GetEventsResponse, GetTeamsResponse, GetUsersAsResourceResponse, GetVehiclesResponse } from 'api/actions';
import { DateService, filterResourceBySearch } from 'services';
import { useUsersAsResourceOptions } from 'queries/user';
import { useEventsManagementPresets } from 'queries/eventsManagementPresets';
import { EventsTableField } from 'api/resources';
import { EventPreviewContainer } from 'components';
import { QUERY_KEY } from 'queries/query-keys';

export const enum EventsManagementDisplayTypeEnum {
  TABLE = 'table',
  CALENDAR = 'calendar',
  CARDS = 'cards',
}

export type EventsManagementState = {
  presetId: string | null;
  columns: EventsTableField[];
  filter: EventsManagementFilter;

  displayType: EventsManagementDisplayTypeEnum;
  search: string;
};

export type OnSetStateFunc = (newState: Partial<EventsManagementState>) => void;
export type EventsManagementContextType = {
  presets: GetEventsManagementPresetsResponse['data'];
  events: GetEventsResponse['data'];
  eventOrganizers: GetEventOrganizersResponse['data'];
  users: GetUsersAsResourceResponse['data'];
  teams: GetTeamsResponse['data'];
  vehicles: GetVehiclesResponse['data'];
  loading: boolean;

  state: EventsManagementState;
  onSetState: OnSetStateFunc;
};

const initialEventsManagementState: EventsManagementState = {
  presetId: null,
  columns: [],
  filter: {},

  displayType: EventsManagementDisplayTypeEnum.TABLE,
  search: '',
};

export const EventsManagementContext = createContext<EventsManagementContextType>({
  presets: [],
  events: [],
  eventOrganizers: [],
  teams: [],
  vehicles: [],
  users: [],
  loading: false,

  state: initialEventsManagementState,
  onSetState: () => {},
});

export const useEventsManagementContext = () => useContext(EventsManagementContext);

const transformLocalStorageResource = (resource: EventsManagementState) => {
  if (resource.filter.dateRange && typeof resource.filter.dateRange !== 'string') {
    return {
      ...resource,
      filter: {
        ...resource.filter,
        dateRange: {
          start: resource.filter.dateRange.start && DateService.dayjs(resource.filter.dateRange.start),
          end: resource.filter.dateRange.end && DateService.dayjs(resource.filter.dateRange.end),
        },
      },
    };
  }

  return resource;
};

export const EventsManagementContextProvider: React.FC<{children: React.ReactNode }> = props => {
  const { data: presets = [], isInitialLoading: presetsLoading } = useEventsManagementPresets();
  const { data: events = [], isInitialLoading: eventsLoading } = useEvents();
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: vehicles = [], isInitialLoading: vehiclesLoading } = useVehicles();
  const { data: eventOrganizers = [], isInitialLoading: eventOrganizersLoading } = useEventOrganizers();
  const loading = eventsLoading || usersLoading || eventOrganizersLoading || teamsLoading || vehiclesLoading || presetsLoading;

  const localStorage = useLocalStorage<EventsManagementState>('events-management-v2', [ 'filter' ]);

  const [ state, setState ] = useState<EventsManagementState>(transformLocalStorageResource(localStorage.getResource(initialEventsManagementState)));

  const filteredEvents = useMemo(() => {
    const filteredEvents = filterEvents(events, state.filter);

    return filterResourceBySearch(filteredEvents, 'name', state.search);
  }, [ events, state.filter, state.search ]);

  const onSetState: OnSetStateFunc = (inputState) => {
    const newState: Partial<EventsManagementState> = { ...inputState };

    if ('presetId' in inputState) {
      const selectedPreset = presets.find(preset => preset._id === inputState.presetId);

      if (!('filter' in inputState)) {
        if (selectedPreset) {
          newState.filter = transformFilterFromPreset(selectedPreset.filter);
        } else {
          newState.filter = initialEventsManagementState.filter;
        }
      }
      if (!('columns' in inputState)) {
        if (selectedPreset) {
          newState.columns = selectedPreset.columns;
        } else {
          newState.columns = [];
        }
      }
    }

    localStorage.setResource({
      ...state,
      ...newState,
    });

    setState(state => {
      return {
        ...state,
        ...newState,
      };
    });
  };

  return (
    <EventsManagementContext.Provider value={{ presets, events: filteredEvents, teams, vehicles, users, eventOrganizers, loading, state, onSetState }}>
      <EventPreviewContainer
        events={events}
        invalidateQueriesHandler={async (queryClient, eventId) => {
          if (!eventId) {
            return;
          }

          await queryClient.invalidateQueries(QUERY_KEY.EVENT(eventId));

          const event = await queryClient.fetchQuery(QUERY_KEY.EVENT(eventId), async () => {
            const response = await getEvent(eventId);

            return response.data;
          });

          queryClient.setQueryData<GetEventsResponse['data']>(QUERY_KEY.EVENTS, (events) => {
            return events?.map(_event =>
              _event._id === eventId ? event : _event
            );
          });
        }}
      >
        {props.children}
      </EventPreviewContainer>
    </EventsManagementContext.Provider>
  );
};