import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { useEventTeamChangeOptions } from 'queries';
import { EventResourceAutocompleteCell } from './EventResourceAutocomplete.component';
import { GetEventsResponse } from 'api/actions';

export type EventTeamAutocompleteCellProps = GridRenderEditCellParams<GetEventsResponse['data'][number], string | null>;

export const EventTeamAutocompleteCell: React.FC<EventTeamAutocompleteCellProps> = props => {
  const { data: teamChangeOptions = [], isInitialLoading: loading } = useEventTeamChangeOptions(props.row._id);

  return <EventResourceAutocompleteCell {...props} options={teamChangeOptions} loading={loading} />;
};