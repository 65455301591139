import { EnumHelpers, EnumHelpersWithIcon } from 'helpers/enums/types';
import { ColorCodeCell } from '../ColorCode.component';

export type EnumColorCodeCellProps<Enum extends string> = {
  value?: Enum;
  helpers: EnumHelpers<Enum> | EnumHelpersWithIcon<Enum>;
};

export const EnumColorCodeCell = <Enum extends string>({ value, helpers }: EnumColorCodeCellProps<Enum>) => {
  return (
    <ColorCodeCell
      color={helpers.getColor(value)}
      label={helpers.getLabel(value)}
      Icon={'getIcon' in helpers ? helpers.getIcon(value) : undefined}
      align={'getIcon' in helpers ? 'left' : 'center'}
    />
  );
};
