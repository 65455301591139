import { GetUsersAsResourceResponse } from 'api/actions';
import { BaseCell } from '../Base.component';
import { Avatar } from '@mui/material';
import { getUserInitialsForAvatar } from 'services';

export type AvatarBaseCellProps = {
  value?: string | null;
  users: GetUsersAsResourceResponse['data'];
};

export const AvatarBaseCell: React.FC<AvatarBaseCellProps> = ({ value, users }) => {
  const user = users.find(user => user._id === value);

  if(!user) {
    return null;
  }
  return (
    <BaseCell
      startIcon={<Avatar src={user.profileImageUrl} sx={{ width: 25, height: 25 }}>{user.name && getUserInitialsForAvatar(user.name)}</Avatar>}
      value={user.name}
    />
  );
};
