import { Badge } from '@mui/material';
import React, { useMemo } from 'react';

export type WrapWithPresetIndicatorProps = {
  isPreset: boolean;
  isSame: boolean;
  children: React.ReactNode;
};

export const WrapWithPresetIndicator: React.FC<WrapWithPresetIndicatorProps> = ({ isSame, isPreset, children }) => {
  const variant = useMemo(() => {
    if(isPreset) {
      if (isSame) {
        return 'preset';
      } else {
        return 'overridePreset';
      }
    }

    return 'none';
  }, [ isPreset, isSame ]);
  const color = useMemo((): 'primary' | 'secondary' | 'default' => {
    switch (variant) {
      case 'preset':
        return 'primary';
      case 'overridePreset':
        return 'secondary';
      case 'none':
      default:
        return 'default';
    }
  }, [ variant ]);

  return (
    <Badge variant="dot" color={color} sx={{ '& .MuiBadge-badge': { top: '50%' } }}>
      {children}
    </Badge>
  );
};