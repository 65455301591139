import { Box, Divider } from '@mui/material';
import React from 'react';
import { ViewTypeInput } from './ViewTypeInput.component';
import { ColumnsInput } from './ColumnsInput.component';
import { FiltersInput } from './FiltersInput';
import { SavePresetButton } from './SavePresetButton.component';
import { useCurrentUser } from 'contexts';

export const Sidebar = () => {
  const { isAdmin } = useCurrentUser();

  return (
    <Box
      height="100%"
      minWidth={270}
      borderRight={theme => `1px solid ${theme.palette.divider}`}
      display="flex"
      flexDirection="column"
      p={1}
      pb={3}
      gap={1}
    >
      <ViewTypeInput />
      <ColumnsInput />
      <Box my={1}><Divider /></Box>
      <Box flex={1} overflow="auto">
        <FiltersInput />
      </Box>
      {isAdmin && (
        <>
          <Box my={1}><Divider /></Box>
          <SavePresetButton />
        </>
      )}
    </Box>
  );
};
