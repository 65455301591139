import { Typography } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { GetEventResourceChangeOptionsData } from 'api/actions';
import React from 'react';
import { DateService } from 'services';
import { AutocompleteCell } from '../Autocomplete.component';

export type EventResourceAutocompleteCellProps<T extends GetEventResourceChangeOptionsData> = GridRenderEditCellParams & {
  options: T[];
  loading?: boolean;
};

export const EventResourceAutocompleteCell = <T extends GetEventResourceChangeOptionsData>(
  props: EventResourceAutocompleteCellProps<T>
) => {
  return (
    <AutocompleteCell
      {...props}
      autocompleteProps={{
        options: props.options,
        loading: props.loading,
        getOptionLabel: (option) => option.name,
        getOptionDisabled: (option) => !!option.eventDateConflicts?.length,
        renderOption: (option) => {
          const eventDateConflicts = option.eventDateConflicts;

          if (eventDateConflicts) {
            return (
              <div>
                <Typography>{option.name}</Typography>
                {eventDateConflicts.map((eventDate) => (
                  <Typography key={eventDate.eventDateId} variant="body2">
                    <Typography component="span" color="error" variant="inherit">
                        CONFLICT:
                    </Typography>
                    {eventDate.eventName} -{' '}
                    {DateService.getFormattedDate(DateService.dayjsTz(eventDate.eventDate))}
                  </Typography>
                ))}
              </div>
            );
          }

          return <Typography>{option.name}</Typography>;
        },
      }}
    />
  );
};
