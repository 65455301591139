import { DialogTitle, DialogContent, Box, Typography, DialogActions, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEventsManagementPreset } from 'api/actions';
import { Modal, TextInput, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import { transformFilterFromPreset, transformFilterToPreset } from 'pages/EventsManagement2/helpers';
import { useEventsManagementContext } from 'pages/EventsManagement2/utils';
import { QUERY_KEY } from 'queries/query-keys';
import React from 'react';
import { object, string } from 'yup';

export type CreatePresetModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

export const CreatePresetModal: React.FC<CreatePresetModalProps> = ({ onClose, onSuccess }) => {
  const { onSetState, state: { filter, columns } } = useEventsManagementContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();

  const createPresetMutation = useMutation({
    mutationFn: async (name: string) => {
      return await createEventsManagementPreset({
        name,
        columns,
        filter: transformFilterToPreset(filter),
      });
    },
    onSuccess: async ({ data: newPreset }) => {
      onSetState({
        presetId: newPreset._id,
        columns: newPreset.columns,
        filter: transformFilterFromPreset(newPreset.filter)
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENTS_MANAGEMENT_PRESETS });
      snackbar.success('New preset created successfully');

      onSuccess();
      onClose();
    },
    onError: () => {
      snackbar.error('Failed to create new preset');
    }
  });

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={() => object({ name: string().required('Preset name is required') })}
      onSubmit={({ name }) => createPresetMutation.mutate(name)}
    >
      {formik => {
        return (
          <Modal open onClose={() => onClose()} topLeftIcon="close">
            <DialogTitle>Create new preset</DialogTitle>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column" alignItems="flex-start" gap={3}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography>1. Choose a name for the preset</Typography>
                  <TextInput
                    name="name"
                    placeholder="Preset name..."
                    size="small"
                    sx={{ minWidth: 300 }}
                  />

                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography>2. Assign Permissions for the preset</Typography>
                  <Typography ml={2} variant="body2">Permissions coming soon...</Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()} fullWidth>Cancel</Button>
              <Button type="submit" disabled={!formik.isValid} onClick={() => formik.handleSubmit()} variant="contained" fullWidth>Save</Button>
            </DialogActions>
          </Modal>
        );
      }}
    </Formik>
  );
};
