import { Box, Rating } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';
import { DateService, currencyFormatter } from 'services';
import { ThemePalette, UnknownEnum } from 'types';
import {
  eventEnumHelpers,
  getApplicationDateRangeLabel,
  getEventStaffStatus,
  getEventDatesStatus,
  createEnumComparer,
  getSelectOptionsFromEnumHelper,
  getUntilApplicationDeadlineColorCodeConfig,
  getUntilApplicationOpenColorCodeConfig_old,
  getApplicationHasBeenOpenColorCodeConfig_old,
  getEventOwed,
  getEventUnpaidPaymentsDue,
  getUntilApplicationOpenValue,
  getApplicationHasBeenOpenValue_old,
  getUntilApplicationDeadlineValue
} from 'helpers';

import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import { getMatrixDisplayText } from 'components/PlaceDistanceAndDurationContent';
import { StaffList } from 'components/StaffList';
import {
  StandardDateCell,
  TeamCell,
  ColorCodeCell,
  ParticipationStatusCell,
  ApplicationStatusCell,
  AcceptanceStatusCell,
  ApplicationPlatformCell,
  DatesStatusCell,
  InsuranceStatusCell,
  StaffStatusCell,
  InsuranceSharedCell,
  OwedCell_old
} from '../Cells';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

export const enum EventsTableFieldsOld {
  NAME = 'name',
  YEAR = 'year',
  DAYS_UNTIL_EVENT = 'daysUntilEvent',
  CREATED_AT = 'createdAt',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EVENT_TIMEFRAME = 'eventTimeframe',
  DAYS_COUNT = 'daysCount',
  FILES_COUNT = 'filesCount',
  JURY_FEE = 'juryFee',
  JURY_FEE_AMOUNT = 'juryFeeAmount',
  JURY_FEE_PAID = 'juryFeePaid',
  JURY_FEE_PAYMENT_TYPE = 'juryFeePaymentType',
  COST = 'cost',
  PAID = 'paid',
  OWED = 'owed',
  SALES_REVENUE = 'salesRevenue',
  SALES_COUNT = 'salesCount',
  TEAM_NAME = 'teamName',
  EVENT_ORGANIZER_NAME = 'eventOrganizerName',
  EVENT_MANAGER_NAME = 'eventManagerName',
  LOCATION = 'location',
  VEHICLE_NAME = 'vehicleName',
  ATTENDANCE = 'attendance',
  VENDORS = 'vendors',
  SPACE_SIZE = 'spaceSize',
  NOTES = 'notes',
  APPLICATION_OPEN_DATE = 'applicationOpenDate',
  APPLICATION_HAS_BEEN_OPEN = 'applicationHasBeenOpen',
  UNTIL_APPLICATION_OPEN = 'untilApplicationOpen',
  APPLICATION_DEADLINE_DATE = 'applicationDeadlineDate',
  UNTIL_APPLICATION_DEADLINE = 'untilApplicationDeadline',
  APPLICATION_OPEN_TIMEFRAME = 'applicationOpenTimeframe',
  UNTIL_PAYMENT_DUE = 'untilPaymentDue',
  NEXT_PAYMENT_DUE = 'nextPaymentDue',
  PAYMENT_NUMBER = 'paymentNumber',
  PARTICIPATION_STATUS = 'participationStatus',
  APPLICATION_STATUS = 'applicationStatus',
  ACCEPTANCE_STATUS = 'acceptanceStatus',
  STAFF_STATUS = 'staffStatus',
  STAFF_LIST = 'staffList',
  DATES_STATUS = 'datesStatus',
  APPLICATION_PLATFORM = 'applicationPlatform',
  PAYMENT_NOTE = 'paymentNote',
  LODGING_REQUIRED = 'lodgingRequired',
  INSURANCE_REQUIRED = 'insuranceRequired',
  INSURANCE_STATUS = 'insuranceStatus',
  INSURANCE_SHARED = 'insuranceShared',
  STUDIO_TO_EVENT = 'studioToEvent',
  AVERAGE_OVERALL_RATING = 'averageOverallRating',
  CREATED_BY = 'createdBy',
}

export const eventSalesColumns: GridColDef[] = [
  {
    field: EventsTableFieldsOld.SALES_REVENUE,
    headerName: 'Sales Revenue',
    width: 130,
    valueFormatter: (value) => currencyFormatter.format(Number(value))
  },
  {
    field: EventsTableFieldsOld.SALES_COUNT,
    headerName: 'Sales #',
    width: 80,
  },
];

export const eventStaffListField: GridColDef<{ dates: Pick<GetEventsResponse['data'][number]['dates'][number], 'staff'>[] }> = {
  field: EventsTableFieldsOld.STAFF_LIST,
  headerName: 'Staff List',
  width: 200,
  valueGetter: (_v, row) => {
    const staffList = row.dates.reduce((r: GetEventsResponse['data'][number]['dates'][number]['staff'], date) => [
      ...r,
      ...date.staff.filter((staff) => !r.find((element) => element.user._id === staff.user._id))
    ], []);

    return staffList;
  },
  renderCell: ({ value }) => <StaffList staff={value} />
};

export const eventTimeframeField: GridColDef<Pick<GetEventsResponse['data'][number], 'startDate' | 'endDate'>> = {
  field: EventsTableFieldsOld.EVENT_TIMEFRAME,
  headerName: 'Event Daterange',
  width: 340,
  valueGetter: (_v, row) => [ row.startDate?.dateAsUtc, row.endDate?.dateAsUtc ],
  valueFormatter: (value) => DateService.getFormattedDateRange(value[0] ? DateService.dayjsTz(value[0]) : value[0], value[1] ? DateService.dayjsTz(value[1]) : value[1]),
  sortComparator: DateService.dateRangeComparer_old,
};

const numberOrNullOrUndefinedComparator = (a?: number | null, b?: number | null) => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }

  return a - b;
};

export const eventsTableColumns_old: GridColDef<GetEventsResponse['data'][number]>[] = [
  {
    field: EventsTableFieldsOld.NAME,
    headerName: 'Name',
    width: 300,
  },
  {
    field: EventsTableFieldsOld.YEAR,
    headerName: 'Year',
    width: 70,
  },
  {
    field: EventsTableFieldsOld.DAYS_UNTIL_EVENT,
    headerName: 'Days until Event',
    valueGetter: (_v, row) => {
      if (!row.startDate) {
        return undefined;
      }

      return -DateService.dayjs().startOf('day').diff(row.startDate.dateAsUtc, 'days');
    },
    renderCell: ({ value, row }) => {
      let label = '';
      let color: ThemePalette = 'unknown';
      const isPast = row.endDate && -DateService.dayjs().startOf('day').diff(row.endDate.dateAsUtc, 'days') < 0;

      if (value === undefined) {
        label = 'Unknown';
      } else if (value <= 0 || value === null) {
        if(isPast) {
          label = 'Past';
        } else {
          label = 'Ongoing';
        }
      } else {
        label = `${value} day${value === 1 ? '' : 's'}`;
      }

      if (value === undefined || isPast) {
        color = 'unknown';
      } else if (value === null || value <= 15) {
        color = 'error';
      } else if (value <= 30) {
        color = 'warning';
      } else {
        color = 'success';
      }

      return <ColorCodeCell color={color} label={label} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: (a, b) => {
      if(a === undefined && b === undefined) {
        return 0;
      }
      if(a === undefined) {
        return 1;
      }
      if(b === undefined) {
        return -1;
      }

      return a - b;
    },
  },
  {
    field: EventsTableFieldsOld.CREATED_AT,
    headerName: 'Created At',
    valueFormatter: (value) => DateService.dayjs(value, 'MM/DD/YYYY'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  {
    field: EventsTableFieldsOld.START_DATE,
    headerName: 'Start Date',
    valueGetter: (_v, row) => row.startDate?.dateAsUtc,
    valueFormatter: (value) => DateService.getFormattedDate(DateService.dayjsTz(value), 'standardTableCell'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  {
    field: EventsTableFieldsOld.END_DATE,
    headerName: 'End Date',
    valueGetter: (_v, row) => row.endDate?.dateAsUtc,
    valueFormatter: (value) => DateService.getFormattedDate(DateService.dayjsTz(value), 'standardTableCell'),
    width: 150,
    type: 'date',
    renderCell: StandardDateCell,
  },
  eventTimeframeField,
  {
    field: EventsTableFieldsOld.DAYS_COUNT,
    headerName: '# of Days',
    valueGetter: (_v, row) => row.dates.length,
    width: 80,
  },
  {
    field: EventsTableFieldsOld.FILES_COUNT,
    headerName: 'Files #',
    valueGetter: (_v, row) => row.files?.length ?? 0,
    width: 70,
  },
  {
    field: EventsTableFieldsOld.JURY_FEE,
    headerName: 'Jury Fee',
    width: 70,
    valueGetter: (_v, row) => row.juryFee,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    )
  },
  {
    field: EventsTableFieldsOld.JURY_FEE_AMOUNT,
    headerName: 'Jury Fee $',
    valueGetter: (_v, row) => row.juryFeeAmount ? currencyFormatter.format(row.juryFeeAmount) : undefined,
    width: 100,
  },
  {
    field: EventsTableFieldsOld.JURY_FEE_PAID,
    headerName: 'Is Jury fee paid?',
    width: 130,
    valueGetter: (_v, row) => row.juryFeePaid,
    renderCell: ({ value }) => (
      <Box width="100%" display="flex" justifyContent="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    )
  },
  {
    field: EventsTableFieldsOld.JURY_FEE_PAYMENT_TYPE,
    headerName: 'Jury fee payment',
    width: 150,
    valueGetter: (_v, row) => row.juryFeePaymentType,
    valueFormatter: (value) => eventEnumHelpers.paymentType.getLabel(value)
  },
  {
    field: EventsTableFieldsOld.COST,
    headerName: 'Cost',
    valueGetter: (_v, row) => row.cost ? currencyFormatter.format(row.cost) : null,
    width: 100,
  },
  {
    field: EventsTableFieldsOld.PAID,
    headerName: 'Paid',
    valueGetter: (_v, row) => currencyFormatter.format(row.paid ?? 0),
    width: 100,
  },
  {
    field: EventsTableFieldsOld.OWED,
    headerName: 'Owed',
    width: 120,
    valueGetter: (_v, row) => getEventOwed(row),
    renderCell: OwedCell_old,
  },
  ...eventSalesColumns,
  {
    field: EventsTableFieldsOld.TEAM_NAME,
    headerName: 'Team',
    width: 130,
    valueGetter: (_v, row) => row.team?.name,
    renderCell: ({ row }) => <TeamCell team={row.team ?? null} />,
  },
  {
    field: EventsTableFieldsOld.EVENT_ORGANIZER_NAME,
    headerName: 'Event Organizer',
    valueGetter: (_v, row) => row.eventOrganizer?.name,
    width: 200,
  },
  {
    field: EventsTableFieldsOld.EVENT_MANAGER_NAME,
    headerName: 'Event Manager',
    valueGetter: (_v, row) => row.eventManager?.name,
    width: 150,
  },
  {
    field: EventsTableFieldsOld.LOCATION,
    headerName: 'Location',
    valueGetter: (_v, row) => row.place.address,
    width: 300,
  },
  {
    field: EventsTableFieldsOld.VEHICLE_NAME,
    headerName: 'Vehicle',
    valueGetter: (_v, row) => row.vehicle?.name,
    width: 120,
  },
  {
    field: EventsTableFieldsOld.ATTENDANCE,
    headerName: 'Attendance',
    width: 100,
  },
  {
    field: EventsTableFieldsOld.VENDORS,
    headerName: '# of Vendors',
    width: 100,
  },
  {
    field: EventsTableFieldsOld.SPACE_SIZE,
    headerName: 'Space Size',
    width: 150,
  },
  {
    field: EventsTableFieldsOld.NOTES,
    headerName: 'Notes',
    width: 200,
  },
  {
    field: EventsTableFieldsOld.APPLICATION_OPEN_DATE,
    headerName: 'Application Open',
    valueGetter: (_v, row) => row.applicationIsOpen ? null : row.applicationOpenDate,
    valueFormatter: (value) => {
      if(value === null) {
        return 'Is open';
      }
      if(value === undefined) {
        return 'Unknown';
      }

      return DateService.dayjs(value).format('MM/DD/YY(ddd)');
    },
    width: 150,
    sortComparator: DateService.dateComparator(true),
  },
  {
    field: EventsTableFieldsOld.APPLICATION_HAS_BEEN_OPEN,
    headerName: 'Application has been open',
    valueGetter: (_v, row) => getApplicationHasBeenOpenValue_old(row.applicationOpenDate, row.applicationIsOpen),
    renderCell: ({ row }) => {
      const colorCodeConfig = getApplicationHasBeenOpenColorCodeConfig_old(row.applicationOpenDate, row.applicationIsOpen);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 200,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: EventsTableFieldsOld.UNTIL_APPLICATION_OPEN,
    headerName: 'Days until Open',
    valueGetter: (_v, row) => getUntilApplicationOpenValue(row.applicationOpenDate, row.applicationIsOpen),
    renderCell: ({ row }) => {
      const colorCodeConfig = getUntilApplicationOpenColorCodeConfig_old(row.applicationOpenDate, row.applicationIsOpen);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: EventsTableFieldsOld.APPLICATION_DEADLINE_DATE,
    headerName: 'Application Deadline',
    valueGetter: (_v, row) => row.applicationOpenUntilFull ? null : row.applicationDeadlineDate,
    valueFormatter: (value) => {
      if(value === null) {
        return 'Is open until full';
      }
      if(value === undefined) {
        return 'Unknown';
      }
      return DateService.dayjs(value).format('MM/DD/YYYY(ddd)');
    },
    width: 150,
    sortComparator: DateService.dateComparator(true),
  },
  {
    field: EventsTableFieldsOld.UNTIL_APPLICATION_DEADLINE,
    headerName: 'Days until Deadline',
    valueGetter: (_v, row) => getUntilApplicationDeadlineValue(row.applicationDeadlineDate, row.applicationOpenUntilFull),
    renderCell: ({ row }) => {
      const colorCodeConfig = getUntilApplicationDeadlineColorCodeConfig(row.applicationDeadlineDate, row.applicationOpenUntilFull);

      return <ColorCodeCell {...colorCodeConfig} />;
    },
    width: 170,
    cellClassName: 'padding-0',
    sortComparator: numberOrNullOrUndefinedComparator,
  },
  {
    field: EventsTableFieldsOld.APPLICATION_OPEN_TIMEFRAME,
    valueGetter: (_v, row) => getApplicationDateRangeLabel(
      row.applicationOpenDate,
      row.applicationIsOpen,
      row.applicationDeadlineDate,
      row.applicationOpenUntilFull,
    ),
    headerName: 'App. Open Timeframe',
    width: 300,
  },
  {
    field: EventsTableFieldsOld.UNTIL_PAYMENT_DUE,
    headerName: 'Days until payment due',
    valueGetter: (_v, row) => {
      // const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);

      // if (!row.cost || !unpaidPaymentsDue.length || row.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
      //   return Infinity;
      // }

      // if (unpaidPaymentsDue.find(paymentDue => paymentDue.dueWithApplication || paymentDue.dueAsSoonAsPossible || paymentDue.chargedUponAcceptance)) {
      //   return -1;
      // }

      // const { daysUntilDue } = getEventClosestPaymentDue(row);

      // return daysUntilDue;
    },
    renderCell: ({ row }) => {
      return null;
      // const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);
      // const { daysUntilDue, dueType, paymentDue } = getEventClosestPaymentDue(row);

      // if (!row.cost) {
      //   return <ColorCodeCell color="error" label="Cost unassigned" />;
      // }

      // if (dueType === null) {
      //   return <ColorCodeCell color="success" label="Paid in full!" />;
      // }

      // if (dueType === 'withApplication') {
      //   return <ColorCodeCell color="warning" label="Due with application" />;
      // }

      // if (dueType === 'chargedUponAcceptance') {
      //   return <ColorCodeCell color="warning" label="Charged upon acceptance" />;
      // }

      // if (row.acceptanceStatus !== EventAcceptanceStatusEnum.accepted) {
      //   return <ColorCodeCell color="unknown" label="No payments due until accepted" secondaryBottom={dueType ? `Then ${dueType === 'asap' ? 'ASAP' : `on ${DateService.getFormattedDate(paymentDue?.dueDateAsUtc ?? '')}`}` : undefined} />;
      // }

      // if (unpaidPaymentsDue.find(paymentDue => paymentDue.dueAsSoonAsPossible)) {
      //   return <ColorCodeCell color="error" label="Due ASAP" />;
      // }

      // let solid = false;
      // let label = '';
      // let color: ThemePalette = 'unknown';

      // if(daysUntilDue === undefined) {
      //   label = 'Unknown';
      // } else if(daysUntilDue < 0) {
      //   label = `Passed ${-daysUntilDue} days ago`;
      // } else if(daysUntilDue === 0) {
      //   label = 'Due today';
      // }else {
      //   label = `${daysUntilDue} day${daysUntilDue === 1 ? '' : 's'}`;
      // }

      // if (daysUntilDue <= 14) {
      //   color = 'error';
      //   solid = true;
      // } else if (daysUntilDue > 14) {
      //   color = 'warning';
      // }

      // return <ColorCodeCell color={color} label={label} solid={solid} secondaryBottom={DateService.getFormattedDate(DateService.dayjsTz(paymentDue?.dueDateAsUtc))} />;
    },
    width: 210,
    cellClassName: 'padding-0'
  },
  {
    field: EventsTableFieldsOld.NEXT_PAYMENT_DUE,
    headerName: 'Next Payment Due',
    valueGetter: (_v, row) =>  {
      // const { paymentDue } = getEventClosestPaymentDue(row);
      // const paid = row.applicationPayments
      //   .filter(applicationPayment => applicationPayment.paymentDue === paymentDue?._id)
      //   .reduce((r, applicationPayment) => r + applicationPayment.amount, 0);

      // return Math.max((paymentDue?.amountDue ?? 0) - paid, 0);
      return null;
    },
    valueFormatter: (value) => value ? currencyFormatter.format(value) : 'N/A',
    width: 140,
  },
  {
    field: EventsTableFieldsOld.PAYMENT_NUMBER,
    headerName: 'Payment #',
    valueGetter: (_v, row) =>  {
      const unpaidPaymentsDue = getEventUnpaidPaymentsDue(row);

      return `${row.paymentsDue.length - unpaidPaymentsDue.length + 1} of ${row.paymentsDue.length}`;
    },
    width: 90,
    align: 'center',

  },
  {
    field: EventsTableFieldsOld.PARTICIPATION_STATUS,
    headerName: 'Participation Status',
    valueGetter: (_v, row) => row.participationStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.participationStatus.enumValues),
    width: 150,
    renderCell: ParticipationStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.participationStatus),
  },
  {
    field: EventsTableFieldsOld.APPLICATION_STATUS,
    headerName: 'Application Status',
    valueGetter: (_v, row) => row.applicationStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.applicationStatus.enumValues),
    width: 140,
    renderCell: ApplicationStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationStatus),
  },
  {
    field: EventsTableFieldsOld.ACCEPTANCE_STATUS,
    headerName: 'Acceptance Status',
    valueGetter: (_v, row) => row.acceptanceStatus ?? UnknownEnum.unknown,
    sortComparator: createEnumComparer(eventEnumHelpers.acceptanceStatus.enumValues),
    width: 150,
    renderCell: AcceptanceStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.acceptanceStatus),
  },
  {
    field: EventsTableFieldsOld.STAFF_STATUS,
    headerName: 'Staff Status',
    valueGetter: (_v, row) => getEventStaffStatus(row.dates),
    width: 100,
    renderCell: StaffStatusCell,
  },
  eventStaffListField,
  {
    field: EventsTableFieldsOld.DATES_STATUS,
    headerName: 'Dates Status',
    valueGetter: (_v, row) => getEventDatesStatus(row.dates),
    width: 100,
    renderCell: DatesStatusCell,
  },
  {
    field: EventsTableFieldsOld.APPLICATION_PLATFORM,
    headerName: 'Application Platform',
    valueGetter: (_v, row) => row.applicationPlatform ?? UnknownEnum.unknown,
    width: 160,
    renderCell: ApplicationPlatformCell,
  },
  {
    field: EventsTableFieldsOld.PAYMENT_NOTE,
    headerName: 'Payment Note',
    valueGetter: (_v, row) => row.paymentNote,
    width: 200,
  },
  {
    field: EventsTableFieldsOld.LODGING_REQUIRED,
    headerName: 'Lodging Required',
    width: 130,
    valueGetter: (_v, row) => row.lodging?.isRequired,
    renderCell: ({ value }) => (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    ),
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
  },
  {
    field: EventsTableFieldsOld.INSURANCE_REQUIRED,
    headerName: 'Insurance Required',
    width: 150,
    valueGetter: (_v, row) => row.insurance?.isRequired,
    renderCell: ({ value }) => (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <YesNoUnknownIconSwitch value={value} />
      </Box>
    ),
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(yesNoEnumHelpers.yesNo),
  },
  {
    field: EventsTableFieldsOld.INSURANCE_STATUS,
    headerName: 'Insurance Status',
    valueGetter: (_v, row) => row.insurance?.status ?? UnknownEnum.unknown,
    width: 200,
    renderCell: InsuranceStatusCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceStatus),
  },
  {
    field: EventsTableFieldsOld.INSURANCE_SHARED,
    headerName: 'Insurance Shared',
    valueGetter: (_v, row) => row.insurance?.shared ?? UnknownEnum.unknown,
    width: 200,
    renderCell: InsuranceSharedCell,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.insuranceShared),
  },
  {
    field: EventsTableFieldsOld.STUDIO_TO_EVENT,
    headerName: 'Studio → Event',
    width: 220,
    valueGetter: (_v, row) => row.place.fromStudioMatrix,
    valueFormatter: (value) => getMatrixDisplayText(value),
  },
  {
    field: EventsTableFieldsOld.AVERAGE_OVERALL_RATING,
    headerName: 'Average Rating',
    width: 140,
    renderCell: ({ value }) => {
      return <Rating value={value} readOnly />;
    }
  },
  {
    field: EventsTableFieldsOld.CREATED_BY,
    headerName: 'Created By',
    valueGetter: (_v, row) => row.createdBy?.name,
    width: 140,
  }
];
