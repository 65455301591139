import { Box, Checkbox, Divider, FormControlLabel, FormHelperText, Popover, TextField } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DateObjectType, DateService } from 'services';

export type DatePickerWithUnknownProps<Value extends unknown> = GridRenderEditCellParams & {
  unknownOptionLabel?: string;
  unknownOptionHelperText?: string;
  unknownOptionFieldName: Extract<keyof Value, string>;
  dateFieldName: Extract<keyof Value, string>;
};

export const DatePickerWithUnknown = <Value extends unknown>(props: DatePickerWithUnknownProps<Value>) => {
  const { id, value, field, api } = props;
  const unknownOption = value[props.unknownOptionFieldName];
  const date = value[props.dateFieldName];

  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [ anchorEl, setAnchorEl ] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setAnchorEl(textFieldRef.current);
  }, []);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'date-picker-with-unknown-cell-popover': undefined;

  const textFieldValue = useMemo(() => {
    if(unknownOption) {
      return props.unknownOptionLabel ?? 'Unknown';
    }

    return date ? DateService.getFormattedDate(date, 'ddd, MMM Do, YYYY'): '';
  }, [ date, props.unknownOptionLabel, unknownOption ]);

  const onDateChange = (date: DateObjectType | null) => {
    api.setEditCellValue({ id, field, value: { ...value, [props.dateFieldName]: date?.isValid() ? date.toISOString() : null } });
  };

  const onUnknownOptionToggle = () => api.setEditCellValue({ id, field, value: { ...value, [props.unknownOptionFieldName]: !unknownOption } });

  return (
    <>
      <TextField fullWidth ref={textFieldRef} value={textFieldValue} InputProps={{ readOnly: true }} />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => api.stopCellEditMode({ id, field })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box mb={2} maxWidth={310}>
          <StaticDatePicker
            value={(!unknownOption && date) ? DateService.dayjs(date) : null}
            onChange={onDateChange}
            minDate={unknownOption ? DateService.dayjs() : undefined}
            maxDate={unknownOption ? DateService.dayjs() : undefined}
            slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true },  }}
            disabled={unknownOption}

          // slotProps={{
          //   textField: {
          //     error: !!meta.error,
          //     helperText: meta.error,
          //   },
          // }}
          />
          <Divider />
          <Box mx={4}>
            <FormControlLabel
              componentsProps={{ typography: { variant: 'body2', sx: { userSelect: 'none' } } }}
              label={props.unknownOptionLabel ?? 'Unknown'}
              control={<Checkbox onChange={onUnknownOptionToggle} checked={unknownOption} size="small" />}
            />
            {props.unknownOptionHelperText && <FormHelperText>{props.unknownOptionHelperText}</FormHelperText>}
          </Box>
        </Box>
      </Popover>
    </>
  );
};