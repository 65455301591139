import { GridCellParams } from '@mui/x-data-grid';
import { YesNoChip } from 'components/Chips/YesNoChip.component';
import { YesNoEnum } from 'types';

export type YesNoCellType = GridCellParams<any, YesNoEnum>;

export const YesNoCell2: React.FC<YesNoCellType> = ({ value }) => {
  if (!value) return null;

  return <YesNoChip size="small" value={value} />;
};
