import { Box } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { eventsTableFieldHelper } from './helpers';
import { EventsTableFieldHelperResouces } from './types';
import { EventsTableField } from 'api/resources';

export type EventsTableFieldPreviewProps<T extends EventsTableField> = {
  resources: EventsTableFieldHelperResouces;
  event: GetEventsResponse['data'][number];
  column: T;
};

export const EventsTableFieldPreview = <T extends EventsTableField>({ resources, event, column }: EventsTableFieldPreviewProps<T>) => {
  const value = eventsTableFieldHelper[column].valueGetter(event);

  return (
    <Box border={theme => `1px solid ${theme.palette.divider}`} height="45px" width="100%" overflow="hidden">
      {eventsTableFieldHelper[column].renderCell(resources)({ value, row: event })}
    </Box>
  );
};