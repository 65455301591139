import { getUntilApplicationDeadlineValue } from 'helpers';
import { ThemePalette } from 'types';
import { ColorCodeCell } from '../ColorCode.component';
import { DateService } from 'services';
import { useMemo } from 'react';
import { EventsTableFieldValues } from 'components/Table/custom/EventsTable/types';
import { EventsTableField } from 'api/resources';

export type EventApplicationDeadlineDateColorCodeCellProps = {
  value: EventsTableFieldValues[EventsTableField.APPLICATION_DEADLINE];
};

export const EventApplicationDeadlineDateColorCodeCell: React.FC<EventApplicationDeadlineDateColorCodeCellProps> = ({ value: inputValue }) => {
  const { applicationDeadlineDate, applicationOpenUntilFull } = inputValue ?? {};

  const untilApplicationDeadline = getUntilApplicationDeadlineValue(applicationDeadlineDate, applicationOpenUntilFull);

  // Get label
  let label = '';

  // untilApplicationDeadline === undefined means that the application deadline date is unknown
  // and applicationOpenUntilFull is falsy meaning that there's no data for the application deadline date
  if(untilApplicationDeadline === undefined) {
    label = 'Unknown';
  // untilApplicationDeadline === null means that the application deadline date is unknown and applicationOpenUntilFull is true
  } else if(untilApplicationDeadline === null) {
    label = 'Open Until Full';
  // untilApplicationDeadline < 0 means that the application deadline date has passed
  } else if(untilApplicationDeadline < 0) {
    label = 'Already closed';
  // untilApplicationDeadline < 0 means that the application deadline is today
  } else if (untilApplicationDeadline === 0) {
    label = 'Due today';
  } else {
    label = `Due in ${untilApplicationDeadline} day${untilApplicationDeadline === 1 ? '' : 's'}`;
  }

  // Get color
  let solid = false;
  let color: ThemePalette = 'unknown';

  // untilApplicationDeadline === undefined means that there's no data for the application deadline date
  if (untilApplicationDeadline === undefined) {
    color = 'unknown';
  // untilApplicationDeadline === null means that the application is open until full
  } else if(untilApplicationDeadline === null) {
    color = 'error';
  // untilApplicationDeadline <= 14 means that the application deadline is within the next 14 days
  } else if (untilApplicationDeadline <= 14) {
    color = 'error';
    solid = true;
  } else {
    color = 'warning';
  }

  const caption = useMemo(() => {
    if ((!applicationDeadlineDate)) {
      return undefined;
    }

    return applicationOpenUntilFull ? 'Open until full' : `Due date: ${DateService.dayjs(applicationDeadlineDate).format('ddd MMM D, YYYY')}`;
  }, [ applicationDeadlineDate, applicationOpenUntilFull ]);

  return <ColorCodeCell color={color} label={label} caption={caption} solid={solid} />;
};