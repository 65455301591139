import { GridColDef } from '@mui/x-data-grid';
import { GetOrdersResponse } from 'api/actions';
import { LoggedAtDateCell, OrderFulfillmentStatusChip } from 'components';
import { EnumHelpers } from 'helpers/enums/types';

export const enum OrdersTableTypeEnum {
  all = 'all',
  not_fulfilled = 'not_fulfilled',
}

export const ordersTableTypeEnumHelper: EnumHelpers<OrdersTableTypeEnum> = {
  enumValues: [
    OrdersTableTypeEnum.all,
    OrdersTableTypeEnum.not_fulfilled,
  ],
  getLabel: (value) => {
    switch (value) {
      case OrdersTableTypeEnum.not_fulfilled:
        return 'Not fulfilled';
      default:
      case OrdersTableTypeEnum.all:
        return 'All';
    }
  },
  getColor: () => 'unknown',
};

// Columns

export const ordersTableColumns: GridColDef<GetOrdersResponse['data']['orders'][number]>[] = [
  {
    field: 'name',
    headerName: 'Order #',
    width: 220,
  },
  {
    field: 'createdAt',
    headerName: 'Logged At',
    valueGetter: (_v, row) => {
      return new Date(row.createdAt);
    },
    type: 'date',
    renderCell: LoggedAtDateCell,
    width: 200,
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    valueGetter: (_v, row) => row.customer ? `${row.customer.firstName} ${row.customer.lastName}` : null,
    width: 130,
  },
  {
    field: 'numberOfSale',
    headerName: '# of pcs',
    valueGetter: (_v, row) => row.sales.length,
    width: 70,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'fulfillmentStatus',
    headerName: 'Fulfillment Status',
    renderCell: ({ row }) => <OrderFulfillmentStatusChip value={row.fulfillmentStatus} />,
    width: 150,
  },
];
