import { ThemePalette } from 'types';
import { ColorCodeCell } from '../ColorCode.component';
import { EventsTableFieldValues } from 'components/Table/custom/EventsTable/types';
import { EventsTableField } from 'api/resources';

export type EventProgressionColorCodeCellProps = {
  value?: EventsTableFieldValues[EventsTableField.EVENT_PROGRESSION];
};
export const EventProgressionColorCodeCell: React.FC<EventProgressionColorCodeCellProps> = ({ value }) => {
  let label = '';
  let color: ThemePalette = 'unknown';
  let solid: boolean = false;

  if (!value) {
    label = 'Unknown';
  } else if (value.daysUntilStart <= 0) {
    if(value.daysUntilEnd <= 0) {
      label = 'Past';
    } else {
      label = 'Ongoing';
    }
  } else {
    label = `Starts in ${value.daysUntilStart} day${value.daysUntilStart === 1 ? '' : 's'}`;
  }

  if (!value) {
    color = 'unknown';
  } else if (value.daysUntilStart <= 0) {
    if (value.daysUntilEnd <= 0) {
      color = 'unknown';
    } else {
      color = 'success';
      solid = true;
    }

  } else if (value.daysUntilStart <= 15) {
    color = 'error';
  } else if (value.daysUntilStart <= 30) {
    color = 'warning';
  } else {
    color = 'success';
  }

  return <ColorCodeCell color={color} label={label} solid={solid} />;
};