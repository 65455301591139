import { EventsTable } from 'components/Table/custom/EventsTable/EventsTable.component';
import { useEventsManagementContext } from '../utils';
import { EventPreviewTypeEnum, useEventPreviewContext } from 'components';
import { useCallback } from 'react';
import { GridRowClassNameParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';

export const EventsManagementTable: React.FC<{ height?: number }> = (props) => {
  const { events, users, eventOrganizers, loading, state: { columns }, onSetState } = useEventsManagementContext();
  const { setOpenEvent, openEventId } = useEventPreviewContext();

  const getRowClassName = useCallback(({ row }: GridRowClassNameParams<GetEventsResponse['data'][number]>) => {
    if (row._id === openEventId) {
      return 'selected-darker';
    }

    return '';
  }, [ openEventId ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <EventsTable
      events={events}
      users={users}
      eventOrganizers={eventOrganizers}
      loading={loading}
      columns={columns}
      height={props.height}
      onOpenEvent={(id) => setOpenEvent(id, EventPreviewTypeEnum.MAIN)}
      getRowClassName={getRowClassName}
      onColumnOrderChange={(api) => {
        onSetState({
          columns: columns.sort((a, b) => {
            return api.getColumnIndexRelativeToVisibleColumns(a) - api.getColumnIndexRelativeToVisibleColumns(b);
          })
        });
      }}
    />
  );
};