import { ArrowRightAltOutlined, LocalShippingOutlined } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { PlaceDefaultEnum, PlaceMatrix } from 'api/resources';
import { getDistanceInMiles } from 'helpers';
import React from 'react';
import { DateService } from 'services';

const STUDIO_ADDRESS = '310 E High St, Pottstown, PA 19464';

export type PlaceDistanceAndDurationContentProps = {
  fromLabel: string;
  toLabel: string;
  placeMatrix: PlaceMatrix | undefined;
  displayMissingMatrixMessage?: string;
  routeDetails?: {
    fromAddress?: PlaceDefaultEnum | string;
    toAddress?: PlaceDefaultEnum | string;
  };
};

const getFormattedDuration = (duration: number) => {
  if (duration === 0) {
    return 'Less than 1 minute';
  }

  const durationObject = DateService.dayjs.duration(duration, 'seconds');

  const days = { unit: 'day', value: durationObject.days() };
  const hours = { unit: 'hr', value: durationObject.hours() };
  const minutes = { unit: 'min', value: durationObject.minutes() };

  const pluralize = (value: number, unit: string) => `${value} ${unit}${value !== 1 ? 's' : ''}`;

  return [ days, hours, minutes ].filter(({ value }) => value > 0).map(({ value, unit }) => pluralize(value, unit)).join(', ');
};

export const getMatrixDisplayText = ({ distance, duration }: PlaceMatrix) => {
  const miles = getDistanceInMiles(distance).toFixed(1);
  const formattedDuration = getFormattedDuration(duration);

  return `${miles} miles • ${formattedDuration}`;
};

const transformAddress = (address: PlaceDefaultEnum | string) => {
  return address === PlaceDefaultEnum.studio ? STUDIO_ADDRESS : address;
};

export const PlaceDistanceAndDurationContent: React.FC<PlaceDistanceAndDurationContentProps> = props => {

  if (!props.placeMatrix && !props.displayMissingMatrixMessage) {
    return null;
  }

  const primary = (
    <>
      <span>{props.fromLabel}</span>
      <ArrowRightAltOutlined fontSize="small" />
      <span>{props.toLabel}</span>
    </>
  );

  return (
    <PlaceDistanceAndDurationListItemContainer routeDetails={props.routeDetails}>
      <ListItem sx={{ py: 0, background: theme => theme.palette.primary.background, borderRadius: '4px', color: theme => theme.palette.text.primary }} component="div">
        <ListItemIcon sx={{ minWidth: 'unset', mr: 2 }}>
          <LocalShippingOutlined />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ fontWeight: 500, variant: 'body2', component: 'span', display: 'flex', alignItems: 'center', gap: 0.5 }}
          secondary={props.placeMatrix ? getMatrixDisplayText(props.placeMatrix) : props.displayMissingMatrixMessage}
          secondaryTypographyProps={{ color: props.placeMatrix ? undefined : 'warning.dark' }}
        />
      </ListItem>
    </PlaceDistanceAndDurationListItemContainer>
  );
};

type PlaceDistanceAndDurationListItemContainerProps = Pick<PlaceDistanceAndDurationContentProps, 'routeDetails'> & {
  children: React.ReactNode;
};

const PlaceDistanceAndDurationListItemContainer: React.FC<PlaceDistanceAndDurationListItemContainerProps> = ({ children, routeDetails }) => {
  const href = !!(routeDetails && routeDetails.fromAddress && routeDetails.toAddress) && `https://www.google.com/maps/dir/${transformAddress(routeDetails.fromAddress)}/${transformAddress(routeDetails.toAddress)}`;

  if(href) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:'none', color: 'inherit' }}
        href={href}
        onClick={e => e.stopPropagation()}
      >
        <Button sx={{ p: 0 }} fullWidth>{children}</Button>
      </a>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};