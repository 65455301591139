import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getEventsManagementPresets } from 'api/actions';

export const useEventsManagementPresets = () => {
  return useQuery(QUERY_KEY.EVENTS_MANAGEMENT_PRESETS, async () => {
    const response = await getEventsManagementPresets();

    return response.data;
  });
};
