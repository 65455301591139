import { Box, Typography, TypographyProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DateService } from 'services';
import { BaseCell } from './Base';

export type StandardDateCellProps = Pick<GridRenderCellParams<any, string | undefined | null>, 'value'> & {
  emptyText?: string;
  fontSize?: TypographyProps['fontSize'];
};

export const StandardDateCell: React.FC<StandardDateCellProps> = ({ value, emptyText }) => {
  if (!value) return <Typography component="i" sx={{ fontSize: '14px' }}>{emptyText ?? 'Not Specified'}</Typography>;

  const date = DateService.dayjs(value);

  return (
    <BaseCell
      align="left"
      value={(
        <>
          {date.format('MM/DD/YY')}
          <b>{date.format('(ddd)')}</b>
        </>
      )}
    />
  );
};

export type LoggedAtDateCellProps = StandardDateCellProps & { withTimeAgo?: boolean };

export const LoggedAtDateCell: React.FC<LoggedAtDateCellProps> = ({ value, emptyText, withTimeAgo, fontSize = '14px' }) => { // won't be undefined
  if (!value) {
    return emptyText ? <Typography component="i" sx={{ fontSize: '14px' }}>{emptyText}</Typography> : null;
  }

  const date = DateService.dayjs(value);

  const isToday = date.isToday();
  const isYesterday = date.isYesterday();

  const formattedTime = DateService.getFormattedTimeFromDate(date);

  let weekday = '';

  if (isToday) {
    weekday = 'Today';
  } else if (isYesterday) {
    weekday = 'Yesterday';
  } else {
    weekday = date.format('ddd');
  }

  const showYear = date.isBefore(DateService.dayjs().subtract(1, 'year'));

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%" height="100%">
      <Typography fontSize={fontSize}>
        <Typography component="span" fontWeight={500} fontSize="inherit">{weekday}</Typography>
        {!(isToday || isYesterday) && date.format(` MMM DD${showYear ? ', YYYY' : ''}`)}
        &nbsp;– {formattedTime}
      </Typography>

      {withTimeAgo && <Typography component="span" variant="caption" color="text.secondary">&nbsp;{date.fromNow()}</Typography>}
    </Box>
  );
};
