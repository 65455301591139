import { TableChartOutlined, CalendarMonthOutlined, ViewCarouselOutlined } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BaseTextField } from 'components';
import React from 'react';
import { EventsManagementDisplayTypeEnum, useEventsManagementContext } from '../utils';

export const ActionsBar = () => {
  const { state: { displayType, search }, onSetState } = useEventsManagementContext();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box flex={1} maxWidth={300}>
        <BaseTextField
          name="search_events"
          value={search}
          placeholder="Enter event name..."
          size="small"
          label="Search events"
          onChange={(e) => onSetState({ search: e.target.value })}
          fullWidth
        />
      </Box>
      <ToggleButtonGroup value={displayType} onChange={(_, value) => value && onSetState({ displayType: value }) } exclusive color="primary">
        <ToggleButton value={EventsManagementDisplayTypeEnum.TABLE}><TableChartOutlined /></ToggleButton>
        <ToggleButton value={EventsManagementDisplayTypeEnum.CALENDAR} disabled><CalendarMonthOutlined /></ToggleButton>
        <ToggleButton value={EventsManagementDisplayTypeEnum.CARDS} disabled><ViewCarouselOutlined /></ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
