import { processEventTableRowUpdate, getEventsTableColumns, eventsTableFieldEnumHelpers, Table, useAlertSnackbar, ActionCell } from 'components';
import React, { useCallback, useMemo } from 'react';
import { GridColDef, GridColumnMenu, GridColumnMenuProps, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { GetEventOrganizersResponse, GetEventsResponse, GetUsersAsResourceResponse, updateEvent, UpdateEventInput } from 'api/actions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { EventsTableFieldValues } from './types';
import { EventsTableField } from 'api/resources';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

const CustomColumnMenu = (props: GridColumnMenuProps) =>  {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide "Manage columns" and "Hide column" actions
        columnMenuColumnsItem: null,
      }}
    />
  );
};

export type EventsTableProps = {
  events: GetEventsResponse['data'];
  users: GetUsersAsResourceResponse['data'];
  eventOrganizers: GetEventOrganizersResponse['data'];
  onOpenEvent: (id: string) => void;
  getRowClassName: (params: GridRowClassNameParams<GetEventsResponse['data'][number]>) => string;
  height?: number;
  columns: EventsTableField[];
  onColumnOrderChange: (api: GridApiCommunity) => void;
  loading?: boolean;
};

export const EventsTable: React.FC<EventsTableProps> = ({
  events,
  users,
  eventOrganizers,
  height,
  onOpenEvent,
  columns: selectedColumns,
  onColumnOrderChange,
  getRowClassName,
  loading
}) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateEventMutation = useMutation({
    mutationFn: ({ id, data }: {id: string; data: UpdateEventInput}) => updateEvent(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.EVENTS);
      snackbar.success('Updated event successfully');
    },
    onError: () => {
      snackbar.error('Failed to update event');
    }
  });

  const processRowUpdate = useCallback(async (newRow: EventsTableFieldValues, oldRow: GetEventsResponse['data'][number]) => {
    const update = processEventTableRowUpdate(newRow, oldRow);

    if (Object.keys(update).length > 0) {
      await updateEventMutation.mutateAsync({ id: oldRow._id, data: update });
    }

    return oldRow;
  }, [ updateEventMutation ]);

  const rawColumns = getEventsTableColumns({ users, eventOrganizers });
  const columns: GridColDef<GetEventsResponse['data'][number]>[] = [
    {
      field: 'action',
      headerName: '',
      disableExport: true,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      hideable: false,
      width: 30,
      renderCell: ({ id }) => <ActionCell onActionClick={() => onOpenEvent(String(id))} />,
    },
    ...rawColumns.sort((a, b) => {
      const aIndex = selectedColumns.indexOf(a.field as EventsTableField);
      const bIndex = selectedColumns.indexOf(b.field as EventsTableField);

      return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
    }),
  ];

  const columnVisibilityModel = useMemo(() => {
    return eventsTableFieldEnumHelpers.enumValues.reduce((acc, column) => {
      return {
        ...acc,
        [column]: selectedColumns.includes(column),
      };
    }, {});
  }, [ selectedColumns ]);

  return (
    <Table
      rows={events}
      loading={loading}
      columns={columns}
      getRowId={(row) => row._id}
      slots={{ toolbar: null, columnMenu: CustomColumnMenu }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnOrderChange={(_params, _event, details) => {
        onColumnOrderChange(details.api);
      }}
      getRowClassName={getRowClassName}
      processRowUpdate={processRowUpdate}
      sx={{ minHeight: height }}
    />
  );
};
