import { GridColDef } from '@mui/x-data-grid';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { ProductActiveBaselineTypeEnum } from 'api/resources';
import { Table } from 'components';
import { useTableActionColumn } from 'hooks';
import { useProductsPageContext } from 'contexts';
import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getProductTeamBaseline, getSelectOptionsFromEnumHelper, productSalesCountColumns } from 'helpers';
import { productEnumHelpers } from 'helpers/enums/product-enums.helpers';
import { Done } from '@mui/icons-material';
import { ROUTING_CONFIG } from 'constants/routing-config';

type BaselineType = ProductActiveBaselineTypeEnum | 'active';

const createTeamBaselineColumn = (team: GetTeamsResponse['data'][number], baselineType: BaselineType): GridColDef<GetProductsResponse['data'][number]> => {
  return {
    field: team.isWarehouse ? 'warehouse' : 'team',
    headerName: team.isWarehouse ? 'Warehouse' : 'Team',
    width: 90,
    flex: 1,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueGetter: (_v, row) => {
      if (baselineType === 'active') {
        return getProductTeamBaseline(row, team);
      }

      return getProductTeamBaseline(row, team, baselineType);
    }
  };
};

export const createBaselinesTableColumns = (teams: GetTeamsResponse['data'], baselineType: BaselineType): GridColDef[] => {
  const warehouseTeam = teams.filter(team => team.isWarehouse)[0];
  const team = teams.filter(team => !team.isWarehouse)[0];

  const teamColumns = (team && warehouseTeam) ? [
    createTeamBaselineColumn(warehouseTeam, baselineType),
    createTeamBaselineColumn(team, baselineType),
  ] : [];

  return [
    {
      field: 'rowNumber',
      headerName: 'No.',
      align: 'center',
      width: 50,
      sortable: false,
      valueGetter: (_v, row, _c, apiRef) => apiRef.current.getRowIndexRelativeToVisibleRows(row._id) + 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      hideable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: (value) => new Date(value),
      type: 'date',
      width: 150,
    },
    ...productSalesCountColumns,
    ...teamColumns,
  ];
};

export const BaselinesTable: React.FC = () => {
  const baselineTypeOptions = getSelectOptionsFromEnumHelper(productEnumHelpers.activeBaselineType);
  const { products, teams, loading } = useProductsPageContext();
  const [ baselineType, setBaselineType ] = useState<BaselineType>('active');
  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });
  const columns = withActionColumn(createBaselinesTableColumns(teams, baselineType));

  return (
    <Table
      columns={columns}
      rows={products}
      loading={loading}
      slotProps={{
        toolbar: {
          searchOnly: true,
          actions: (
            <ToggleButtonGroup color="primary" exclusive value={baselineType} onChange={(_, value) => value && setBaselineType(value)}>
              <ToggleButton value="active">
                <Done fontSize="small" color="primary" sx={{ visibility: baselineType === 'active' ? 'initial' : 'hidden' }} />
                Active
              </ToggleButton>
              {baselineTypeOptions.map(({ value, label }) => {
                const isSelected = baselineType === value;

                return (
                  <ToggleButton value={value} key={value}>
                    <Done fontSize="small" color="primary" sx={{ visibility: isSelected ? 'initial' : 'hidden' }} />
                    {label}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          )
        }
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            createdAt: false,
          }
        }
      }}
      getRowId={(x) => x._id}
    />
  );
};
