import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

export type BaseCellProps = {
  startIcon?: React.ReactNode;
  value?: number | React.ReactNode| null;
  align?: 'left' | 'center' | 'right';
};

export const BaseCell: React.FC<BaseCellProps> = props => {
  const justifyContent = useMemo(() => {
    if (props.align === 'left') {
      return 'flex-start';
    }

    if (props.align === 'right') {
      return 'flex-end';
    }

    // default
    return 'center';
  }, [ props.align ]);

  return (
    <Box display="flex" justifyContent={justifyContent} alignItems="center" height="100%" mx={1}>
      <Typography
        variant="body2"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {props.startIcon}
        {props.value}
      </Typography>
    </Box>
  );
};