import { Box } from '@mui/material';
import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import { UnknownEnum, YesNoEnum } from 'types';

export type YesNoCellProps = {
  value?: YesNoEnum | UnknownEnum;
};

export const YesNoCell: React.FC<YesNoCellProps> = props => {
  if (!props.value) {
    return null;
  }

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <YesNoUnknownIconSwitch value={props.value ?? null} />
    </Box>
  );
};