import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import React from 'react';
import { DateObjectType, DateService } from 'services';

export type FormTimePickerPropsType = {
  name: string;
} & TimePickerProps<DateObjectType>;


export const FormTimePicker: React.FC<FormTimePickerPropsType> = ({
  name,
  ...props
}) => {
  const [ field, meta, helpers ] = useField(name);

  return (
    <TimePicker
      // always use mobile mode
      desktopModeMediaQuery="@media (false)"
      {...props}
      value={field.value ? DateService.dayjs(field.value, 'HH:mm') : null}
      onChange={(date) => helpers.setValue(date ? DateService.dayjs(date).format('HH:mm') : null, true)}
      slotProps={{
        textField: {
          error: !!meta.error,
          helperText: meta.error,
          fullWidth: true
        },
      }}
    />
  );
};
