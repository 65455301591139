import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FunctionComponent, SVGProps, useMemo } from 'react';
import { ThemePalette } from 'types';

export type ColorCodeCellProps = {
  color: ThemePalette;
  label: string;
  align?: 'left' | 'center' | 'right';
  Icon?: FunctionComponent<SVGProps<SVGSVGElement> & { sx?: SxProps<Theme> | undefined }>;
  caption?: string;
  solid?: boolean;
};

export const ColorCodeCell: React.FC<ColorCodeCellProps> = ({ color, label, align, Icon, caption, solid }) => {
  const alignItems = useMemo(() => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  }, [ align ]);

  return (
    <Box
      p={1}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      justifyContent="center"
      bgcolor={theme => solid ? theme.palette[color].main : theme.palette[color].background}
      color={theme => solid ? theme.palette[color].contrastText : theme.palette[color].main}
    >
      <Typography variant="body2" fontWeight={500} display="flex" alignItems="center" gap={1}>
        {Icon && <Icon fontSize="small" />}
        {label}
      </Typography>
      <Typography variant="caption">{caption}</Typography>
    </Box>
  );
};