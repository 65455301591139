import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventsResponse, UpdateEventInput, updateEvent } from 'api/actions';
import { EventAcceptanceStatusEnum, EventInsuranceSharedEnum, EventInsuranceStatusEnum, EventParticipationStatusEnum } from 'api/resources';
import { eventsTableColumns_old, useAlertSnackbar } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { processFormValueUpdate } from 'helpers';
import { UseTableActionColumnArgs, useTableActionColumn } from 'hooks';
import { QUERY_KEY } from 'queries/query-keys';
import { UnknownEnum, YesNoEnum } from 'types';

export const useEventsManagementTableColumns = (params: {getNavigateTo?: UseTableActionColumnArgs['getNavigateTo']; editable?: Record<string, boolean>}) => {
  const { getNavigateTo, editable } = params;
  const { withActionColumn } = useTableActionColumn({
    routeTo: 'events',
    getNavigateTo: (id, row) => getNavigateTo?.(id, row) ?? DYNAMIC_ROUTES.event.createLink({ eventId: id }),
    openInNew: true,
  });
  const columns = eventsTableColumns_old.map(column => ({ ...column, editable: editable?.[column.field] }));

  return withActionColumn(columns);
};

const useEventsManagementTableUpdateMutation = () => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();

  return useMutation({
    mutationFn: ({ eventId, update }: {eventId: string; update: UpdateEventInput }) => {
      return updateEvent(eventId, update);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);

      snackbar.success('Event updated');
    }
  });
};

export const useEventsManagementProcessRowUpdate = () => {
  const updateMutation = useEventsManagementTableUpdateMutation();

  return async (
    newRow: GetEventsResponse['data'][number] & {
      insuranceRequired: YesNoEnum | UnknownEnum;
      lodgingRequired: YesNoEnum | UnknownEnum;
      insuranceStatus: EventInsuranceStatusEnum | UnknownEnum;
      insuranceShared: EventInsuranceSharedEnum | UnknownEnum;
      participationStatus: EventParticipationStatusEnum | UnknownEnum;
      acceptanceStatus: EventAcceptanceStatusEnum | UnknownEnum;
    },
    oldRow: GetEventsResponse['data'][number]
  ) => {
    const update = {
      lodging: {
        isRequired: processFormValueUpdate.yesNoUnknown(newRow.lodgingRequired),
      },
      insurance: {
        isRequired: processFormValueUpdate.yesNoUnknown(newRow.insuranceRequired),
        status: processFormValueUpdate.enumWithUnknown(newRow.insuranceStatus),
        shared: processFormValueUpdate.enumWithUnknown(newRow.insuranceShared),
      },
      participationStatus: processFormValueUpdate.enumWithUnknown(newRow.participationStatus),
      acceptanceStatus: processFormValueUpdate.enumWithUnknown(newRow.acceptanceStatus),
    };

    if((update.participationStatus || undefined) !== oldRow.participationStatus
      || (update.acceptanceStatus || undefined) !== oldRow.acceptanceStatus) {
      await updateMutation.mutateAsync({ eventId: newRow._id, update });
    }

    return oldRow;
  };
};