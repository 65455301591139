import { getUntilApplicationOpenValue } from 'helpers';
import { ThemePalette } from 'types';
import { ColorCodeCell } from '../ColorCode.component';
import { DateService } from 'services';
import { GetEventsResponse } from 'api/actions';
import { useMemo } from 'react';
import { EventsTableFieldValues } from 'components/Table/custom/EventsTable/types';
import { EventsTableField } from 'api/resources';

export type EventApplicationOpenDateColorCodeCellProps = {
  row: GetEventsResponse['data'][number];
  value: EventsTableFieldValues[EventsTableField.APPLICATION_OPEN];
};

export const EventApplicationOpenDateColorCodeCell: React.FC<EventApplicationOpenDateColorCodeCellProps> = ({ row, value: inputValue }) => {
  const { applicationOpenDate, applicationIsOpen } = inputValue ?? {};

  const untilApplicationOpen = getUntilApplicationOpenValue(applicationOpenDate, applicationIsOpen);
  const isClosed = row.applicationDeadlineDate && DateService.dayjs(row.applicationDeadlineDate).isBefore(DateService.dayjs(), 'days');
  const applicationWasOpenFor = (row.applicationOpenDate && row.applicationDeadlineDate) ? DateService.dayjs(row.applicationDeadlineDate).diff(row.applicationOpenDate, 'days') + 1 : undefined;

  // Get label
  let label = '';

  // untilApplicationOpen === undefined means that the application open date is unknown
  // and applicationIsOpen is falsy meaning that there's no data for the application open date
  if (untilApplicationOpen === undefined) {
    label = 'Unknown';
    // untilApplicationOpen === null means that the application open date is unknown and applicationIsOpen is true
    // untilApplicationOpen < 0 means that the application open date has passed
  } else if (untilApplicationOpen === null || untilApplicationOpen <= 0) {
    if(untilApplicationOpen === null) {
      label = `${isClosed ? 'Was open' : 'Open'} (open date unknown)`;
    } else if (isClosed && applicationWasOpenFor !== undefined) {
      label = `Was open for ${applicationWasOpenFor} day${-applicationWasOpenFor === 1 ? '' : 's'}`;
    } else if (untilApplicationOpen === 0) {
      label = 'Opens today!';
    } else {
      label = `Open for ${-untilApplicationOpen} day${-untilApplicationOpen === 1 ? '' : 's'}`;
    }
  } else {
    label = `Opens in ${untilApplicationOpen} day${untilApplicationOpen === 1 ? '' : 's'}`;
  }

  // Get color
  let color: ThemePalette = 'unknown';

  // untilApplicationOpen === undefined meaning that there's no data for the application open date
  if (untilApplicationOpen === undefined) {
    color = 'unknown';
  // untilApplicationOpen === null meaning that the application is open and the open date is unknown
  } else if (untilApplicationOpen === null) {
    color = 'warning';
  // untilApplicationOpen < 0 means that the application open date has passed
  } else if (untilApplicationOpen < 0) {
    if (untilApplicationOpen <= -14) {
      color = 'error';
    } else if (untilApplicationOpen <= -7) {
      color = 'threat';
    }
  } else if (untilApplicationOpen <= 7) {
    color = 'success';
  } else if (untilApplicationOpen <= 14) {
    color = 'warning';
  } else {
    color = 'error';
  }

  const caption = useMemo(() => {
    if ((!applicationOpenDate && !applicationIsOpen)) {
      return undefined;
    }

    return applicationIsOpen ? 'Open date unknown' : `Open date: ${DateService.dayjs(applicationOpenDate).format('ddd MMM D, YYYY')}`;
  }, [ applicationIsOpen, applicationOpenDate ]);

  return <ColorCodeCell color={color} label={label} caption={caption} />;
};