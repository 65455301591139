import { Box, IconButton } from '@mui/material';
import { GridCellParams, GridColDef, GridRenderCellParams, GridRowClassNameParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { GetTeamsResponse } from 'api/actions';
import { useCallback, useMemo, useState } from 'react';
import { EditModeSaveAndResetButtons, TableProps } from 'components';
import { RefreshOutlined, SaveOutlined } from '@mui/icons-material';
import { Toolbar, ToolbarProps } from '../Toolbar.component';
import { UnknownEnum } from 'types';
import { useInventoryTableSettings, UseInventoryTableSettingsArgsBase } from './useInventoryTableSettings.hook';
import { ProductStock } from 'api/resources';
import { InventoryEdit, InventoryEdits, InventoryRow, InventoryTableLatestUpdates, InventoryTableOnSaveFunc, SaveInventoryEditsParams, InventoryTableUpdateValidator, InventoryTableCellEdit, InventoryTableApi } from './types';
import { getProductTeamStock, inventoryTableSx, onSetLatestUpdatesHandler } from './helpers';
import { InventoryTableEditModal } from '../components';
import { useInventoryTableTeamColumns, UseInventoryTableTeamColumnsArgsBase } from './useInventoryTableTeamColumns.hook';
import { useInventoryTableApi, UseInventoryTableApiArgsBase } from './useInventoryTableApi.hook';
import { useDeviceDetection } from 'hooks';

export type UseInventoryTableArgs = {
  products: InventoryRow[];
  initialInventoryEdits?: InventoryEdits;
  getStockQuantity: (stock: ProductStock) => number;
  teams:  GetTeamsResponse['data'];
  getExtraColumns?: (InventoryEdits: InventoryEdits, api: InventoryTableApi) => GridColDef<InventoryRow>[] | undefined;
  onSaveRow?: InventoryTableOnSaveFunc;
  onSaveAll?: InventoryTableOnSaveFunc;
  hideReset?: boolean;
  hideSaveColumn?: boolean;
  overrideToolbarActions?: React.ReactNode;
  hideToolbarSettingsButton?: boolean;
  getCellInputError?: InventoryTableUpdateValidator<{ stock: InventoryRow['stocks'][string]; updateQty: number | undefined }>;
  settingsArgs?: UseInventoryTableSettingsArgsBase;
  apiArgs?: UseInventoryTableApiArgsBase;
  teamColumnsArgs?: UseInventoryTableTeamColumnsArgsBase;
};

export const useInventoryTable = ({
  products,
  teams,
  onSaveRow,
  onSaveAll,
  initialInventoryEdits = {},
  hideReset = false,
  hideSaveColumn = false,
  overrideToolbarActions,
  hideToolbarSettingsButton,
  getCellInputError = () => undefined,
  getStockQuantity = stock => stock.quantity,
  getExtraColumns,
  settingsArgs,
  apiArgs,
  teamColumnsArgs,
}: UseInventoryTableArgs) => {
  const device = useDeviceDetection();
  const isTabletOrMobile = device === 'mobile' || device === 'tablet';
  const gridRef = useGridApiRef();
  const [ latestUpdates, setLatestUpdates ] = useState<InventoryTableLatestUpdates>({});
  const [ saveLoading, setSaveLoading ] = useState<'all' | {productId: string} | false>(false);
  const [ inventoryEdits, setInventoryEdits ] = useState<InventoryEdits>(initialInventoryEdits);
  const [ cellEditModalOpen, setCellEditModalOpen ] = useState<InventoryTableCellEdit | null>(null);
  const settings = useInventoryTableSettings({
    products,
    teams,
    inventoryEdits,
    getStockQuantity,
    ...settingsArgs,
  });

  const onSetLatestUpdates = (params: SaveInventoryEditsParams) => onSetLatestUpdatesHandler(params, setLatestUpdates);

  const productHash = useMemo(() => products.reduce((acc: Record<string, number>, product, idx) => ({ ...acc, [product._id]: idx }),{}), [ products ]);

  const getProduct = useCallback((productId: string) => products[productHash[productId]], [ productHash, products ]);
  const getInventoryEdit = useCallback((row: InventoryRow, teamId: string): InventoryEdit | undefined => {
    const productEdits = inventoryEdits[row._id];

    if (!productEdits || !productEdits[teamId]) {
      return;
    }

    let loading: boolean | undefined;

    if (saveLoading === 'all') {
      loading = true;
    } else if (saveLoading && saveLoading.productId === row._id) {
      loading = true;
    }

    return { ...productEdits[teamId], loading };
  }, [ inventoryEdits, saveLoading ]);

  const allEdits = useMemo(() => {
    const productKeys = Object.keys(inventoryEdits);

    return productKeys.reduce((acc: InventoryEdit[], productId) => {
      const teamKeys = Object.keys(inventoryEdits[productId]);

      const edits = teamKeys.reduce((acc: InventoryEdit[], teamId) => {

        return [ ...acc, inventoryEdits[productId][teamId] ];
      }, []);

      return [ ...acc, ...edits ];
    }, []);
  }, [ inventoryEdits ]);

  const getEditsForProduct = useCallback((productId: string) => {
    const productEdits = inventoryEdits[productId];

    if (!productEdits) {
      return [];
    }

    return Object.keys(productEdits).map(teamId => productEdits[teamId]);
  }, [ inventoryEdits ]);

  const hasEdits = allEdits.length > 0;

  const getInputErrorsForProduct = (row: InventoryRow) => {
    if (settings.transferConfig && settings.isTransferOperation) {
      return !!getCellInputError({ stock: getProductTeamStock(row, settings.transferConfig.in), updateQty: getInventoryEdit(row, settings.transferConfig.in)?.value });
    }

    return settings.teamIds.some((teamId) => !!getCellInputError({ stock: getProductTeamStock(row, teamId), updateQty: getInventoryEdit(row, teamId)?.value }));
  };

  const hasInputErrors = settings.filteredRows.some(getInputErrorsForProduct);

  const hasEditErrors = allEdits.some(edit => edit.error !== undefined);
  const hasErrors = hasEditErrors || hasInputErrors;

  const api = useInventoryTableApi({ setInventoryEdits, getProduct, ...apiArgs, ...settings });

  const editModal = useMemo(() => {
    return (
      <InventoryTableEditModal
        cellEdit={cellEditModalOpen}
        onClose={() => setCellEditModalOpen(null)}
        onChange={(value, teamId, productId) => api.setEdit({ teamId, productId, newValue: value })}
        getProduct={getProduct}
        getInventoryEdit={getInventoryEdit}
      />
    );
  }, [ api, cellEditModalOpen, getInventoryEdit, getProduct ]);

  const teamColumns = useInventoryTableTeamColumns({
    isTabletOrMobile,
    getStockQuantity,
    teams,
    latestUpdates,
    getCellInputError,
    getInventoryEdit,
    onChange: (value, productId, teamId) => api.setEdit({ teamId, productId, newValue: value }),
    onEditCell: (productId, teamId) => setCellEditModalOpen({ productId, teamId }),
    ...teamColumnsArgs,
    ...settings,
  });

  const getTeamIdFromField = (field: string) => settings.transferConfig ? field.replace('_transfer', '') : field;

  const onSave = async (saveFunc: InventoryTableOnSaveFunc, productId?: string) => {
    // typeguard
    if (settings.mode === UnknownEnum.unknown) {
      return;
    }

    setSaveLoading(productId ? { productId } : 'all');
    const edits = productId ? { [productId]: inventoryEdits[productId] } : inventoryEdits;

    const saveParams: SaveInventoryEditsParams = {
      edits,
      transferConfig: settings.transferConfig,
      mode: settings.mode,
      isTransferOperation: settings.isTransferOperation,
      products,
    };

    await saveFunc(saveParams);

    if (productId) {
      api.removeProductEdit(productId);
    } else {
      setInventoryEdits({});
    }

    onSetLatestUpdates(saveParams);
    setSaveLoading(false);
  };

  const rows = useMemo(() => {
    // put edits on the top in order of timestamps for mobile devices
    if (isTabletOrMobile) {
      return settings.filteredRows.sort((a, b) => {
        const aEdit = getEditsForProduct(a._id).reduce((acc: InventoryEdit | null, edit) => (acc && acc.timestamp < edit.timestamp) ? acc : edit, null);
        const bEdit = getEditsForProduct(b._id).reduce((acc: InventoryEdit | null, edit) => (acc && acc.timestamp < edit.timestamp) ? acc : edit, null);

        if (aEdit && bEdit) {
          return aEdit.timestamp - bEdit.timestamp;
        }

        if (aEdit && !bEdit) {
          return -1;
        }

        if (!aEdit && bEdit) {
          return 1;
        }

        return 0;
      });
    }

    return settings.filteredRows;
  }, [ getEditsForProduct, isTabletOrMobile, settings.filteredRows ]);

  const inventoryTableProps: TableProps<ToolbarProps> = {
    apiRef: gridRef,
    showCellVerticalBorder: true,
    rows,
    columns: [
      {
        field: 'name',
        headerName: 'Product',
        width: 200,
        cellClassName: 'productName',
        disableColumnMenu: true,
      },
      ...teamColumns,
      ...(getExtraColumns?.(inventoryEdits, api) ?? []),
    ].concat(hideSaveColumn ? [] : [
      {
        field: 'save',
        headerName: '',
        align: 'right',
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        cellClassName: 'inventoryTableCell',
        width: onSaveRow ? 104 : 56,
        renderCell: ({ row }: GridRenderCellParams<InventoryRow>) => {
          const productEdits = getEditsForProduct(row._id);
          const hasErrors = productEdits.some(edit => edit.error);

          return (
            <Box display="flex" gap={1}>
              {!hideReset && (
                <IconButton
                  color="warning"
                  disabled={!productEdits.length}
                  onClick={() => api.removeProductEdit(row._id)}
                  title="Reset Row"
                >
                  <RefreshOutlined />
                </IconButton>
              )}
              {onSaveRow && (
                <IconButton
                  color="success"
                  disabled={!productEdits.length || hasErrors}
                  onClick={() => onSave(onSaveRow, row._id)}
                  title="Save Row"
                >
                  <SaveOutlined />
                </IconButton>
              )}
            </Box>
          );
        }
      }
    ]),
    pinnedColumns: {
      right: [ 'save' ],
      left: [ 'name' ]
    },
    getRowId: row => row._id,
    slots: { toolbar: Toolbar },
    rowHeight: 70,
    rowBufferPx: 5,
    slotProps: {
      toolbar: {
        hideSettingsButton: hideToolbarSettingsButton,
        showStockMeta: settings.showStockMeta,
        isSetMode: settings.isSetOperation && !settings.transferConfig,
        onOpenSettings: () => settings.setSettingsModalOpen(true),
        actions: overrideToolbarActions ?? (
          <EditModeSaveAndResetButtons
            resetDisabled={allEdits.length === 0}
            saveDisabled={hasErrors || allEdits.length === 0}
            onSaveChanges={() => onSaveAll && onSave(onSaveAll)}
            onReset={() => setInventoryEdits({})}
            hideSaveButton={!onSaveAll}
            hideResetButton={hideReset}
          />
        )
      }
    },
    getCellClassName: (params: GridCellParams<InventoryRow>) => {
      const teamId = getTeamIdFromField(params.field);
      const hasEdit = getInventoryEdit(params.row, teamId);

      if (hasEdit) {
        return 'cellHasEdit';
      }

      if (settings.transferConfig &&settings.isTransferOperation && [ 'action', settings.transferConfig.out ].includes(teamId) && getInventoryEdit(params.row, settings.transferConfig.in)) {
        return 'cellHasEdit';
      }

      return '';
    },
    getRowClassName: (params: GridRowClassNameParams<InventoryRow>) => {
      if (getInputErrorsForProduct(params.row)) {
        return 'rowHighlight rowHasErrors';
      }

      if (inventoryEdits[params.row._id]) {
        if (getEditsForProduct(params.row._id).some(edit => edit.error)) {
          return 'rowHighlight rowHasErrors';
        }

        return 'rowHighlight';
      }

      return '';
    },
    columnHeaderHeight: 32,
    sx: inventoryTableSx,
  };

  return {
    ...settings,
    onResetEdits: () => setInventoryEdits({}),
    inventoryTableProps,
    editModal,
    hasEdits,
    hasErrors,
    inventoryEdits,
    setSaveLoading,
    getInputErrorsForProduct
  };
};