import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEventsManagementPreset } from 'api/actions';
import { useAlertSnackbar } from 'components';
import { transformFilterToPreset } from './helpers';
import { QUERY_KEY } from 'queries/query-keys';
import { useEventsManagementContext } from './utils';

export const useUpdateEventsManagementPreset = () => {
  const { state: { filter, columns } } = useEventsManagementContext();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();

  return useMutation({
    mutationFn: async ({ id, name }: {id: string; name?: string}) => {
      return await updateEventsManagementPreset(id, name ? { name } : {
        filter: transformFilterToPreset(filter),
        columns,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENTS_MANAGEMENT_PRESETS });
      snackbar.success('Preset updated successfully');
    },
  });
};