import { GetEventsResponse } from 'api/actions';
import { ColorCodeCell } from '../ColorCode.component';
import { currencyFormatter } from 'services';

export type EventOwedColorCodeCellProps = {
  row: GetEventsResponse['data'][number];
  value?: number;
};

export const EventOwedColorCodeCell: React.FC<EventOwedColorCodeCellProps> = ({ row, value }) => {
  if (!row.cost || value === undefined) {
    return <ColorCodeCell color="warning" label="Cost in unassigned" />;
  }

  if (value <= 0) {
    if (value < 0) {
      return <ColorCodeCell color="error" solid label={`Overpaid by ${-value}`} />;
    }

    return <ColorCodeCell color="success" label="Paid in full!" />;
  }

  return <ColorCodeCell color={row.acceptanceStatus === 'accepted' ? 'error' : 'info'} label={`${currencyFormatter.format(value)}`} />;
};