import { EventDatesStatusEnum, EventStaffStatusEnum, YesNoEnum } from 'types';
import { EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventFutureInterestStatusEnum, EventInsuranceSharedEnum, EventInsuranceStatusEnum, EventParticipationStatusEnum } from './event';
import { ApiResource } from './resources.types';

export type EventsManagementFilterMultiSelectField<T extends string = string> = {
  value: T[];
  includeUnassigned?: boolean;
};

export type EventsManagementPreset = ApiResource<{
  name: string;
  columns: EventsTableField[];
  filter: {
    dateRangeOrShortcut?: EventsManagementPresetDateRangeOrShortcut;
    participationStatus?: EventsManagementFilterMultiSelectField<EventParticipationStatusEnum>;
    applicationStatus?: EventsManagementFilterMultiSelectField<EventApplicationStatusEnum>;
    acceptanceStatus?: EventsManagementFilterMultiSelectField<EventAcceptanceStatusEnum>;
    staffStatus?: EventsManagementFilterMultiSelectField<EventStaffStatusEnum>;
    datesStatus?: EventsManagementFilterMultiSelectField<EventDatesStatusEnum>;
    futureInterestStatus?: EventsManagementFilterMultiSelectField<EventFutureInterestStatusEnum>;
    applicationOpenDate?: EventsManagementFilterMultiSelectField<EventsManagementPresetDateStatusEnum>;
    applicationDeadlineDate?: EventsManagementFilterMultiSelectField<EventsManagementPresetDateStatusEnum>;
    insuranceRequired?: EventsManagementFilterMultiSelectField<YesNoEnum>;
    insuranceStatus?: EventsManagementFilterMultiSelectField<EventInsuranceStatusEnum>;
    insuranceShared?: EventsManagementFilterMultiSelectField<EventInsuranceSharedEnum>;
    lodgingRequired?: EventsManagementFilterMultiSelectField<YesNoEnum>;
    team?: EventsManagementFilterMultiSelectField;
    teamLead?: EventsManagementFilterMultiSelectField;
  };
}>;

export const enum EventsManagementPresetDateStatusEnum {
  specified = 'specified',
  notSpecified = 'notSpecified',
  upcoming = 'upcoming',
  past = 'past'
}

export const enum EventsTableField {
  NAME = 'nameCellValue',
  CREATED_AT = 'createdAtCellValue',
  YEAR = 'yearCellValue',
  EVENT_PROGRESSION = 'eventProgressionCellValue',
  START_DATE = 'startDateCellValue',
  END_DATE = 'endDateCellValue',
  DATE_RANGE = 'eventDateRangeCellValue',
  DAYS_COUNT = 'daysCountCellValue',
  ATTENDANCE = 'attendanceCellValue',
  VENDORS = 'vendorsCellValue',
  SPACE_SIZE = 'space_sizeCellValue',
  TEAM = 'teamCellValue',
  TEAM_MANAGER = 'teamManagerCellValue',
  VEHICLE = 'vehicleCellValue',
  EVENT_ORGANIZER = 'event_ogranizerCellValue',
  EVENT_MANAGER = 'eventManagerCellValue',
  PARTICIPATION_STATUS = 'participationStatusCellValue',
  APPLICATION_STATUS = 'applicationStatusCellValue',
  APPLICATION_OPEN = 'applicationOpenCellValue',
  APPLICATION_DEADLINE = 'applicationDeadlineCellValue',
  JURY_FEE = 'juryFeeCellValue',
  JURY_FEE_AMOUNT = 'juryFeeAmountCellValue',
  JURY_FEE_PAID = 'juryFeePaidCellValue',
  JURY_FEE_PAYMENT_TYPE = 'juryFeePaymentTypeCellValue',
  ACCEPTANCE_STATUS = 'acceptanceStatusCellValue',
  APPLICATION_PLATFORM = 'applicationPlaformCellValue',
  COST = 'costCellValue',
  PAID = 'paidCellValue',
  OWED = 'owedCellValue',
  PAYMENTS_DUE = 'paymentsDueCellValue',
  REVENUE = 'revenueCellValue',
  ITEMS_SOLD = 'itemsSoldCellValue',
  LOCATION = 'locationCellValue',
  STUDIO_TO_EVENT = 'srudioToEventCellValue',
  AVERAGE_OVERALL_RATING = 'averageOverallRatingCellValue',
  CREATED_BY = 'createdByCellValue',
  INSURANCE_REQUIRED = 'insuranceRequiredCellValue',
  INSURANCE_STATUS = 'insuranceStatusCellValue',
  INSURANCE_SHARED = 'insuranceSharedCellValue',
  LODGING_REQUIRED = 'lodgingRequiredCellValue',
  STAFF_STATUS = 'staffStatusCellValue',
  DATES_STATUS = 'datesStatusCellValue',
}

export type EventsManagementPresetDateRangeOrShortcut =
  | { dateRange: { start: string; end: string }; shortcut?: never }
  | { dateRange?: never; shortcut: EventsManagementPresetDateRangeShortcut };

export const enum EventsManagementPresetDateRangeShortcut {
  PAST = 'past',
  UPCOMING = 'upcoming',

  THIS_YEAR = 'thisYear',
  THIS_MONTH = 'thisMonth',

  NEXT_YEAR = 'nextYear',
  NEXT_MONTH = 'nextMonth',

  LAST_YEAR = 'lastYear',
  LAST_MONTH = 'lastMonth',

  LAST_365_DAYS = 'last365Days',
}