import { Close } from '@mui/icons-material';
import { AppBar, Box, Dialog, DialogContent, IconButton, Slide, SlideProps, Toolbar, Typography } from '@mui/material';
import { FilterButton, FilterEnumFieldConfiguration, ImagesCarousel, Table, useFilter } from 'components';
import React, { useMemo, useState } from 'react';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { GetLoggingPortalDataResponse, GetProductsResponse } from 'api/actions';
import { genreEnumHelpers } from 'helpers/enums/genre-enums.helpers';
import { GenreGroupEnum } from 'api/resources/genre';
import { useGridApiRef, GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';

export type ProductSearchModalProps = {
  onClose: () => void;
};

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const getProductSearchTableColumns = (teamId?: string): GridColDef<GetLoggingPortalDataResponse['data']['products'][number]>[] => [
  {
    field: 'rowNumber',
    headerName: 'Rank',
    align: 'center',
    width: 50,
    sortable: false,
    hideable: false,
    getApplyQuickFilterFn: () => null,
    valueGetter: (_v, row, _c, apiRef) => apiRef.current.getRowIndexRelativeToVisibleRows(row._id) + 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    sortable: false,
    hideable: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity in stock',
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    width: 150,
    sortable: false,
    hideable: false,
    getApplyQuickFilterFn: () => null,
    valueGetter: (_v, row) => {
      if (!teamId) {
        return null;
      }

      const stock = row.stocks.find(stock => stock.team === teamId);

      return stock?.quantity;
    },
  },
  {
    field: 'genres',
    headerName: 'Genres',
    width: 250,
    sortable: false,
    hideable: false,
    valueFormatter: (value: GetProductsResponse['data'][number]['genres']) => {
      return value
        .sort((a, b) => a.order - b.order)
        .map(genre => genreEnumHelpers.group.getLabel(genre.genre.group))
        .join(', ');
    },
    getApplyQuickFilterFn: () => null,
  },
  {
    field: 'salesCount',
    headerName: 'Sales Count',
    width: 150,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    hideable: false,
    getApplyQuickFilterFn: () => null,
  },
];

const enumFilter: FilterEnumFieldConfiguration<GetLoggingPortalDataResponse['data']['products'][number], GenreGroupEnum> = {
  field: 'genreGroup',
  label: 'Genres',
  type: 'enum',
  options: genreEnumHelpers.group.enumValues,
  getOptionLabel: genreEnumHelpers.group.getLabel,
  predicate: (row, value) => row.genres.some(genre => genre.genre.group === value),
  defaultValue: [
    GenreGroupEnum.electronic,
    GenreGroupEnum.folk,
    GenreGroupEnum.hiphop,
    GenreGroupEnum.jazz,
    GenreGroupEnum.latin,
    GenreGroupEnum.metal,
    GenreGroupEnum.other,
    GenreGroupEnum.pop,
    GenreGroupEnum.punk,
    GenreGroupEnum.rb,
    GenreGroupEnum.rock
  ],
};

export const ProductSearchModal: React.FC<ProductSearchModalProps> = props => {
  const [ displayCarouselAtIndex, setDisplayCarouselAtIndex ] = useState<number | null>(null);
  const { products, teamId } = useLoggingPortalContext();
  const apiRef = useGridApiRef();
  const [ quickFilter, setQuickFilter ] = useState('');
  const columns = getProductSearchTableColumns(teamId);

  const onFilterModelChange = (model: GridFilterModel) => {
    if (model.quickFilterValues?.length) {
      setQuickFilter(model.quickFilterValues[0]);
    } else {
      setQuickFilter('');
    }
  };

  const { modal, setShowModal, filteredRows } = useFilter({
    localStorageId: 'logging_portal_product_search_filter',
    rows: products.filter(product => product.availability?.events),
    filterConfigurations: [ enumFilter ],
  });

  const orderedRows = useMemo(() => {
    if (!apiRef.current.getRowIndexRelativeToVisibleRows) {
      return [];
    }

    return filteredRows
      .filter(row => row.name.toLowerCase().includes(quickFilter.toLowerCase()))
      .sort((a, b) => apiRef.current.getRowIndexRelativeToVisibleRows(a._id) - apiRef.current.getRowIndexRelativeToVisibleRows(b._id));
  }, [ apiRef, filteredRows, quickFilter ]);
  const imageSrcs = useMemo(() => {
    return orderedRows.map(product => product.imageUrl) as string[];
  }, [ orderedRows ]);

  return (
    <Dialog
      fullScreen
      open
      onClose={props.onClose}
      transitionDuration={400}
      TransitionComponent={Transition}
    >
      {modal}
      {displayCarouselAtIndex !== null && (
        <ImagesCarousel
          initialIndex={displayCarouselAtIndex}
          imageSrcs={imageSrcs}
          onClose={() => setDisplayCarouselAtIndex(null)}
          isProductImagesDisplay
          renderInfo={(index) => <><strong>{orderedRows[index].name}</strong>, {index + 1} of {imageSrcs.length}</>}
        />
      )}
      <AppBar sx={{ position: 'relative' }} color="transparent">
        <Toolbar>
          <Box flex={1}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h6" component="div">
            Inventory Search
          </Typography>
          <Box flex={1}/>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Table
          apiRef={apiRef}
          rows={filteredRows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: { salesCount: false },
            },
            sorting: {
              sortModel: [ { field: 'salesCount', sort: 'desc' } ],
            },
          }}
          getRowId={(x) => x._id}
          onRowClick={(params) => setDisplayCarouselAtIndex(apiRef.current.getRowIndexRelativeToVisibleRows(params.id))}
          disableRowSelectionOnClick
          slotProps={{ toolbar: { searchOnly: true, actions: <FilterButton onClick={() => setShowModal(true)} /> } }}
          onFilterModelChange={onFilterModelChange}
        />
      </DialogContent>
    </Dialog>
  );
};