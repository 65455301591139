import { Box, Button } from '@mui/material';
import { PageContentHeader } from 'components';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventsManagementContextProvider } from './utils';
import { ActionsBar } from './components/ActionsBar.component';
import { Sidebar } from './components/Sidebar/Sidebar.component';
import { EventsManagementTable } from './components/Table.component';

export const EventsManagement2 = () => {
  const navigate = useNavigate();
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [ containerHeight, setContainerHeight ] = useState<number | undefined>();

  useEffect(() => {
    if (tableContainerRef.current) {
      const handleResize = () => {
        const rect = tableContainerRef.current?.getBoundingClientRect();

        setContainerHeight(rect?.height);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <EventsManagementContextProvider>
      <PageContentHeader
        title="Events Management"
        rightActions={<Button variant="contained" onClick={() => navigate('/events/new')} size="small">New Event</Button>}
      />
      <Box display="flex" height="100%" overflow="hidden">
        <Sidebar />
        <Box display="flex" flexDirection="column" flex={1} m={1} gap={1} minWidth={0}>
          <ActionsBar />
          <Box flex={1} ref={tableContainerRef}>
            <EventsManagementTable height={containerHeight} />
          </Box>
        </Box>
      </Box>
    </EventsManagementContextProvider>
  );
};
