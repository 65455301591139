import { GridColDef } from '@mui/x-data-grid';
import { LoggedAtDateCell, StandardDateCell, TeamCell } from 'components';
import { TSalesTablePerTypeRow, TSalesTableRow } from './types';
import { currencyFormatter } from 'services';
import { Box, Typography } from '@mui/material';

export const salesTableColumns: GridColDef<TSalesTableRow>[] = [
  {
    field: 'createdAt',
    headerName: 'Logged At',
    valueGetter: (_v, row) => new Date(row.createdAt),
    type: 'date',
    renderCell: LoggedAtDateCell,
    width: 200,
  },
  {
    field: '_id',
    headerName: 'Sale Id',
    width: 220,
  },
  {
    field: 'order',
    headerName: 'Order',
    valueGetter: (_v, row) => row.order?.name,
    width: 130,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: 200,
    valueGetter: (_v, row) => row.product.name,
  },
  {
    field: 'frame',
    headerName: 'Frame',
    type: 'boolean',
    width: 100,
    sortable: false,
  },
  {
    field: 'salePrice',
    headerName: 'Sale Price',
    width: 100,
    valueFormatter(value: TSalesTableRow['salePrice']) {
      return currencyFormatter.format(value);
    },
  },
  {
    field: 'payment',
    headerName: 'Payment Type',
    width: 125,
  },
  {
    field: 'type',
    headerName: 'Log Type',
    valueGetter: (_v, row) => {
      return `${row.order.type} ${'event' in row ? '(event)' : ''}${'storefront' in row ? '(storefront)' : ''}`;
    },
    width: 135,
  },
  {
    field: 'team',
    headerName: 'Team',
    width: 100,
    valueGetter: (_v, row) => row.order.event ? row.order.event.team.name : null,
    renderCell: ({ row }) => row.order.event ? <TeamCell team={row.order.event.team} /> : <Typography variant="body2" color="text.secondary" fontStyle="italic">Not Applicable</Typography>,
  },
  {
    field: 'event',
    headerName: 'Event',
    width: 200,
    valueGetter: (_v, row) => row.order.event ? row.order.event.name : null,
    renderCell: ({ value }) => value ? value : (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <Typography variant="body2" color="text.secondary" fontStyle="italic">Not Applicable</Typography>
      </Box>
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    width: 100,
    valueGetter: (_v, row) => row.order.user ? row.order.user.name : null,
    renderCell: ({ value }) => value ? value : (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <Typography variant="body2" color="text.secondary" fontStyle="italic">Not Applicable</Typography>
      </Box>
    ),
  },
  {
    field: 'date',
    headerName: 'Date',
    valueGetter: (_v, row) => new Date(row.saleDate),
    width: 200,
    type: 'date',
    renderCell: StandardDateCell,
  }
];

const totalFields: GridColDef[] = [
  {
    field: 'salesCount',
    headerName: 'Total Sales',
    width: 200,
  },
  {
    field: 'salesRevenue',
    headerName: 'Revenue',
    width: 200,
    valueFormatter(value: TSalesTablePerTypeRow['salesRevenue']) {
      return currencyFormatter.format(value);
    },
  },
];

export const salesPerProductColumns: GridColDef<TSalesTablePerTypeRow>[] = [
  {
    field: 'name',
    headerName: 'Product',
    width: 200,
  },
  ...totalFields,
];

export const salesPerTeamColumns: GridColDef<TSalesTablePerTypeRow>[] = [
  {
    field: 'name',
    headerName: 'Team',
    width: 200,
  },
  ...totalFields,
];

export const salesPerEventColumns: GridColDef<TSalesTablePerTypeRow>[] = [
  {
    field: 'name',
    headerName: 'Event',
    width: 200,
  },
  ...totalFields,
];
